import moment from 'moment';
import {
	commaStringToNumber,
	numStringToNumber,
	roundDown,
	roundUp,
	roundUp2,
} from './util';

function getMonthlyDays(lastWorkDate) {
	if (!lastWorkDate) return [];

	const lastDate = new Date(lastWorkDate);

	// 윤년인지 확인하는 내부 함수
	const isLeapYear = (year) => {
		return (year % 4 === 0 && year % 100 !== 0) || year % 400 === 0;
	};

	// 날짜를 포맷하는 함수
	const formatDate = (date) => {
		const year = date.getFullYear();
		const month = String(date.getMonth() + 1).padStart(2, '0');
		const day = String(date.getDate()).padStart(2, '0');
		return `${year}-${month}-${day}`;
	};

	// 해당 연도와 월의 마지막 날을 반환하는 함수
	const getLastDayOfMonth = (year, month) => {
		if (month === 1) {
			// 2월인 경우
			return isLeapYear(year) ? 29 : 28;
		}
		return new Date(year, month + 1, 0).getDate();
	};

	// 월 단위로 날짜를 빼는 함수
	const subtractMonths = (date, months) => {
		let year = date.getFullYear();
		let month = date.getMonth() - months;
		const day = date.getDate();

		// 월이 음수일 경우 연도를 조정
		while (month < 0) {
			month += 12;
			year--;
		}

		// 새 날짜 객체를 생성
		const newDate = new Date(year, month, 1);

		// 해당 월에 동일한 날짜가 없을 경우 그 달의 마지막 날로 설정
		const lastDay = getLastDayOfMonth(
			newDate.getFullYear(),
			newDate.getMonth()
		);
		if (day > lastDay) {
			newDate.setDate(lastDay);
		} else {
			newDate.setDate(day);
		}

		return newDate;
	};

	// 마지막 근무일이 말일인지 확인
	const isEndOfMonth = (date) => {
		return (
			date.getDate() === getLastDayOfMonth(date.getFullYear(), date.getMonth())
		);
	};

	let results = [];
	const numberOfMonths = isEndOfMonth(lastDate) ? 3 : 4;

	for (let i = 0; i < numberOfMonths; i++) {
		const adjustedDate = subtractMonths(lastDate, i);
		const year = adjustedDate.getFullYear();
		const month = adjustedDate.getMonth();

		let startDate, endDate;

		if (i === 0) {
			// 마지막 근무 월 처리
			startDate = new Date(year, month, 1);
			endDate = lastDate;
		} else if (i === numberOfMonths - 1 && !isEndOfMonth(lastDate)) {
			// 마지막 월은 4개월째로 퇴사일로부터 3개월 전 날짜
			startDate = new Date(
				lastDate.getFullYear(),
				lastDate.getMonth() - 3,
				lastDate.getDate() + 1
			); // 정확히 3개월 전의 다음 날
			endDate = new Date(year, month + 1, 0); // 해당 월의 말일
		} else {
			// 그 외 월 처리
			startDate = new Date(year, month, 1);
			endDate = new Date(year, month + 1, 0); // 해당 월의 말일
		}

		results.push({
			year: year,
			month: month + 1,
			startDate: formatDate(startDate),
			endDate: formatDate(endDate),
			days: Math.floor((endDate - startDate) / (1000 * 60 * 60 * 24) + 1), // 소수점 없이 정수로 변환
			daysInMonth: endDate.getDate(),
		});
	}
	return results;
}

export function averMonthDaysF(
	retiredDate,
	workerEnteredDate,
	paydocus,
	hourOrdinaryWage,
	paidHolidayLBTime,
	retireFundTaxFreeValue = 0
) {
	const enterDateData = workerEnteredDate;
	const retiredDateData = retiredDate;

	if (!retiredDate) {
		// retiredDate 또는 workerEnteredDate가 없으면 기본 값을 반환하거나 작업을 수행하지 않음
		return {
			retireMonthDays: 0,
			totalWorkingDays: 0,
			// 나머지 기본 값 설정
		};
	}
	const retireDateMoment = moment(retiredDateData, 'YYYY-MM-DD'); //마지막근무일 객체
	const retireDateString = retireDateMoment.format('YYYY-MM-DD'); // 퇴직월 말일 string
	const enterDateMoment = moment(enterDateData); //입사일 객체
	const totalWorkingDays = retireDateMoment.diff(enterDateMoment, 'days') + 1; // 재직일수 (number)

	const retiredYear = retiredDate ? retiredDate?.getFullYear() : '';
	const retiredMonth = retiredDate ? retiredDate?.getMonth() : '';
	const retiredDateMoment = moment(retiredDate).format('YYYY-MM-DD');

	const retiredYearMonth =
		retiredDateMoment.split('-')[0] + retiredDateMoment.split('-')[1];

	const retiredDate1MonthBeforeYearMonth = moment(retiredDateMoment, 'YYYY-MM')
		.subtract(1, 'months')
		.format('YYYYMM');

	const retiredDate2MonthBeforeYearMonth = moment(retiredDateMoment, 'YYYY-MM')
		.subtract(2, 'months')
		.format('YYYYMM');

	const retiredDate3MonthBeforeYearMonth = moment(retiredDateMoment, 'YYYY-MM')
		.subtract(3, 'months')
		.format('YYYYMM');

	const retiredDate12MonthBeforeYearMonth = moment(retiredDateMoment, 'YYYY-MM')
		.subtract(12, 'months')
		.format('YYYYMM');

	// 날짜 계산 함수(퇴직월, 1개월전, 2개월전, 3개월전)
	const result = getMonthlyDays(retiredDate);

	function averWageOfOneMonth() {
		// 퇴직 월에 해당하는 paydocu 데이터를 찾습니다.
		const paydocuForRetiredMonth = paydocus.find(
			(paydocu) => retiredDate && paydocu.info.yearMonth === retiredYearMonth
		);

		// paydocuForRetiredMonth가 없으면 0을 반환합니다.
		if (!paydocuForRetiredMonth) return 0;

		// 필요한 값들을 구조 분해 할당으로 추출합니다.
		const {
			info: { totalMonthWageAmount },
			addAverWage: {
				plusUnusedAnnualLeavesWage,
				plusRegularBonus,
				plusIrregularBonus,
			},
			plusRetiredUnusedAnnualLeavesWage,
			plusSpecialRandomWage,
		} = paydocuForRetiredMonth;

		// 각 임금 항목들을 기본값 처리와 함께 계산합니다.
		const annualWage = plusUnusedAnnualLeavesWage || 0;
		const retireUnusedMonthAnnualWage = plusRetiredUnusedAnnualLeavesWage || 0;
		const specialRandomWage = plusSpecialRandomWage || 0;
		const bonusWage = plusRegularBonus + plusIrregularBonus || 0;

		// // 퇴직 월의 일 수 관련 데이터
		// const retireMonthBeforeDaysInMonth = result[0]?.daysInMonth || 1;
		// const retireMonthDays = result[0]?.days || 0;

		// 퇴직 월의 실제 평균 임금을 계산합니다.
		const realAverWageOfRetiredMonth =
			totalMonthWageAmount -
			annualWage -
			bonusWage -
			retireUnusedMonthAnnualWage -
			specialRandomWage;

		// 계산된 값을 반환합니다.
		return roundUp(realAverWageOfRetiredMonth || 0);
	}

	function averWageOfOneMonth1() {
		// 퇴직 전 달에 해당하는 paydocu 데이터를 찾습니다.
		const paydocuForRetiredMonth = paydocus.find(
			(paydocu) =>
				retiredDate &&
				paydocu.info.yearMonth === retiredDate1MonthBeforeYearMonth
		);

		// paydocuForRetiredMonth가 없으면 0을 반환합니다.
		if (!paydocuForRetiredMonth) return 0;

		// 필요한 값들을 구조 분해 할당으로 추출합니다.
		const {
			info: { totalMonthWageAmount },
			addAverWage: {
				plusUnusedAnnualLeavesWage = 0,
				plusRegularBonus = 0,
				plusIrregularBonus = 0,
			} = {},
			plusRetiredUnusedAnnualLeavesWage = 0,
			plusSpecialRandomWage = 0,
		} = paydocuForRetiredMonth;

		// 각 임금 항목 계산
		const annualWage = plusUnusedAnnualLeavesWage;
		const retireUnusedMonthAnnualWage = plusRetiredUnusedAnnualLeavesWage;
		const specialRandomWage = plusSpecialRandomWage;
		const bonusWage = plusRegularBonus + plusIrregularBonus;

		// 퇴직 전 달의 일 수 관련 데이터
		const retireOneMonthBeforeDaysInMonth = result[1]?.daysInMonth || 1;
		const retireOneMonthBeforeMonthDays = result[1]?.days || 0;

		// 퇴직 전 달의 실제 평균 임금 계산
		const realAverWageOfRetiredMonth =
			totalMonthWageAmount -
			annualWage -
			bonusWage -
			retireUnusedMonthAnnualWage -
			specialRandomWage;

		// 계산된 값을 반환합니다.
		return (
			roundUp(
				(realAverWageOfRetiredMonth / retireOneMonthBeforeDaysInMonth) *
					retireOneMonthBeforeMonthDays
			) || 0
		);
	}

	function averWageOfOneMonth2() {
		// 퇴직 전 두 달에 해당하는 paydocu 데이터를 찾습니다.
		const paydocuForRetiredMonth = paydocus.find(
			(paydocu) =>
				retiredDate &&
				paydocu.info.yearMonth === retiredDate2MonthBeforeYearMonth
		);

		// paydocuForRetiredMonth가 없으면 0을 반환합니다.
		if (!paydocuForRetiredMonth) return 0;

		// 필요한 값들을 구조 분해 할당으로 추출합니다.
		const {
			info: { totalMonthWageAmount },
			addAverWage: {
				plusUnusedAnnualLeavesWage = 0,
				plusRegularBonus = 0,
				plusIrregularBonus = 0,
			} = {},
			plusRetiredUnusedAnnualLeavesWage = 0,
			plusSpecialRandomWage = 0,
		} = paydocuForRetiredMonth;

		// 각 임금 항목 계산
		const annualWage = plusUnusedAnnualLeavesWage;
		const retireUnusedMonthAnnualWage = plusRetiredUnusedAnnualLeavesWage;
		const specialRandomWage = plusSpecialRandomWage;
		const bonusWage = plusRegularBonus + plusIrregularBonus;

		// // 퇴직 전 두 달의 일 수 관련 데이터
		// const retireTwoMonthBeforeDaysInMonth = result[2]?.daysInMonth || 1;
		// const retireTwoMonthBeforeMonthDays = result[2]?.days || 0;

		// 퇴직 전 두 달의 실제 평균 임금 계산
		const realAverWageOfRetiredMonth =
			totalMonthWageAmount -
			annualWage -
			bonusWage -
			retireUnusedMonthAnnualWage -
			specialRandomWage;

		// 계산된 값을 반환합니다.
		return roundUp(realAverWageOfRetiredMonth) || 0;
	}

	function averWageOfOneMonth3() {
		// 퇴직 전 세 달에 해당하는 paydocu 데이터를 찾습니다.
		const paydocuForRetiredMonth = paydocus.find(
			(paydocu) =>
				retiredDate &&
				paydocu.info.yearMonth === retiredDate3MonthBeforeYearMonth
		);

		// paydocuForRetiredMonth가 없으면 0을 반환합니다.
		if (!paydocuForRetiredMonth) return 0;

		// 필요한 값들을 구조 분해 할당으로 추출합니다.
		const {
			info: { totalMonthWageAmount },
			addAverWage: {
				plusUnusedAnnualLeavesWage = 0,
				plusRegularBonus = 0,
				plusIrregularBonus = 0,
			} = {},
			plusRetiredUnusedAnnualLeavesWage = 0,
			plusSpecialRandomWage = 0,
		} = paydocuForRetiredMonth;

		// 각 임금 항목 계산
		const annualWage = plusUnusedAnnualLeavesWage;
		const retireUnusedMonthAnnualWage = plusRetiredUnusedAnnualLeavesWage;
		const specialRandomWage = plusSpecialRandomWage;
		const bonusWage = plusRegularBonus + plusIrregularBonus;

		// 퇴직 전 세 달의 일 수 관련 데이터
		const retireThreeMonthBeforeDaysInMonth = result[3]?.daysInMonth || 1;
		const retireThreeMonthBeforeMonthDays = result[3]?.days || 0;

		// 퇴직 전 세 달의 실제 평균 임금 계산
		const realAverWageOfRetiredMonth =
			totalMonthWageAmount -
			annualWage -
			bonusWage -
			retireUnusedMonthAnnualWage -
			specialRandomWage;

		// 계산된 값을 반환합니다.
		return (
			roundUp(
				(realAverWageOfRetiredMonth / retireThreeMonthBeforeDaysInMonth) *
					retireThreeMonthBeforeMonthDays
			) || 0
		);
	}

	const retireYearTotalAnnualLeavesWagesArray = paydocus.map((paydocu) =>
		retiredDate &&
		paydocu.info.yearMonth >= retiredDate12MonthBeforeYearMonth &&
		paydocu.info.yearMonth <= retiredYearMonth
			? Number(paydocu.addAverWage.plusUnusedAnnualLeavesWage || 0)
			: ''
	);
	function sumOfRetireAnnualLeaveWageCom(
		retireYearTotalAnnualLeavesWagesArray
	) {
		return retireYearTotalAnnualLeavesWagesArray.reduce(
			(sum, wage) => sum + (wage || 0),
			0
		);
	}
	const averAnnualLeavesWagesTemp = sumOfRetireAnnualLeaveWageCom(
		retireYearTotalAnnualLeavesWagesArray
	);

	const averAnnualLeavesWages = averAnnualLeavesWagesTemp
		? averAnnualLeavesWagesTemp
		: 0;

	const retireYearTotalBonusWagesArray = paydocus.map((paydocu) =>
		retiredDate &&
		paydocu.info.yearMonth >= retiredDate12MonthBeforeYearMonth &&
		paydocu.info.yearMonth <= retiredYearMonth
			? Number(paydocu.addAverWage.plusRegularBonus || 0) +
			  Number(paydocu.addAverWage.plusIrregularBonus || 0)
			: 0
	);

	function sumOfRetireYearTotalBonusWageCom(retireYearTotalBonusWagesArray) {
		return retireYearTotalBonusWagesArray.reduce(
			(sum, wage) => sum + (wage || 0),
			0
		);
	}
	const averBonusWagesTemp = sumOfRetireYearTotalBonusWageCom(
		retireYearTotalBonusWagesArray
	);

	const averBonusWages = averBonusWagesTemp ? averBonusWagesTemp : 0;

	const dayOrdinaryWage = hourOrdinaryWage * paidHolidayLBTime;

	const sumOfAverageWages =
		+averWageOfOneMonth() +
		+averWageOfOneMonth1() +
		+averWageOfOneMonth2() +
		+averWageOfOneMonth3() +
		roundUp2(((+averAnnualLeavesWages + +averBonusWages) / 12) * 3);

	const sumOfAverDays =
		+result[0]?.days +
		+result[1]?.days +
		+result[2]?.days +
		(+result[3]?.days || 0);

	const retireAverageDayWage = roundUp2(sumOfAverageWages / sumOfAverDays);
	const averageFinalDayWage =
		dayOrdinaryWage > retireAverageDayWage
			? dayOrdinaryWage
			: retireAverageDayWage;

	const workedYears = roundUp(totalWorkingDays / 365);
	const retireFund = Math.ceil(
		averageFinalDayWage * (totalWorkingDays / 365) * 30
	);
	const firedForcastWage = dayOrdinaryWage * 30;

	//1-1. 퇴직비과세
	const retireFundTaxFree = retireFundTaxFreeValue
		? numStringToNumber(retireFundTaxFreeValue)
		: 0;

	//2. 퇴직소득금액
	const retiredFundDeductTaxFree = retireFund - retireFundTaxFree;

	//3. 퇴직소득공제(근속년수 공제)
	function workedYearTaxMinus() {
		let yearTaxMinus;
		if (workedYears <= 5) {
			yearTaxMinus = workedYears * 1000000;
		} else if (workedYears <= 10) {
			yearTaxMinus = 5000000 + (workedYears - 5) * 2000000;
		} else if (workedYears <= 20) {
			yearTaxMinus = 15000000 + (workedYears - 10) * 2500000;
		} else {
			yearTaxMinus = 40000000 + (workedYears - 20) * 3000000;
		}
		return yearTaxMinus;
	}

	// 4.환산급여((2. - 3.) * 12 / 근속년수 )
	const convertedRetiredFund =
		((retiredFundDeductTaxFree - workedYearTaxMinus()) * 12) / workedYears;

	// 5. 환산급여공제
	function convertedRetiredFundTaxMinus() {
		let convertedTaxMinusValue;
		if (convertedRetiredFund <= 8000000) {
			convertedTaxMinusValue = convertedRetiredFund;
		} else if (convertedRetiredFund <= 70000000) {
			convertedTaxMinusValue =
				8000000 + ((convertedRetiredFund - 8000000) * 60) / 100;
		} else if (convertedRetiredFund <= 100000000) {
			convertedTaxMinusValue =
				45200000 + ((convertedRetiredFund - 70000000) * 55) / 100;
		} else if (convertedRetiredFund <= 300000000) {
			convertedTaxMinusValue =
				61700000 + ((convertedRetiredFund - 100000000) * 45) / 100;
		} else {
			convertedTaxMinusValue =
				151700000 + ((convertedRetiredFund - 300000000) * 35) / 100;
		}
		return convertedTaxMinusValue;
	}

	//6.과세표준
	const targetTaxRetiredFund = roundUp(
		convertedRetiredFund - convertedRetiredFundTaxMinus()
	);

	//7. 환산산출세액
	function convertedRetiredFundTax() {
		let retiredFundTaxValue;
		if (targetTaxRetiredFund <= 14000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 6) / 100;
		} else if (targetTaxRetiredFund <= 50000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 15) / 100 - 1260000;
		} else if (targetTaxRetiredFund <= 88000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 24) / 100 - 5760000;
		} else if (targetTaxRetiredFund <= 150000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 35) / 100 - 15440000;
		} else if (targetTaxRetiredFund <= 300000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 38) / 100 - 19940000;
		} else if (targetTaxRetiredFund <= 500000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 40) / 100 - 25940000;
		} else if (targetTaxRetiredFund <= 1000000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 42) / 100 - 35940000;
		} else {
			retiredFundTaxValue = (targetTaxRetiredFund * 45) / 100 - 65940000;
		}
		return retiredFundTaxValue;
	}

	//산출세액
	const retiredFundTaxValue =
		roundDown(((convertedRetiredFundTax() / 12) * workedYears) / 10) * 10;

	const finalRetiredFundTax = retiredFundTaxValue;
	const retiredFundRegionTaxValue =
		roundDown(finalRetiredFundTax / 10 / 10) * 10;
	const finalRetiredFundRegionTax = retiredFundRegionTaxValue;
	const finalRetiredFund =
		retireFund - finalRetiredFundTax - finalRetiredFundRegionTax;

	return {
		retireMonthDays: result[0]?.days,
		totalWorkingDays: totalWorkingDays,
		retireMonthBeforeDaysInMonth: result[0]?.daysInMonth,
		retireOneMonthBeforeMonthDays: result[1]?.days,
		retireOneMonthBeforeDaysInMonth: result[1]?.daysInMonth,
		retireTwoMonthBeforeMonthDays: result[2]?.days,
		retireTwoMonthBeforeDaysInMonth: result[2]?.daysInMonth,
		retireThreeMonthBeforeMonthDays: result[3]?.days,
		retireThreeMonthBeforeDaysInMonth: result[3]?.daysInMonth,
		retireMonthFirstDateString: result[0]?.startDate,
		retireDateString: retireDateString,
		retireOneMonthBeforeFirstDateString: result[1]?.startDate,
		retireOneMonthBeforeEndDateString: result[1]?.endDate,
		retireTwoMonthBeforeFirstDateString: result[2]?.startDate,
		retireTwoMonthBeforeEndDateString: result[2]?.endDate,
		retireThreeMonthBeforeFirstDateString: result[3]?.startDate,
		retireThreeMonthBeforeEndDateString: result[3]?.endDate,
		retiredYear: retiredYear,
		retiredMonth: retiredMonth,
		retiredYearMonth: retiredYearMonth,
		retiredDate1MonthBeforeYearMonth: retiredDate1MonthBeforeYearMonth,
		retiredDate2MonthBeforeYearMonth: retiredDate2MonthBeforeYearMonth,
		retiredDate3MonthBeforeYearMonth: retiredDate3MonthBeforeYearMonth,
		retiredDate12MonthBeforeYearMonth: retiredDate12MonthBeforeYearMonth,
		averWageOfOneMonth: averWageOfOneMonth(),
		averWageOfOneMonth1: averWageOfOneMonth1(),
		averWageOfOneMonth2: averWageOfOneMonth2(),
		averWageOfOneMonth3: averWageOfOneMonth3(),
		averBonusWages: averBonusWages,
		averAnnualLeavesWages: averAnnualLeavesWages,
		dayOrdinaryWage: dayOrdinaryWage,
		sumOfAverageWages: sumOfAverageWages,
		sumOfAverDays: sumOfAverDays,
		averageFinalDayWage: averageFinalDayWage,
		workedYears: workedYears,
		retireFund: retireFund,
		firedForcastWage: firedForcastWage,
		finalRetiredFund: finalRetiredFund,
		finalRetiredFundTax: finalRetiredFundTax,
		finalRetiredFundRegionTax: finalRetiredFundRegionTax,
		retireFundTaxFree: retireFundTaxFree,
	};
}

export function averMonthDaysUserInputF(
	hourOrdinaryWage,
	paidHolidayLBTime,
	retireFundTaxFreeValue = '',
	userInputDataValue = {}
) {
	const enterDateData = userInputDataValue.workerEnteredDate;
	const retiredDateData = userInputDataValue.retiredDate;
	const retireDateMoment = moment(retiredDateData, 'YYYY-MM-DD');
	const retireDateString = retireDateMoment.format('YYYY-MM-DD');
	const enterDateMoment = moment(enterDateData);
	const totalWorkingDays = userInputDataValue?.midCalDays
		? retireDateMoment.diff(enterDateMoment, 'days') +
		  1 -
		  userInputDataValue?.midCalDays
		: retireDateMoment.diff(enterDateMoment, 'days') + 1;
	const retiredYear = userInputDataValue.retiredDate
		? userInputDataValue.retiredDate?.getFullYear()
		: '';
	const retiredMonth = userInputDataValue.retiredDate
		? userInputDataValue.retiredDate?.getMonth()
		: '';
	const retiredDateMomentFormatted = moment(
		userInputDataValue.retiredDate
	).format('YYYY-MM-DD');

	const retiredYearMonth =
		retiredDateMomentFormatted.split('-')[0] +
		retiredDateMomentFormatted.split('-')[1];
	const retiredDate1MonthBeforeYearMonth = moment(
		`${retiredYear}-${retiredMonth - 1}-01`,
		'YYYY-MM-DD'
	).format('YYYYMM');
	const retiredDate2MonthBeforeYearMonth = moment(
		`${retiredYear}-${retiredMonth - 2}-01`,
		'YYYY-MM-DD'
	).format('YYYYMM');
	const retiredDate3MonthBeforeYearMonth = moment(
		`${retiredYear}-${retiredMonth - 3}-01`,
		'YYYY-MM-DD'
	).format('YYYYMM');
	const retiredDate12MonthBeforeYearMonth = moment(
		`${retiredYear - 1}-${retiredMonth - 1}-01`,
		'YYYY-MM-DD'
	).format('YYYYMM');

	const result = getMonthlyDays(userInputDataValue.retiredDate);

	// 사용자 입력을 기반으로 월별 임금 및 일수를 계산
	const userInputData = {
		midCalDays: numStringToNumber(userInputDataValue.midCalDays || 0),
		retiredMonthWage: numStringToNumber(
			userInputDataValue.realAverWageOfRetiredMonth || 0
		),
		retiredMonthDays: numStringToNumber(
			userInputDataValue.retiredMonthDayCount || 0
		),
		retiredMonth1TotalWage: numStringToNumber(
			userInputDataValue.retireMonth1TotalWage || 0
		),
		retiredMonth1Days: numStringToNumber(
			userInputDataValue.retiredMonthDayCount01 || 0
		),
		retiredMonth2TotalWage: numStringToNumber(
			userInputDataValue.retireMonth2TotalWage || 0
		),
		retiredMonth2Days: numStringToNumber(
			userInputDataValue.retiredMonthDayCount02 || 0
		),
		retiredMonth3TotalWage: numStringToNumber(
			userInputDataValue.retireMonth3TotalWage || 0
		),
		retiredMonth3Days: numStringToNumber(
			userInputDataValue.retiredMonthDayCount03 || 0
		),
		retireYearTotalAnnualLeavesWages: numStringToNumber(
			userInputDataValue.retireYearTotalAnnualLeavesWages || 0
		),
		retireYearTotalBonusWages: numStringToNumber(
			userInputDataValue.retireYearTotalBonusWages || 0
		),
	};

	// 사용자 입력 기반 평균 임금 계산
	const sumOfAverageWages =
		userInputData.retiredMonthWage +
		userInputData.retiredMonth1TotalWage +
		userInputData.retiredMonth2TotalWage +
		userInputData.retiredMonth3TotalWage +
		roundUp2(
			((+userInputData.retireYearTotalAnnualLeavesWages +
				+userInputData.retireYearTotalBonusWages) /
				12) *
				3
		);

	// 사용자 입력 기반 일수 계산
	const sumOfAverDays =
		userInputData.retiredMonthDays +
		userInputData.retiredMonth1Days +
		userInputData.retiredMonth2Days +
		userInputData.retiredMonth3Days;

	const dayOrdinaryWage = userInputDataValue?.dayOrdinaryWage
		? commaStringToNumber(userInputDataValue?.dayOrdinaryWage)
		: hourOrdinaryWage * paidHolidayLBTime;
	const retireAverageDayWage = roundUp2(sumOfAverageWages / sumOfAverDays);
	const averageFinalDayWage =
		dayOrdinaryWage > retireAverageDayWage
			? dayOrdinaryWage
			: retireAverageDayWage;

	const workedYears = roundUp(totalWorkingDays / 365);
	const retireFund = Math.ceil(
		averageFinalDayWage * (totalWorkingDays / 365) * 30
	);
	const firedForcastWage = dayOrdinaryWage * 30;

	const retireFundTaxFree = retireFundTaxFreeValue
		? numStringToNumber(retireFundTaxFreeValue)
		: 0;

	const retiredFundDeductTaxFree = retireFund - retireFundTaxFree;

	function workedYearTaxMinus() {
		let yearTaxMinus;
		if (workedYears <= 5) {
			yearTaxMinus = workedYears * 1000000;
		} else if (workedYears <= 10) {
			yearTaxMinus = 5000000 + (workedYears - 5) * 2000000;
		} else if (workedYears <= 20) {
			yearTaxMinus = 15000000 + (workedYears - 10) * 2500000;
		} else {
			yearTaxMinus = 40000000 + (workedYears - 20) * 3000000;
		}
		return yearTaxMinus;
	}

	const convertedRetiredFund =
		((retiredFundDeductTaxFree - workedYearTaxMinus()) * 12) / workedYears;

	function convertedRetiredFundTaxMinus() {
		let convertedTaxMinusValue;
		if (convertedRetiredFund <= 8000000) {
			convertedTaxMinusValue = convertedRetiredFund;
		} else if (convertedRetiredFund <= 70000000) {
			convertedTaxMinusValue =
				8000000 + ((convertedRetiredFund - 8000000) * 60) / 100;
		} else if (convertedRetiredFund <= 100000000) {
			convertedTaxMinusValue =
				45200000 + ((convertedRetiredFund - 70000000) * 55) / 100;
		} else if (convertedRetiredFund <= 300000000) {
			convertedTaxMinusValue =
				61700000 + ((convertedRetiredFund - 100000000) * 45) / 100;
		} else {
			convertedTaxMinusValue =
				151700000 + ((convertedRetiredFund - 300000000) * 35) / 100;
		}
		return convertedTaxMinusValue;
	}

	const targetTaxRetiredFund = roundUp(
		convertedRetiredFund - convertedRetiredFundTaxMinus()
	);

	function convertedRetiredFundTax() {
		let retiredFundTaxValue;
		if (targetTaxRetiredFund <= 14000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 6) / 100;
		} else if (targetTaxRetiredFund <= 50000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 15) / 100 - 1260000;
		} else if (targetTaxRetiredFund <= 88000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 24) / 100 - 5760000;
		} else if (targetTaxRetiredFund <= 150000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 35) / 100 - 15440000;
		} else if (targetTaxRetiredFund <= 300000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 38) / 100 - 19940000;
		} else if (targetTaxRetiredFund <= 500000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 40) / 100 - 25940000;
		} else if (targetTaxRetiredFund <= 1000000000) {
			retiredFundTaxValue = (targetTaxRetiredFund * 42) / 100 - 35940000;
		} else {
			retiredFundTaxValue = (targetTaxRetiredFund * 45) / 100 - 65940000;
		}
		return retiredFundTaxValue;
	}

	const retiredFundTaxValue =
		roundDown(((convertedRetiredFundTax() / 12) * workedYears) / 10) * 10;

	const finalRetiredFundTax = retiredFundTaxValue;
	const retiredFundRegionTaxValue =
		roundDown(finalRetiredFundTax / 10 / 10) * 10;
	const finalRetiredFundRegionTax = retiredFundRegionTaxValue;
	const finalRetiredFund =
		retireFund - finalRetiredFundTax - finalRetiredFundRegionTax;

	return {
		retireMonthDays: result[0]?.days,
		totalWorkingDays: totalWorkingDays,
		retireMonthBeforeDaysInMonth: result[0]?.daysInMonth,
		retireOneMonthBeforeMonthDays: result[1]?.days,
		retireOneMonthBeforeDaysInMonth: result[1]?.daysInMonth,
		retireTwoMonthBeforeMonthDays: result[2]?.days,
		retireTwoMonthBeforeDaysInMonth: result[2]?.daysInMonth,
		retireThreeMonthBeforeMonthDays: result[3]?.days,
		retireThreeMonthBeforeDaysInMonth: result[3]?.daysInMonth,
		retireMonthFirstDateString: result[0]?.startDate,
		retireDateString: retireDateString,
		retireOneMonthBeforeFirstDateString: result[1]?.startDate,
		retireOneMonthBeforeEndDateString: result[1]?.endDate,
		retireTwoMonthBeforeFirstDateString: result[2]?.startDate,
		retireTwoMonthBeforeEndDateString: result[2]?.endDate,
		retireThreeMonthBeforeFirstDateString: result[3]?.startDate,
		retireThreeMonthBeforeEndDateString: result[3]?.endDate,
		retiredYear: retiredYear,
		retiredMonth: retiredMonth,
		retiredYearMonth: retiredYearMonth,
		retiredDate1MonthBeforeYearMonth: retiredDate1MonthBeforeYearMonth,
		retiredDate2MonthBeforeYearMonth: retiredDate2MonthBeforeYearMonth,
		retiredDate3MonthBeforeYearMonth: retiredDate3MonthBeforeYearMonth,
		retiredDate12MonthBeforeYearMonth: retiredDate12MonthBeforeYearMonth,
		averBonusWages: userInputData.retireYearTotalBonusWages,
		averAnnualLeavesWages: userInputData.retireYearTotalAnnualLeavesWages,
		dayOrdinaryWage: dayOrdinaryWage,
		sumOfAverageWages: sumOfAverageWages,
		sumOfAverDays: sumOfAverDays,
		averageFinalDayWage: averageFinalDayWage,
		workedYears: workedYears,
		retireFund: retireFund,
		firedForcastWage: firedForcastWage,
		finalRetiredFund: finalRetiredFund,
		finalRetiredFundTax: finalRetiredFundTax,
		finalRetiredFundRegionTax: finalRetiredFundRegionTax,
		retireFundTaxFree: retireFundTaxFree,
	};
}
