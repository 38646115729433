import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Modal } from 'semantic-ui-react';
import ClientPrivateProtectPaperContents from './ClientPrivateProtectPaperContents';

export default function ClientPrivateProtectPaperIntro({ isOpen, onAgree, onClose }) {

  const {currentUserProfile} = useSelector(state => state.profile)
  const {clientInfo} = useSelector(state => state.companyReducer)

  const agentType = clientInfo?.agentType ? clientInfo?.agentType : currentUserProfile?.agentType;
  const referralAgentType = clientInfo?.referralAgentType ? clientInfo?.referralAgentType : currentUserProfile?.referralAgentType;

  return (
    <Modal open={isOpen} onClose={onClose} size="small">
      <Modal.Header>AI NOMUSA 개인정보처리방침</Modal.Header>
      <Modal.Content>
        <ClientPrivateProtectPaperContents currentUserProfile={currentUserProfile} agentType={agentType} referralAgentType={referralAgentType} />
      </Modal.Content>
      <Modal.Actions>
        <Button color='green' onClick={onAgree}>동의합니다</Button>
        <Button onClick={onClose}>닫기</Button>
      </Modal.Actions>
    </Modal>
  )
}
