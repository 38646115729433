import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function RuleOfEmployeeMenu({
	freeComClient5,
	isCoWorkSemusa,
	BsFillFileEarmarkRuledFill,
	viewId,
	isWorker,
	roeConsulting,
	ruleLaborCouncilConsulting,
	rOEBizSecretConsulting,
	roeExcuteDueDate,
	rlcExcuteDueDate,
	bizSecretExcuteDueDate,
}) {
	const history = useHistory();
	const { currentUserProfile } = useSelector((state) => state.profile);

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	const isRoeServiceOk = isWorker
		? Boolean(roeExcuteDueDate) &&
		  (roeConsulting === '취업규칙 컨설팅 요청' ||
				roeConsulting === '노동법률 자문사')
		: roeConsulting === '취업규칙 컨설팅 요청' ||
		  roeConsulting === '노동법률 자문사';

	const isLaborCouncilServiceOk = isWorker
		? Boolean(rlcExcuteDueDate) &&
		  (ruleLaborCouncilConsulting === '노사협의회 컨설팅 요청' ||
				ruleLaborCouncilConsulting === '노동법률 자문사')
		: ruleLaborCouncilConsulting === '노사협의회 컨설팅 요청' ||
		  ruleLaborCouncilConsulting === '노동법률 자문사';

	const isROEBSServiceOk = isWorker
		? Boolean(bizSecretExcuteDueDate) &&
		  (rOEBizSecretConsulting === '영업비밀 컨설팅 요청' ||
				rOEBizSecretConsulting === '영업비밀 노동법률 자문사')
		: rOEBizSecretConsulting === '영업비밀 컨설팅 요청' ||
		  rOEBizSecretConsulting === '영업비밀 노동법률 자문사';

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const noUserInfo =
		currentUserProfile?.ceoName || currentUserProfile?.workerInputCompanyName
			? false
			: true;

	useEffect(() => {
		function noUserInfoF() {
			alert(`회원가입 후 회원 정보를 입력해야 사용가능합니다.`);
			currentUserProfile?.id
				? history.push(`/profile/${currentUserProfile?.id}`)
				: history.push('/pricePolicyPaper');
		}
		noUserInfo && noUserInfoF();
	}, [noUserInfo, history, currentUserProfile?.id]);

	return (
		<>
			{
				<Menu.Item
					className='menu-item'
					onMouseOver={handleMouseOver}
					onMouseLeave={handleMouseLeave}>
					{!isMobile && <BsFillFileEarmarkRuledFill className='icon-spacing' />}
					<Dropdown
						pointing='top left'
						text='회사규정'
						open={dropdownOpen}
						onClick={handleDropdownToggle}>
						{freeComClient5 || isCoWorkSemusa ? (
							<Dropdown.Menu>
								<Dropdown.Item
									onClick={() =>
										alert(
											'취업규칙 유료 컨설팅을 받으신 회원사에게 제공됩니다.'
										)
									}
									as={Link}
									to={`/pricePolicyPaper/`}
									text='취업규칙'
								/>
								<Dropdown.Item
									onClick={() =>
										alert(
											'30인 이상 사업장 중 노사협의회 제도설계 유료 컨설팅 받으신 회원사에게 제공됩니다.'
										)
									}
									as={Link}
									to={`/pricePolicyPaper/`}
									text='노사협의회규정'
								/>
							</Dropdown.Menu>
						) : (
							<Dropdown.Menu style={{ width: '200px' }}>
								{isRoeServiceOk ? (
									<Dropdown.Item
										as={Link}
										to={`/ruleOfEmployee/${viewId}`}
										text='취업규칙'
									/>
								) : isWorker ? (
									<Dropdown.Item
										onClick={() =>
											alert(
												'취업규칙 유료 컨설팅을 받으신 회원사에게 제공됩니다.'
											)
										}
										as={Link}
										to={`/`}
										text='취업규칙'
									/>
								) : (
									<Dropdown.Item
										onClick={() =>
											alert(
												'취업규칙 유료 컨설팅을 받으신 회원사에게 제공됩니다.'
											)
										}
										as={Link}
										to={`/pricePolicyPaper/`}
										text='취업규칙'
									/>
								)}
								{isLaborCouncilServiceOk ? (
									<Dropdown.Item
										as={Link}
										to={`/ruleOfLaborCouncil/${viewId}`}
										text='노사협의회규정'
									/>
								) : (
									<Dropdown.Item
										onClick={() =>
											alert(
												'30인 이상 사업장 중 노사협의회 제도설계 유료 컨설팅 받으신 회원사에게 제공됩니다.'
											)
										}
										as={Link}
										to={`/pricePolicyPaper/`}
										text='노사협의회규정'
									/>
								)}
								{isROEBSServiceOk ? (
									<Dropdown.Item
										as={Link}
										to={`/ruleOfEmployeeBizSecret/${viewId}`}
										text='영업비밀보호규정'
									/>
								) : (
									<Dropdown.Item
										onClick={() =>
											alert(
												'영업비밀보호 제도설계 유료 컨설팅 받으신 회원사에게 제공됩니다.'
											)
										}
										as={Link}
										to={`/pricePolicyPaper/`}
										text='영업비밀보호규정'
									/>
								)}
								{/* <Dropdown.Item as={Link} to={`/representativesAgreement/${currentUserProfile.id}`} text='근로자대표서면합의서' /> */}
							</Dropdown.Menu>
						)}
					</Dropdown>
				</Menu.Item>
			}
		</>
	);
}
