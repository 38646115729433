import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PayPaperLaborContract() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI NOMUSA 임금명세서 임금계약서, 근로계약서 연계 설명">
      <YouTube videoId='KCqx3gLC4YA' opts={opts} />
    </ModalWrapper>
  )
}
