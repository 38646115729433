import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function WorkerRegStep1_4() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI NOMUSA 정년 퇴직 이후 촉탁직 등록">
      <YouTube videoId='akNUD22JdgM' opts={opts} />
    </ModalWrapper>
  )
}
