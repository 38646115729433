import React from 'react'

export default function ClientDashNewFunction({setOpenWhatsNew, openWhatsNew}) {
  return (
    
    <div onClick={() => setOpenWhatsNew(!openWhatsNew)} style={{cursor:'pointer'}}>
      {/* <h6>11월 19일 : 11월 19일 개정 노동법(산전후휴가, 육아휴직 등) 취업규칙 반영(전후 비교 포함)</h6> */}
      {/* <h6>11월 24일 : 월급제지만, 근무일에 비례해서 지급하는 일당 개념 임금을 통상/최저임금에 반영(알고리즘) 추가  </h6> */}
      {/* <h6 style={{marginTop: '-20px', marginBottom: '-20px'}}>12월 04일 : 임금명세서 결근, 지각 등 공제 / 중도입퇴사 자동 일할 계산 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>12월 05일 : 세금 및 4대보험 설정 관련 설명 youtube 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>12월 18일 : 직원 로그인을 통한 임금명세서 교부 의무 이행 확인 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>12월 20일 : 2022년 건강보험, 요양보험, 고용보험 인상 요율 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>12월 21일 : 세후 계산기 - 국민연금, 건강보험 보수월액에 따른 계산 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>12월 24일 : Dynamic 임금대장 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>12월 27일 : 직원 로그인을 통한 근로계약서 열람 확인 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>12월 27일 : 법정연차, 회계기준 연차 등 직원별 발생 연차 확인 기능 추가 </h6>
      <h3 style={{marginBottom: '-20px'}}>12월 28일 : 세후 계산기 - 고용보험 보수월액에 따른 계산 기능 추가</h3>
      */}
      <h6 style={{marginTop: '20px', marginBottom: '-20px'}}>1월 14일 : 임금명세서에 중도입퇴사 입력없이 자동계산 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>1월 17일 : 임금명세서에 사용연차 추가로 잔여 연차 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>1월 17일 : 임금명세서에 결근일 입력시 1년미만자 월 단위 연차 공제 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>1월 28일 : 평균임금에 비과세 수당 설정 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>2월 22일 : 직원별 특이사항 기록을 위한 메모 기능 추가(직원등록 Step 1.(9)에서 기록하고 직원List 상세보기에 노출됨)</h6>
      <h6 style={{marginBottom: '-20px'}}>3월 18일 : 교대제 등 비정형 근로시간 근무 월급제 근로계약서, 임금계약서, 임금명세서 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>5월 19일 : 연차사용내역 근로자별 로그인 안내 기능 및 연차/근태 대장 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>6월 17일 : 안전보건활동 리포트 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>7월 1일 : 일용직이 아닌 월 주기 임금받는 일당제 급여명세서 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>7월 5일 : 임금대장 3단 표시 추가 및 일당제 고용보험 신고용 대장 기능 추가 </h6>
      <h6 style={{marginBottom: '-20px'}}>7월 31일 : 근로기준법 제63조 농업, 어업, 축산업 및 감단직, 관리감독자 근로시간, 휴게, 휴일 적용제외 근로계약서, 임금명세서, 임금계약서 시스템 구축</h6>
      <h6 style={{marginBottom: '-20px'}}>8월 3일 : 임금명세서 : 매월 1일 현재 만 60세 이상 국민연금료, 만 65세 고용보험료 미공제 로직 반영</h6>
      <h6 style={{marginBottom: '-20px'}}>8월 6일 : 건강보험 퇴직 정산 자동 계산 기능 추가(임금명세서 메뉴(퇴직일자 있는 경우만 보임))</h6>
      <h6 style={{marginBottom: '-20px'}}>8월 20일 : 회계년도 연차 부여 1년미만자 연차소멸을 입사 1년되는 날이 아닌 회계년도 말일로 시스템 변경 및 취업규칙, 근로계약서 그 부분 내용 변경</h6>
      <h6 style={{marginBottom: '-20px'}}>9월 2일 : 중도입사자 건강, 요양보험료도 "당월과세"로 설정시 자동 계산 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>9월 12일 : 법정 퇴직금 자동 계산 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>9월 21일 : 이직확인서, 휴업수당 등 계산을 위한 평균임금 자동 계산 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>9월 26일 : 직원별 임금대장(임금채권 소멸시효에 따라 최대 3년분 임금 표시) 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>10월 13일 : 임금명세서 출력시 디자인 수정</h6>
      <h6 style={{marginBottom: '-20px'}}>10월 17일 : 근로소득세 부양가족 수에 따른 공제 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>10월 27일 : 일용직 일자 및 일당 소수점 작업 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>11월 03일 : 근로소득세 부양가족(7세이상 20세이상) 수에 따른 공제 기능 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>11월 08일 : 첫 화면 직원리스트에 2023년 최저임금 부족액 예상액 표시</h6>
      <h6 style={{marginBottom: '-20px'}}>11월 23일 : 미사용 연차휴가 금액 대신 일수만 넣으면 자동 계산 기능 임금명세서에 추가</h6>
      <h6 style={{marginBottom: '-20px'}}>11월28일 : 직원 등록시 회원가입(등록) 여부, 퇴직자 및 임시비번 표시</h6>
      <h6 style={{marginBottom: '-20px'}}>12월10일 : 연차대장에 연차수당 집계 표함.</h6>
      <h6 style={{marginBottom: '-20px'}}>12월17일 : 2022년 12월 31일 기준 연차 현황 Dashboard 상세보기에 포함(2023년2월까지 게시함).</h6>
      <h3 style={{marginBottom: '-20px'}}>12월24일 : 2023년 변경 노동법 안내.</h3>
      <h3 style={{marginBottom: '-20px'}}>12월24일 : 2023년 급여작업용 건강보험 요율인상, 식대 비과세 한도 20만원 적용.</h3>
      <h3 style={{marginBottom: '-20px'}}>12월31일 : 정년퇴직 이후 촉탁계약직 근로계약서 탑재(근속기간 단절계약서).</h3>
      <h3 style={{marginBottom: '-20px'}}>01월02일 : 건강보험, 국민연금 제외자 소정근로 월 60시간, 주 13.8시간(60시간 나누기 4.345주)으로 로직 정교화</h3>
      <h3 style={{marginBottom: '-20px'}}>01월10일 : 정년퇴직 이후 촉탁근로자 관련법에 따라 만 55세 이상근로자로 한정하여 출력하는 로직 반영.</h3>
      <h3 style={{marginBottom: '-20px'}}>01월15일 : 15세 이상 18세 미만 연소근로자 근로계약서, 연봉계약서, 임금명세서 개발</h3>
      <h3 style={{marginBottom: '-20px'}}>01월19일 : 정규직 개인별 급여대장 연도별 검색 및 합계금액 표시 모듈 개발</h3>
      <h3 style={{marginBottom: '-20px', color: 'red'}}>01월29일 : 직원 등록, 임금명세서 설명 동영상(노동법 포함) 탑재</h3>
      <h3 style={{marginBottom: '-20px'}}>02월07일 : 격주 근무자용 2주단위 탄력적 근로시간제 근로계약서 탑재</h3>
      <h3 style={{marginBottom: '-20px'}}>02월15일 : 계약직의 재계약일로 근로계약서 작성 기능 추가</h3>
      <h3 style={{marginBottom: '-20px' }}>02월19일 : 직원 계좌정보를 이용한 월별 계좌이체 대장 추가</h3>
      <h3 style={{marginBottom: '-20px', color: 'red'}}>03월9일 : 법률위반 근로계약서 출력불가 기능 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>03월12일 : 2023 청년장려금, 육아휴직 대체인력 지원금 대상자 안내 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>03월15일 : 고용안정지원금 관련 감원방지 의무기간 안내 프로그램 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>03월17일 : 고용안정지원금 대상자와 감원방지의무기간 자동연결 및 설명표시 프로그램 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>03월31일 : 근로기준법 제48조 제1항의 임금대장 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>04월3일 : 임금명세서 이월복사 프로그램 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>04월12일 : 매월 임금명세서 작업시 직원 역량모델링을 위한 업무수행 feedback 프로그램 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>04월25일 : 출산전후 휴가 및 육아휴직 관련 프로그램 추가</h3>
      <h3 style={{marginBottom: '-20px'}}>05월01일 : 법정 출산전후휴가 급여 해당월 임금자동계산 프로그램 추가(임금명세서)</h3>
      <h3 style={{marginBottom: '-20px'}}>06월19일 : 취업규칙 제43조 수습기간 변경 안내 : 최저임금 90% 지급조건에 단순노무직은 제외 조항 산입</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 01월 01일 : 2024년 최저임금액 9,860원 반영 안내</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 01월 05일 : 출근일마다 지급하는 식대를 평균임금으로 분류</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 01월 06일 : 출근일마다 지급하는 식대를 평균임금으로 분류한 내용 임금계약서에 명기</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 01월 06일 : 초단시간 사원은 취업규칙의 휴일, 연차유급휴가, 퇴직금 조항 제외하도록 취업규칙 개정</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 01월 07일 : 급여작업시 1주 소정근로시간 4인이하 근로자의 중도입퇴사 일할 보정계산이 필요한 경우 작업 안내 알람 시스템 구축</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 01월 08일 : 급여작업시 마감 해제 기능 구축</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 01월 20일 : 1월 급여작업부터 육아수당 월 20만원 비과세로 상향 로직 구현</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 02월 20일 : 고용안정지원금 감원 방지기간 안내 로직 개선</h3>
      <h3 style={{marginBottom: '-20px', color: 'blue'}}>24년 02월 29일 : 재직자와 퇴사자 보기 구분, 화면 아래 위 바로가기 버튼 추가</h3>
      <h3 style={{marginBottom: '20px', color: 'blue'}}>* 24년 03월 15일 : DC형 퇴직연금 임금대장 추가</h3>
      <button style={{fontSize : '15px', color: 'black', height: '30px', width: '200px', background: 'white'}} onClick={() => setOpenWhatsNew(!openWhatsNew)}>내용 닫기</button>
      <hr />
    </div>
  )
}