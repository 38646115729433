import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Grid, Input, Loader } from 'semantic-ui-react';
import ClientReqList4In from './ClientReqList4In';
import { getWorkerUserProfile } from '../../../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../../../profiles/profileActions';
import { dataFromSnapshotIdDate } from '../../../../app/firestore/firestoreService';
import { josaGaType, lastPreventDateF } from '../../../../app/common/util/util';
// import { clearPaydocu, fetchAllPaydocusFormFirestoreService4In } from '../../../companyManage/companyActions';
import { clearClientReqs4In, fetchClientReqs4In } from '../clientReqActions4In';
import useScrollRestoration from '../../../../app/hooks/useScrollRestoration';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import ClientReqDashboard4InWorker from '../clientsReqDashboard4In/dashboardDetail/ClientReqDashboard4InWorker';
import ClientReqDashboard4InAuthenticated from '../clientsReqDashboard4In/dashboardDetail/ClientReqDashboard4InAuthenticated';
import { format } from 'date-fns';

export default function ClientReqDashboard4In() {
	const { saveScrollPosition, restoreScrollPosition } = useScrollRestoration();
	const dispatch = useDispatch();
	const history = useHistory();
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);
	const { filter, clientInfo } = useSelector((state) => state.companyReducer);

	// const { paydocus  } = useSelector(state => state.companyReducer);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);

	const isWorker = currentUserProfile?.isWorker;
	const isDayWorker = currentUserProfile?.isDayWorker;
	const isBizWorker = currentUserProfile?.isBizWorker;
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const laborContractId = currentUserProfile?.paydocuWorkerUid;
	const companyNameC = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName;
	const companyName = isWorker
		? currentUserProfile?.workerInputCompanyName
			? currentUserProfile?.workerInputCompanyName
			: companyNameC
		: companyNameC;

	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: currentUserProfile?.numOfWorkers;
	const annualLeaves = clientInfo?.annualLeaves
		? clientInfo?.annualLeaves
		: currentUserProfile?.annualLeaves;
	const finBaseDate = clientInfo?.finBaseDate
		? clientInfo?.finBaseDate
		: currentUserProfile?.finBaseDate;

	const companyNameUn = companyName ? josaGaType(companyName) : null;

	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	useEffect(() => {
		if (currentUserProfile) {
			dispatch(fetchClientReqs4In(filter)).then(() => {});
		}
		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter, currentUserProfile]);

	useEffect(() => {
		if (currentUserProfile) {
			// currentUserProfile이 존재할 때만 실행
			const unSubscribe = getWorkerUserProfile({
				next: (snapshot) =>
					dispatch(
						listenToWorkerUsers(
							snapshot.docs.map((docSnapshot) =>
								dataFromSnapshotIdDate(docSnapshot)
							)
						)
					),
				error: (error) => console.log(error),
			});
			return () => {
				unSubscribe();
			};
		}
	}, [dispatch, currentUserProfile]); // 의존성 배열에 currentUserProfile 추가

	const noUserInfo =
		currentUserProfile?.ceoName || currentUserProfile?.workerInputCompanyName
			? false
			: true;

	useEffect(() => {
		function noUserInfoF() {
			alert(`회원가입 후 회원 정보를 입력해야 사용가능합니다.`);
			currentUserProfile?.id
				? history.push(`/profile/${currentUserProfile?.id}`)
				: history.push('/pricePolicyPaper');
		}
		noUserInfo && noUserInfoF();
	}, [noUserInfo, history, currentUserProfile?.id]);

	useEffect(() => {
		restoreScrollPosition();

		return () => {
			saveScrollPosition();
		};
	}, []); // eslint-disable-line react-hooks/exhaustive-deps

	// finBaseDate가 Firestore 타임스탬프 형식인지 확인하고 변환하는 함수
	const toDateIfTimestamp = (timestamp) => {
		// "seconds" 속성을 확인하여 Firestore 타임스탬프 형식인지 판단
		if (timestamp && typeof timestamp === 'object' && 'seconds' in timestamp) {
			return new Date(timestamp.seconds * 1000);
		}
		// 이미 Date 객체거나 다른 형태인 경우 그대로 반환
		return timestamp;
	};

	const finBaseDateF = finBaseDate
		? format(toDateIfTimestamp(finBaseDate), 'yyyy-MM-dd')
		: '';
	const lastPreventData = lastPreventDateF(clientReqs4In);
	const realCount = true;
	const lastPreventData6 = lastPreventData ? lastPreventData[6] : null;
	const today = new Date();
	const formatteTodayDate = format(toDateIfTimestamp(today), 'yyyy-MM-dd');
	const lastPreventDataShow =
		lastPreventData6 && (formatteTodayDate <= lastPreventData6 ? true : false);

	function reloadToClientReqs4InInfo() {
		history.push('/clientReqs4InInfo');
	}

	return (
		<>
			{isWorker ? (
				<ClientReqDashboard4InWorker
					companyNameUn={companyNameUn}
					isDayWorker={isDayWorker}
					isBizWorker={isBizWorker}
					laborContractId={laborContractId}
					history={history}
				/>
			) : (
				<>
					<PaydocuShortCut
						filter={filter}
						clientReqs4In={clientReqs4In}
						realCount={realCount}
					/>
					<Grid>
						<Grid.Column width={16}>
							<>
								<div onClick={reloadToClientReqs4InInfo}>
									<h3
										style={{
											color: 'teal',
											marginTop: '0px',
											marginBottom: '25px',
											cursor: 'pointer',
										}}>
										{' '}
										<span style={{ fontWeight: 'bolder', color: 'red' }}>
											What's New{' '}
										</span>
										24년 03월 15일 : DC형 퇴직연금 불입액 임금대장 추가
										<span style={{ color: 'blue' }}> (전체보기)</span>
									</h3>
								</div>
								<hr />
								{authenticated && (
									<>
										<ClientReqDashboard4InAuthenticated
											lastPreventData={lastPreventData}
											lastPreventDataShow={lastPreventDataShow}
											numOfWorkers={numOfWorkers}
											authLevel={authLevel}
											annualLeaves={annualLeaves}
											finBaseDateF={finBaseDateF}
										/>
										{authLevel > 10 && (
											<>
												<span
													style={{ marginBottom: '15px', fontSize: '17px' }}>
													{authLevel >= 90
														? '회사명 또는 직원성명'
														: '직원성명'}
												</span>
												:{' '}
												<Input
													type='text'
													placeholder='Search'
													value={searchTerm}
													onChange={handleChange}
												/>
												<br />
											</>
										)}
										<ClientReqList4In
											authLevel={authLevel}
											lastPreventData={lastPreventData}
											clientReqs4In={clientReqs4In}
											searchResults={searchResults}
											setSearchResults={setSearchResults}
											searchTerm={searchTerm}
											// paydocus={paydocus}
											companyName={companyName}
										/>
									</>
								)}
							</>
						</Grid.Column>
						<Grid.Column width={16}>
							<Loader active={loading} />
						</Grid.Column>
					</Grid>
				</>
			)}
		</>
	);
}
