import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Grid } from 'semantic-ui-react';
import { listenToClientReq4InFromFirestore } from '../../../../app/firestore/firestoreService4In';
import useFirestoreDoc from '../../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../../app/layout/LoadingComponent';
import { clearClientReqs4In, fetchClientReqs4In, listenToSelectedClientReq4In } from '../clientReqActions4In';
import ClientReqDetailedHeader4In from './ClientReqDetailedHeader4In';
import ClientReqDLCDWageMonthWageCon from './clientReqDetailedLCDetail/ClientReqDLCDWageMonthWageCon';
import ClientReqDLCHeadWageCon from './clientReqDetailedLCDetail/ClientReqDLCHeadWageCon';
import ClientReqDLCJudgeWageCon from './clientReqDetailedLCDetail/ClientReqDLCJudgeWageCon';
import ClientReqDLCTail from './clientReqDetailedLCDetail/ClientReqDLCTail';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import { contractOldWorkerF, getAgeFromSocialNumber, numStringToNumber } from '../../../../app/common/util/util';
import { decrypto } from '../../../../app/firestore/firestoreService';

export default function ClientRDLWageContract({match}) {
  const htmlContentRef = useRef(null);
  const dispatch = useDispatch()
  const {selectedClientReq4In, clientReqs4In} = useSelector(state => state.clientReq4InStore);
  const { filter, clientInfo } = useSelector(state => state.companyReducer);
  const {loading, error} = useSelector(state => state.async);
  const {currentUserProfile} = useSelector(state => state.profile);
  const numOfWorkers = selectedClientReq4In?.numOfWorkers;

  //노무사는 노무사가 입력한 회사로 회사이름 설정 가능하고, 유저는 본인 회원가입한 상호로만 회사이름 설정됨.
  const isWorker = currentUserProfile?.isWorker;
  const isEtcWorker = selectedClientReq4In?.isEtcWorker;
  const isExceptTimeLawWorker = selectedClientReq4In?.isExceptTimeLawWorker;

  const skipWeek1 = selectedClientReq4In?.selectWorkDays1Info?.skipWeek === "격주근무" ? true : false
  const skipWeek2 = selectedClientReq4In?.selectWorkDays2Info?.skipWeek === "격주근무" ? true : false
  const skipWeek3 = selectedClientReq4In?.selectWorkDays3Info?.skipWeek === "격주근무" ? true : false
  const skipWeek4 = selectedClientReq4In?.selectWorkDays4Info?.skipWeek === "격주근무" ? true : false
  const skipWeek5 = selectedClientReq4In?.selectWorkDays5Info?.skipWeek === "격주근무" ? true : false
  const skipWeek6 = selectedClientReq4In?.selectWorkDays6Info?.skipWeek === "격주근무" ? true : false

  const isSkipWeekedWorker = (skipWeek1 || skipWeek2 || skipWeek3 || skipWeek4 || skipWeek5 || skipWeek6) ? true : false
  
  const perDayMealTaxFree = clientInfo?.perDayMealTaxFree ? clientInfo?.perDayMealTaxFree : null;

  const companyName =  isWorker ? (currentUserProfile?.workerInputCompanyName ? currentUserProfile?.workerInputCompanyName : selectedClientReq4In?.companyName) : 
                        clientInfo?.companyName ? clientInfo?.companyName :
                        selectedClientReq4In?.company?.companyName ? selectedClientReq4In?.company?.companyName :
                        selectedClientReq4In?.companyName;

  const workerSocialNumberFront = decrypto(selectedClientReq4In?.workerSocialNumberFront,  String(process.env.CRYPTO_KEY))
  const netAge = getAgeFromSocialNumber(workerSocialNumberFront);
  const worker = selectedClientReq4In?.worker?.name;

  const contractOldWorker = contractOldWorkerF(selectedClientReq4In?.contractOldWorker, selectedClientReq4In?.afterRetireAgainContractEndDate, selectedClientReq4In?.afterRetireAgainContractStartDate
    , netAge)

  // 1주 소정근로시간
  const weekLBTime = selectedClientReq4In?.lawBase?.weekLBTime;
  const wageComment = selectedClientReq4In?.wageComment;

  useFirestoreDoc({
    shouldExcute: match.params.id !== selectedClientReq4In?.id && Location.pathname !== '/createClientReq4In',
    query: () => listenToClientReq4InFromFirestore(match.params.id),
    data: clientReq4In => dispatch(listenToSelectedClientReq4In(clientReq4In)),
    deps: [match.params.id, dispatch]
  })

  useEffect(() => {
    dispatch(fetchClientReqs4In(filter)).then(() => {
    });
    return () => {
      dispatch(clearClientReqs4In()); 
    };
  }, [dispatch, filter]);

  const daylyTimePay = selectedClientReq4In?.daylyPay?.timeWage ? numStringToNumber(selectedClientReq4In?.daylyPay?.timeWage) : 0;
  const isDayOrTimeWorker = (selectedClientReq4In?.dayWorker?.daylyPay || daylyTimePay) ? true : false;

  const superAdminTrue = currentUserProfile?.authLevel > 99 ? true : false;

  if (loading || (!selectedClientReq4In && !error)) 
  return <LoadingComponent content='Loading event ...' />;
  if (error) return <Redirect to='/error' />;


  return (
    <>
      {isWorker ? null : <PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In}/> }
        
      <Grid>
        <Grid.Column width={16}>
          <ClientReqDetailedHeader4In 
            selectedClientReq4In={selectedClientReq4In} 
            companyName={companyName}
            clientReqs4In={clientReqs4In}
            daylyTimePay={selectedClientReq4In?.daylyTimePay} 
            isDayOrTimeWorker={isDayOrTimeWorker}
          />
          <ClientReqDLCJudgeWageCon
            selectedClientReq4In={selectedClientReq4In} 
            workerId={selectedClientReq4In.id}
            isEtcWorker={isEtcWorker}
            htmlContentRef={htmlContentRef}
            superAdminTrue={superAdminTrue}
          />
          <div id='laborWageContract' ref ={htmlContentRef}>
            <div className='section'>
              <ClientReqDLCHeadWageCon
                isSkipWeekedWorker={isSkipWeekedWorker}
                isExceptTimeLawWorker={isExceptTimeLawWorker}
                selectedClientReq4In={selectedClientReq4In}
                worker={worker}
                companyName={companyName}
                weekLBTime={weekLBTime}
                contractOldWorker={contractOldWorker}
                />
            </div>
            <br />
            <div className='section'>
              <ClientReqDLCDWageMonthWageCon 
                selectedClientReq4In={selectedClientReq4In} 
                numOfWorkers={numOfWorkers}
                weekLBTime={weekLBTime}
                worker={worker}
                companyName={companyName}
                wageComment={wageComment}
                isEtcWorker={isEtcWorker}
                isExceptTimeLawWorker={isExceptTimeLawWorker}
                perDayMealTaxFree={perDayMealTaxFree}
              /> 
            </div>
            <div className='section'>
              <ClientReqDLCTail 
                clientInfo={clientInfo}
                selectedClientReq4In={selectedClientReq4In}
                worker={worker}
                companyName={companyName}
              />
            </div>

            <footer>
              저작권 © AI NOMUSA(노무법인 최상인업). All rights reserved since 2002.
            </footer>
          </div>
        </Grid.Column>
      </Grid>
    </>
  )
}