import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function  PayPaperRetire() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI NOMUSA 임금명세서 퇴직 건보정산 및 퇴직미사용 연차 정산">
      <YouTube videoId='5RYQDj4eTVg' opts={opts} />
    </ModalWrapper>
  )
}
