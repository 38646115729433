import React from 'react'

export default function JudgeListItem4In({
  netAge,
  netAge15To18,
  ceoName,
  Label,
  numOfWorkers,
  isEtcWorker,
  clientReq4In,
  over52permission,
  week52OKComment,
  week52ViolationDashboardComment,
  restOKComment,
  restViolationDashboardComment,
  
  numOfTaxFamily,
  numOfTaxFamily7To20,
  isExceptTimeLawWorker,
  isDayWorker
}) {

  return (
  <>
    {!ceoName 
      ?
        <>
          직원수 : <Label floated='left'>기본 5인 설정(회사정보에서 수정하세요.)</Label>
        </>
      : 
        <>
          직원수 : <Label floated='left' content={numOfWorkers} />
        </>
    }
    <>
      {(isEtcWorker || isDayWorker) ? null  :
        <>
          {isExceptTimeLawWorker 
            ?
              <Label color='blue'>감단직은 주52시간 적용 제외</Label>
            :
              <>
                {(clientReq4In.lawBase?.judge52 || over52permission === "52특례사업장" )
                  ?
                  <Label floated='left' content={week52OKComment()} />
                  :
                  <Label color='red' floated='left' content={week52ViolationDashboardComment()} />
                }
              </>
          }
          {isExceptTimeLawWorker 
            ? 
              <Label color='blue'>감단직은 휴게규정 적용 제외</Label>
            :
              <>
                {(clientReq4In.lawBase?.restTotalViolation)
                  ?
                    clientReq4In.lawBase?.restViolation1 !== undefined ? <Label floated='left' content={restOKComment()} /> : null
                  :
                    clientReq4In.lawBase?.restViolation1 !== undefined ? <Label color='red' floated='left' content={restViolationDashboardComment()} /> : null
                }
              </>
          }
        </>
      }
    </>
    {!ceoName 
    ?
      <Label>최저임금 판단 및 부족액은 회사정보 알고리즘 필요(회사정보입력요망)</Label> 
    :
      <>
        {/* {minusMonthWage
          ?
          <Label floated='left' content={lowWageOKComment()} />
          :
          <Label color='red' floated='left' content={lowWageViolationDashboardComment()} />
        }
        {
          (minusMonthWage && viewLevel >= 10)
          ?
            <Label color='red'>2023년 최저임금 월 {numberToCommaString(minusMonthWage)} 원 부족 가능!!</Label>
          :
            null
        } */}
    </>
    }
    {numOfTaxFamily 
      ?
        <Label> <span style={{color : "blue"}}>{`세금 부양가족수(본인포함) : ${numOfTaxFamily}`}</span> </Label> 
      :
        null
    }
    {numOfTaxFamily7To20
      ?
        <Label> <span style={{color : "blue"}}>{`8세이상 20세이하 부양가족수 : ${numOfTaxFamily7To20}`}</span> </Label> 
      :
        null
    }
    {netAge15To18
      ?
        <Label> <span style={{color : "red"}}>{`만 ${netAge}세 연소근로자`}</span> </Label> 
      :
        null
    }
  </>
  )
}
