import React, { useState } from 'react';
import { Formik, Form } from 'formik';
import { Button, Checkbox, Label, Tab } from 'semantic-ui-react';

import ModalWrapper from '../../app/common/modals/ModalWrapper';
import MyTextInput from '../../app/common/form/MyTextInput';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { closeModal } from '../../app/common/modals/modalReducer';
import { registerInFirebase } from '../../app/firestore/firebaseService';
import { addUserContractAgreementToFirestore, 
  addUserPrivateAgreementToFirestore, 
  addWorkerUsersToFirestore, 
  updateWorkerUsersToFirestore } from '../../app/firestore/firestoreService4In';
import ClientUseContractPaperIntro from './ClientUseContractPaperIntro';
import ClientPrivateProtectPaperIntro from './ClientPrivateProtectPaperIntro';

export default function RegisterForm() {
  const dispatch = useDispatch();
  const [useContractAgree, setUseContractAgree] = useState(false);
  const [useContractAgreeOpen, setUseContractAgreeOpen] = useState(false);
  const [privateProtectAgree, setPrivateProtectAgree] = useState(false);
  const [privateProtectAgreeOpen, setPrivateProtectAgreeOpen] = useState(false);

  const handleUseContractAgree = () => {
    setUseContractAgree(true); // 이용약관 동의 상태 업데이트
    setUseContractAgreeOpen(false); // 이용약관 모달 닫기
  };

  const handlePrivateProtectAgree = () => {
    setPrivateProtectAgree(true); // 개인정보처리방침 동의 상태 업데이트
    setPrivateProtectAgreeOpen(false); // 개인정보처리방침 모달 닫기
  }

  // 이용약관 모달을 열고 닫는 함수들
  const openUseContractAgreeModal = () => setUseContractAgreeOpen(true);
  const openPrivateProtectAgreeModal = () => setPrivateProtectAgreeOpen(true);

  // FormAgreements 컴포넌트 정의
  const FormAgreements = () => {
    return (
      <>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <Checkbox 
            label='이용약관 동의' 
            disabled={!useContractAgree}
            checked={useContractAgree}
            onChange={(e, { checked }) => setUseContractAgree(checked)} 
          />
          <Button onClick={openUseContractAgreeModal} type='button'>이용약관</Button>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '1rem' }}>
          <Checkbox 
            label='개인정보처리방침 동의' 
            disabled={!privateProtectAgree}
            checked={privateProtectAgree}
            onChange={(e, { checked }) => setPrivateProtectAgree(checked)} 
          />
          <Button onClick={openPrivateProtectAgreeModal} type='button'>개인정보처리방침</Button>
        </div>
      </>
    );
  };

  function SubmitButton({ formikProps }) {
    return (
      <Button
        loading={formikProps.isSubmitting}
        disabled={!formikProps.isValid || !formikProps.dirty || formikProps.isSubmitting || !useContractAgree || !privateProtectAgree}
        type='submit'
        fluid
        size='large'
        content='회원가입'
      />
    );
  }

  // Tab panes 정의
  const panes = [
    // 사용자 탭
    {
      menuItem: '회사',
      render: () => (
        <Tab.Pane attached={false}>
          <p style={{ textAlign: 'center' }}>회사 회원가입</p>
          <Formik
            initialValues={{
              displayName: '',
              bizNumber: '',
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              displayName: Yup.string().required('회사명은 필수입니다.'),
              email: Yup.string().required('이메일은 필수입니다.').email('유효한 이메일 주소여야 합니다.'),
              password: Yup.string().required('비밀번호는 필수입니다.'),
              bizNumber: Yup.string().required('사업자등록번호는 필수입니다.'),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              
              const userInfo = {displayName: values?.displayName, email: values?.email, password: values?.password};
              
              try {
                await registerInFirebase(userInfo);
                await addUserPrivateAgreementToFirestore();
                await addUserContractAgreementToFirestore();
                setSubmitting(false);
                dispatch(closeModal());
              } catch (error) {
                setErrors({ auth: error.message });
                setSubmitting(false);
              }
            }}
          >
            {formikProps => (
              <Form className='ui form'>
                <MyTextInput name='bizNumber' placeholder='사업자등록번호' autoComplete='off' />
                <MyTextInput name='displayName' placeholder='회사상호' autoComplete='off' />
                <hr />
                <MyTextInput name='email' placeholder='이메일 주소' autoComplete='off'/>
                <MyTextInput name='password' placeholder='비밀번호' type="password" autoComplete='off'/>
                {formikProps.errors.auth && <Label basic color='red' style={{marginBottom: 10}} content={formikProps.errors.auth} />}
                <FormAgreements />
                <SubmitButton formikProps={formikProps} />
              </Form>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
    {
      menuItem: '직원',
      render: () => (
        <Tab.Pane attached={false}>
          <p style={{color:'blue', textAlign: 'center', marginBottom:'-3px'}}>회사, 직원코드가 없는 경우 가입 불가합니다.</p>
          <p style={{ textAlign: 'center' }}>(코드는 회사 인사팀 문의).</p>

          <Formik
            initialValues={{
              displayName: '',
              workerInputCompanyName: '',
              companyId: '',
              paydocuWorkerUid: '',
              email: '',
              password: '',
            }}
            validationSchema={Yup.object({
              displayName: Yup.string().required('직원이름은 필수입니다.'),
              workerInputCompanyName: Yup.string().required('회사이름은 필수입니다.'),
              companyId: Yup.string().required('회사코드').min(15, '회사에서 부여한 "회사코드"를 입력하세요.'),
              paydocuWorkerUid: Yup.string().required('직원코드').min(15, '회사에서 부여한 "직원코드"를 입력하세요.'),
              email: Yup.string().required('이메일은 필수입니다.').email('유효한 이메일 주소여야 합니다.'),
              password: Yup.string().required('비밀번호는 필수입니다.'),
            })}
            onSubmit={async (values, { setSubmitting, setErrors }) => {
              
              const userInfo = {displayName: values?.displayName, email: values?.email, password: values?.password};
              const workerInfo = {workerInputCompanyName: values?.workerInputCompanyName, companyId: values?.companyId, paydocuWorkerUid: values?.paydocuWorkerUid, displayName: values?.displayName, email: values?.email};
              
              try {
                await registerInFirebase(userInfo);
                await addUserContractAgreementToFirestore();
                await addUserPrivateAgreementToFirestore();
                await updateWorkerUsersToFirestore(workerInfo);
                await addWorkerUsersToFirestore(workerInfo);
                setSubmitting(false);
                dispatch(closeModal());
              } catch (error) {
                setErrors({ auth: error.message });
                setSubmitting(false);
              }
            }}
          >
            {formikProps => (
              <Form className='ui form'>
                <MyTextInput name='displayName' placeholder='직원개인실명' autoComplete='off' />
                <MyTextInput name='workerInputCompanyName' placeholder='회사상호' autoComplete='off' />
                <MyTextInput name='companyId' placeholder='회사코드' autoComplete='off' />
                <MyTextInput name='paydocuWorkerUid' placeholder='직원코드' autoComplete='off' />
                <hr />
                <MyTextInput name='email' placeholder='이메일 주소' autoComplete='off'/>
                <MyTextInput name='password' placeholder='비밀번호' type="password" autoComplete='off'/>
                {formikProps.errors.auth && <Label basic color='red' style={{marginBottom: 10}} content={formikProps.errors.auth} />}
                <FormAgreements />                
                <SubmitButton formikProps={formikProps} />
              </Form>
            )}
          </Formik>
        </Tab.Pane>
      ),
    },
  ];

  return (
    <ModalWrapper size='mini' header="AI NOMUSA 회원가입">
      <Tab menu={{ secondary: true, pointing: true }} panes={panes} />

        {/* 이용약관 동의 모달 */}
        <ClientUseContractPaperIntro
          isOpen={useContractAgreeOpen}
          onAgree={handleUseContractAgree}
          onClose={() => setUseContractAgreeOpen(false)}
        />
        {/* 개인정보처리방침 동의 모달 */}
        <ClientPrivateProtectPaperIntro
          isOpen={privateProtectAgreeOpen}
          onAgree={handlePrivateProtectAgree}
          onClose={() => setPrivateProtectAgreeOpen(false)}
        />

    </ModalWrapper>
  );
}