import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Container, Header, Segment, Button, Divider } from 'semantic-ui-react';
import { freeComClient } from '../../app/common/util/util';
import { dataFromSnapshotIdDate } from '../../app/firestore/firestoreService';
import { getWorkerUserProfile } from '../../app/firestore/firestoreService4In';
import { listenToWorkerUsers } from '../profiles/profileActions';
import logo from '../../app/layout/logo.png';

export default function HomePage({ history }) {
	const dispatch = useDispatch();
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientInfo } = useSelector((state) => state.companyReducer);
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;

	const isWorker = currentUserProfile?.isWorker;
	const agentUid = currentUserProfile?.agentUid;
	const agentName = currentUserProfile?.agentName;
	const agentWorkerPhone = currentUserProfile?.agentWorkerPhone;
	const psNomusaCompanyName = currentUserProfile?.psNomusaCompanyName;
	const agentWorkerName = currentUserProfile?.agentWorkerName;

	useEffect(() => {
		if (currentUserProfile) {
			let isMounted = true; // 컴포넌트 마운트 상태를 추적하는 변수
			const unSubscribe = getWorkerUserProfile({
				next: (snapshot) => {
					if (isMounted) {
						// 컴포넌트가 마운트된 상태일 때만 dispatch 실행
						dispatch(
							listenToWorkerUsers(
								snapshot.docs.map((docSnapshot) =>
									dataFromSnapshotIdDate(docSnapshot)
								)
							)
						);
					}
				},
			});
			return () => {
				unSubscribe();
				isMounted = false; // 컴포넌트가 언마운트될 때 isMounted를 false로 설정
			};
		}
	}, [dispatch, currentUserProfile]);

	const sponsor =
		currentUserProfile?.agentName && currentUserProfile?.isAgent === '스폰서'
			? currentUserProfile?.agentName
			: psNomusaCompanyName
			? psNomusaCompanyName
			: null;

	return (
		<Segment inverted textAlign='center' vertical className='masthead'>
			<Container>
				<div className='logo-container'>
					<img src={logo} alt='Logo' className='logo-top-left' />
				</div>

				{isWorker ? (
					<>
						{currentUserProfile?.agentName &&
							currentUserProfile?.agentUid &&
							currentUserProfile?.agentType === '제약영업' && (
								<Header
									as='h2'
									inverted
									style={{ marginTop: '230px', marginBottom: '-200px' }}>
									{currentUserProfile?.agentName}의
								</Header>
							)}
					</>
				) : (
					<>
						{sponsor && (
							<Header
								as='h2'
								className={`h2responsive-text`}
								inverted
								style={{
									marginTop: '230px',
									marginBottom: '-200px',
									color: 'white',
									textShadow: '4px 4px 16px #000000',
								}}>
								{sponsor}의 파트너
							</Header>
						)}
					</>
				)}
				<Header
					as='h1'
					className={`h1responsive-text`}
					inverted
					style={{ marginTop: '200px', textShadow: '4px 4px 16px #000000' }}>
					AI NOMUSA
				</Header>
				<Header
					as='h2'
					className={`h2responsive-text`}
					inverted
					style={{ color: 'white', textShadow: '4px 4px 16px #000000' }}>
					세상에 없던 노무관리 시스템
				</Header>
				<Divider className='gray-divider' />
				{freeComClient(authLevel, ceoName) && !isWorker ? (
					<Button
						onClick={() => history.push('/pricePolicyPaper')}
						size='massive'
						inverted>
						시작하기
					</Button>
				) : (
					<Button
						onClick={() => history.push('/clientReqs4In')}
						size='massive'
						inverted
						className='custom-large-button' // 커스텀 클래스 추가
					>
						시작하기
					</Button>
				)}
				<br />
				<br />
				<br />
				<br />
				{agentName && agentUid && (
					<>
						<div
							className={`divresponsive-text`}
							style={{
								textAlign: 'center',
								fontSize: '1.5rem',
								color: 'yellow',
								margin: '10px 0',
								padding: '5px',
							}}>
							<span>
								Sponsored By {agentName} - 담당 : {agentWorkerName}, 상담 문의 :{' '}
								{agentWorkerPhone}
							</span>
						</div>
					</>
				)}
				<div
					className={'div2responsive-text'}
					style={{
						textAlign: 'center',
						fontSize: '2rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					노무법인 최상인업 ||
					<Link
						to='/clientUseContractPaper'
						style={{ color: 'inherit', textDecoration: 'inherit' }}>
						{' '}
						이용약관{' '}
					</Link>
					||
					<Link
						to='/clientPrivateProtectPaper'
						style={{ color: 'inherit', textDecoration: 'inherit' }}>
						{' '}
						개인정보처리방침
					</Link>
				</div>
				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					세종특별시 갈매로 351 에비뉴힐 B 4115호
				</div>
				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					사업자번호 : 413-85-02763 통신판매업신고증 : 제2021-세종아름-0301호
				</div>

				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					대표자명 : 김경모 대표번호 : 044-868-9359 팩스번호 : 02-6442-9329
					이메일 : ainomusaforyou@naver.com
				</div>
				<div
					className={`divresponsive-text`}
					style={{
						textAlign: 'center',
						fontSize: '1.5rem',
						margin: '10px 0',
						padding: '5px',
					}}>
					(C) TheBestInup ALL RIGHTS RESERVED
				</div>
				<hr />
				<div
					className={`divresponsive-text`}
					style={{ textAlign: 'center', color: '#FFD700', fontSize: '1.5rem' }}>
					AI NOMUSA는 공인노무사법 제2조 제1항 제2호에 따라 노동 관계 법령에
					따른 서류
					<br />
					(근로계약서. 임금명세서 등)의 작성과 공인노무사의 확인을 수행하는 국내
					유일 프로그램입니다.
				</div>
			</Container>
		</Segment>
	);
}
