import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function WorkerRegStep4() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI NOMUSA 퇴직/사직/해고 설정">
      <YouTube videoId='6rR4_H_D4qI' opts={opts} />
    </ModalWrapper>
  )
}
