import React from 'react';
import { Modal, Button } from 'semantic-ui-react';
import ClientUseContractPaperContents from './ClientUseContractPaperContents';

export default function ClientUseContractPaperIntro({ isOpen, onAgree, onClose }) {
  return (
    <Modal open={isOpen} onClose={onClose} size="small">
      <Modal.Header>AI NOMUSA 이용약관</Modal.Header>
        <Modal.Content>
          <ClientUseContractPaperContents />
        </Modal.Content>
      <Modal.Actions>
        <Button color='green' onClick={onAgree}>동의합니다</Button>
        <Button onClick={onClose}>닫기</Button>
      </Modal.Actions>
    </Modal>
  )
}