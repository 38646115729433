import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function PayPaperAddTime() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI NOMUSA 임금명세서 연장, 야간, 휴일 설정">
      <YouTube videoId='uT9nHxoVluw' opts={opts} />
    </ModalWrapper>
  )
}
