import React, { useEffect, useState } from 'react';
import { Dropdown, Menu } from 'semantic-ui-react';
import { GrUserWorker } from 'react-icons/gr';
import { useHistory } from 'react-router-dom';

export default function CompanyContractMenu({ profile }) {
	const history = useHistory();

	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	function goToCMSContractContents() {
		history.push(`/cmsProgramContract/${profile.id}`);
	}

	function goToProgramUseContract() {
		history.push(`/programUseContract/${profile.id}`);
	}

	return (
		<>
			<Menu.Item
				className='menu-item'
				onMouseOver={handleMouseOver}
				onMouseLeave={handleMouseLeave}>
				{!isMobile && <GrUserWorker className='icon-spacing' />}
				<Dropdown
					pointing='top left'
					text='이용 계약서'
					open={dropdownOpen}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item
							onClick={() => goToProgramUseContract()}
							text='서비스 이용 계약서'
						/>
						<Dropdown.Item
							onClick={() => goToCMSContractContents()}
							text='CMS 자동이체 신청서'
						/>
					</Dropdown.Menu>
				</Dropdown>
			</Menu.Item>
		</>
	);
}
