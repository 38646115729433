import React from 'react'

export default function JudgeDayWorkerListItem4In({
  Label,
  clientReq4In,
  lowWageOKComment,
  lowWageViolationDashboardComment,
  viewLevel,
  numberToCommaString,
  roundUp,
  restOKComment,
  restViolationDashboardComment,
}) {
  return (
    <>
      {/* {clientReq4In?.dayWorker?.insufficientDayWage <= 0
        ?
        <Label floated='left' content={lowWageOKComment()} />
        :
        <Label color='red' floated='left' content={lowWageViolationDashboardComment()} />
      }
      {(clientReq4In?.dayWorker?.insufficientDayWage > 0 && viewLevel >= 10)
        ?
          <Label color='red'> 매일 {numberToCommaString(roundUp(clientReq4In?.dayWorker?.insufficientDayWage))} 원 부족 가능!!</Label>
        :
          null
      } */}
      {clientReq4In.lawBase?.restViolationDay
        ?
          clientReq4In.lawBase?.restViolationDay !== undefined ? <Label floated='left' content={restOKComment()} /> : null
        :
          clientReq4In.lawBase?.restViolationDay !== undefined ? <Label color='red' floated='left' content={restViolationDashboardComment()} /> : null
      }
    </>
  )
}
