import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from 'semantic-ui-react';
import useFirestoreDoc from '../../../app/hooks/useFirestoreDoc';
import LoadingComponent from '../../../app/layout/LoadingComponent';
import { listenToSelectedWorkerUser } from '../profileActions';
import { getWorkerUserProfile } from '../../../app/firestore/firestoreService4In';

export default function WorkerUserPage({ match }) {
	const dispatch = useDispatch();
	const { selectedWorkerUser, currentUserProfile } = useSelector(
		(state) => state.profile
	);
	const { currentUser } = useSelector((state) => state.auth);
	const { loading, error } = useSelector((state) => state.async);
	let profile;

	useFirestoreDoc({
		query: () => getWorkerUserProfile(match.params.id),
		data: (profile) => dispatch(listenToSelectedWorkerUser(profile)),
		deps: [dispatch, match.params.id],
		// shouldExcute: match.params.id !== currentUser.uid
	});

	if (match.params.id === currentUser.uid) {
		profile = currentUserProfile;
	} else {
		profile = selectedWorkerUser;
	}

	if ((loading && !profile) || (!profile && !error))
		return <LoadingComponent content='Loading profile...' />;
	return (
		<Grid>
			<Grid.Column width={16}>
				<hi>{selectedWorkerUser?.workerUid}</hi>
			</Grid.Column>
		</Grid>
	);
}
