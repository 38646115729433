import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe5CouncilDutyRules({
	serviceStartDate,
	// profile,
	// authLevel,
	company,
	companyUn,
	companyGa,
	allOpen,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	previousSectionCount4,
	setSectionCount,
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `협의 사항`,
				content: [
					`협의회는 다음 각 호의 사항을 협의한다.`,
					[
						`생산성 향상과 성과 배분`,
						`근로자의 채용ㆍ배치 및 교육훈련`,
						`근로자의 고충처리`,
						`안전, 보건, 그 밖의 작업환경 개선과 근로자의 건강증진`,
						`인사ㆍ노무관리의 제도 개선`,
						`경영상 또는 기술상의 사정으로 인한 인력의 배치전환ㆍ재훈련ㆍ해고 등 고용조정의 일반원칙`,
						`작업과 휴게 시간의 운용`,
						`임금의 지불방법ㆍ체계ㆍ구조 등의 제도 개선`,
						`신기계ㆍ기술의 도입 또는 작업 공정의 개선`,
						`작업 수칙의 제정 또는 개정`,
						`종업원지주제(從業員持株制)와 그 밖에 근로자의 재산형성에 관한 지원`,
						`직무 발명 등과 관련하여 해당 근로자에 대한 보상에 관한 사항`,
						`근로자의 복지증진`,
						`사업장 내 근로자 감시 설비의 설치`,
						`여성근로자의 모성보호 및 일과 가정생활의 양립을 지원하기 위한 사항`,
						`직장 내 성희롱 및 고객 등에 의한 성희롱 예방에 관한 사항`,
						`그 밖의 노사협조에 관한 사항`,
					],
					`협희회는 제1항 각 호의 사항에 대하여 제22조의 정족수에 따라 의결할 수 있다.`,
					`${company} 상정된 의안 중 전문적인 내용이나 계속 보완시킬 필요가 있는 사항은 전문기관 또는 해당 전문가에게 위탁할 수 있으며 필요한 경비는 ${company}에서 지급한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `의결 사항`,
				content: [
					`${companyUn} 다음 각 호의 1에 해당하는 사항에 대하여는 협의회의 의결을 거쳐야 한다.`,
					[
						`근로자의 교육훈련 및 능력개발 기본계획의 수립`,
						`복지 시설의 설치와 관리`,
						`사내 복지기금의 설치`,
						`고충처리위원회에서 해결되지 아니한 사항`,
						`각종 노사 공동위원회의 설치`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `보고 사항`,
				content: [
					`${companyUn} 정기회의에 다음 각 호의 1에 해당하는 사항에 관하여 성실하게 보고하여야 한다.`,
					[
						`경영계획 전반 및 실적에 관한 사항`,
						`분기별 업무계획과 실적에 관한 사항`,
						`인력계획에 관한 사항`,
						`기업의 경제적∙재정적 상황`,
						`안전보건에 관한 사항`,
					],
					`근로자위원은 사원의 요구사항을 보고 설명할 수 있다.`,
					`${companyGa} 제1항의 규정에 의한 보고∙설명을 이행하지 아니하는 경우에는 근로자위원은 제1항 각 호에 관한 자료의 제출을 요구할 수 있으며 ${companyUn} 이에 성실히 응하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `의결된 사항의 공지 및 이행`,
				content: [
					`협의회는 의결된 사항을 신속히 근로자에게 공지시켜야 한다.`,
					`사원과 ${companyUn} 협의회에서 의결된 사항을 문서로 작성하여 보관하고 성실하게 이행하여야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `임의중재`,
				content: [
					`협의회는 다음 각 호의 1에 해당하는 경우에는 근로자위원 및 사용자위원의 합의에 의하여 협의회에 중재기구를 두어 해결하거나 노동위원회 기타 제3자에 의한 중재를 받을 수 있다.`,
					[
						`제23조에 규정된 사항에 관하여 협의회가 의결하지 못한 경우`,
						`협의회에서 의결된 사항의 해석 또는 이행방법 등에 관하여 의견의 불일치가 있는 경우`,
						`그 밖에 중재가 필요한 경우`,
					],
					`제1항의 규정에 의한 중재결정이 있는 때에는 협의회의 의결을 거친 것으로 보며 근로자와 ${companyUn} 이에 따라야 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections?.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [company, companyUn, companyGa]);

	useEffect(() => {
		setSectionCount(sections.length);
	}, [sections, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제5장 협의회의 의무</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3 +
							previousSectionCount4
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
