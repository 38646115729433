// import { format } from 'date-fns';
import React from 'react';
import { format } from 'date-fns/esm';
import * as moment from 'moment';
import { Button, Grid, Tab, Table } from 'semantic-ui-react'
// import { Link } from 'react-router-dom';
import { printSupportFundContract, printSupportFundWarrant } from '../../../app/common/util/util';
// import { Link } from 'react-router-dom';
import SupportFundWarrantContents from './SupportFundWarrantContents';
// import CmsContractContents from './CmsContractContents';
import { addSupportFundContractPrintToFirestore } from '../../../app/firestore/firestoreService4In';

export default function SupportFundContractContents({profile}) {
  let today = new Date();
  const nomusaCompanyName = profile?.agentType === 'bs노무사' ? profile?.agentName : '노무법인 최상인업 세종지점';
  const nomusaName = profile?.agentType === 'bs노무사' ? profile?.agentWorkerName : '이일선 노무사';
  const nomusaPhone = profile?.agentType === 'bs노무사' ? profile?.agentWorkerPhone : '044-868-9359';


  // //무료사용일 1년
  // function freeUseLimitDate () {
  //   const freeLimitDate = moment()
  //   return moment(moment().set({ 'year': freeLimitDate.get('year')+1, 'month': freeLimitDate.get('month'), 'date': freeLimitDate.get('date')-1 })).format('YYYY-MM-DD');
  // } 

  //계약종료일 2년
  function constactLimitDate () {
    const freeLimitDate = moment()
    return moment(moment().set({ 'year': freeLimitDate.get('year')+2, 'month': freeLimitDate.get('month'), 'date': freeLimitDate.get('date')-1 })).format('YYYY-MM-DD');
  } 

  // 직인관련 스타일
  // const styles = {
  //   position: 'absolute',
  //   // top: 0,
  //   bottom: "-150%",    // computed based on child and parent's height
  //   right: 0,   // computed based on child and parent's width
  // };

  async function supportFundContractPrintF () {
    printSupportFundContract()

    await addSupportFundContractPrintToFirestore();
  };

  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      <Tab.Pane>
        <p style={{color:'blue'}}>* 중요안내 : 고용보험법상 고용안정지원금 대행 및 대리는 공인노무사의 고유영역으로 본 고용안정지원금 계약은 본 시스템 AI NOMUSA 운영사인 당사(노무법인 최상인업)와 계약하는 경우를 제외하고 아래 노무사(법인)과의 계약임을 안내드리며, 고용안정지원금 관련 업무는 아래 노무사(법인)에게 문의하시기 바랍니다.</p>
        <br />
        <Grid>
          <>
            <Button 
              color='blue' 
              style={{display: "inline", color: 'black', margin: '5px',}}
              onClick={() => supportFundContractPrintF()}
              >지원금 계약서 출력
            </Button>
            <Button 
              color='blue' 
              style={{display: "inline", color: 'black', margin: '5px',}}
              onClick={() => printSupportFundWarrant()}
            >위임장 출력
            </Button>
            {/* <Button 
              color='blue' 
              style={{display: "inline", color: 'black', margin: '5px',}}
              onClick={() => printCmsContract()}
            >CMS 자동이체 신청서 출력
            </Button> */}
            {/* <hr/>
            <Button color='green' as={Link} to=/clientUseContractPaper style={{display: "inline", color: 'black', margin: '5px'}}>이용약관</Button>
            <Button color='green' style={{display: "inline", color: 'black', margin: '5px'}} as={Link} to=/clientPrivateProtectPaper>개인정보처리방침</Button>
            <Button floated='right' color='green' as={Link} to='/pricePolicyPaper' style={{display: "right", color: 'black', margin: '5px', float: 'right' }}>기능 및 가격</Button>  */}
            <Button  disabled style={{display: "inline", color: 'black', margin: '5px',}}>{nomusaCompanyName}({nomusaPhone})으로 연락주세요.</Button>
            <div id='supportFundContract'>
              <Table width ="100%" align = "center">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell className='titlee' align = "center"><h1 style={{textAlign : 'center', margin : '30px', fontSize : '30px'}}> {nomusaCompanyName} 고용안정지원금 컨설팅 계약서 </h1></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              {/* <table width ="100%" align = "center">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell><h1 style={{textAlign : 'left', margin : '10px', fontSize : '15px'}}>고용안정지원금 및 장려금[2021년 특별고용촉진장려금, 청년채용특별장려금, 출산육아기고용안정지원금, 워라벨일자리장려금, 기타 계획신고서가 없는 지원금 및 장려금]</h1></Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table> */}
              <br/>
              <h3>[의뢰인]</h3>
              <Table style={{width : "100%", height : "150", textAlign : "center"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width ="20%">회사명 </Table.Cell>
                    <Table.Cell width ="20%">대표자 성명</Table.Cell>
                    <Table.Cell width ="20%">담당자 성명</Table.Cell>
                    <Table.Cell width ="20%">담당자 연락처</Table.Cell>
                    <Table.Cell width ="20%">담당자 이메일</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="20%">{profile?.companyName}</Table.Cell>
                    <Table.Cell width ="20%">{profile?.ceoName}</Table.Cell>
                    <Table.Cell width ="20%">{profile?.staffName }</Table.Cell>
                    <Table.Cell width ="20%">{profile?.companyPhone }</Table.Cell>
                    <Table.Cell width ="20%">{profile?.staffEmail }</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </Table>
              <br />
              <h3>[수임인]</h3>
              <table style={{width : "100%", height : "150", textAlign : "center"}}>
                <Table.Body>
                  <Table.Row>
                    <Table.Cell width ="20%">회사명 </Table.Cell>
                    <Table.Cell width ="20%">담당노무사명</Table.Cell>
                    <Table.Cell width ="20%">담당자 연락처</Table.Cell>
                  </Table.Row>
                  <Table.Row>
                    <Table.Cell width ="20%">{nomusaCompanyName}</Table.Cell>
                    <Table.Cell width ="20%">{nomusaName}</Table.Cell>
                    <Table.Cell width ="20%">{nomusaPhone}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <div>
                  상기 의뢰인(이하 "본인")과 상기 수임인(이하 "귀하")은 상기 고용안정지원금 및 장려금 컨설팅(이하 "위임 분야")의 업무를 원할히 추진하기 위해 서로 다음 사항에 대해 준수할 것을 서약합니다. 
              </div>
              <br />
              <div  style={{width : "100%", height : "150", textAlign : "center"}}><strong>- 다    음 -</strong>
              </div>
              <h4>제1조 [계약기간]</h4>
              <div >
                  ① 고용안정지원금 컨설팅의 계약기간은 계약체결일로부터 {constactLimitDate()}까지로 하되 계약기간 내에 귀하가 고용안정지원금 및 장려금 업무를 진행한 경우 본인이 동 금원을 수령하면 그 금원에 대한 보수 지급일까지는 계약기간이 연장된다. 
              </div>
              <br />
              <div >
                  ② 제1항에 따른 계약기간 내에 본 고용안정지원금 계약을 종료하기 위해서는 본인은 종료 예정일 30일전까지 귀하에게 통보할 의무가 있고, 본인이 제1항에 따른 계약기간 종료일 1개월 전까지 종료통보를 하지 아니한 경우는
                  매 2년 단위로 계약기간은 갱신되어 본 계약은 유지된다.
              </div>
              <br />
              <br />
              <h4>제2조 [공인노무사법에 의한 권한의 수여]</h4>
              <div >위 위임분야를 처리하기 위해 본인의 결산서 등 재무 관련 및 근로계약서, 취업규칙 등 인사 노무, 직원명부 및 4대보험 관련 자료 등 사무관리 서류 및 관련 일체의 제도에 대한 접근 권한을 위임분야 관련 사무가 종료될때까지 공인노무사법 제2조에 의거 귀하에게 수여하며, 
                귀하는 적법한 대리인으로 공인노무사법 및 민법 및 상법 등 관련 법령에 의거 본인의 이익을 위해 선량한 관리자의 주의로서 위임사무를 처리한다.</div>
              <br />
              <br />
              <br />
              <h4>제3조 [책임 및 면책]</h4>
              <div >
                  ① 본인은 귀하에게 위임한 컨설팅 업무를 수행하는데 필요한 위임사무 수행 시 
                  귀하가 필요하다고 인정하여 귀하가 제출을 요구한 서류 및 자료나 조회한 사항에 대해서 거짓없이 신속히 이에 응하여 제출하거나 답할 책임이 있다.
              </div>
              <br />
              <div >
                  ② 본인은 귀하에게 위임 업무를 수행하는데 귀하가 필요하다고 인정하여 귀하가 제출을 요구한 서류 및 자료나 조회한 사항에 대해서 거짓없이 신속히 이에 응하여 제출하거나 답할 책임이 있고, 귀하는 본인이 제시한 자료 등의 범위 내에서만 책임을 진다. 
              </div>
              <br />
              <br />
              <div >
                  ③ 귀하는 위임업무 진행을 위해 공인노무사법상의 공인노무사 자격 및 직무능력을 유지해야 하고, 위임업무 진행 중 또는 만료 후라도 위임 업무와 관련된 비밀을 정당한 이유 없이 타인에게 누설하지 않아야 한다.
              </div>
              <br />
              <div >
                ④ 본 계약은 귀하의 지식 및 노하우로 <span style={{fontSize: '1.1rem', color:'blue'}}>귀하가 위임업무을 진행한 고용안정지원금 및 장려금에 대해 본인이 그 금원을 수령한 경우에 한해 본인은 제4조의 컨설팅 비용을 귀하에게 지급할 책임을 부담하는 계약</span>으로 
                <span style={{fontSize: '1.1rem', color:'blue'}}>귀하는 약정한 고용안정지원금 및 장려금을 반드시 진행할 책임을 부담하는 계약이 아님</span>을 당사자는 서로 분명히 한다.
              </div>
              <br />
              <br />
              <h4>제4조 [고용안정지원금 대행 또는 대리 비용]</h4>
              <div >
                  고용안정지원금 및 장려금 성공보수 : 본인은 귀하가 위에서 수권받은 컨설팅을 통해 본인에게 고용보험법상 지원금 및 장려금 등이 입금되면 <strong style={{color: 'blue'}}>입금된 전체 금액의 20%(부가세 별도)</strong>을 아래 계좌로 입금한다.
              </div>
              <br />
              <div  >
              <strong style={{color: 'blue'}}>* 계좌번호 : 317-5674-7750-11 노무법인 최상인업 세종지점 </strong>
              </div>
              <h4>제5조 [고용안정지원금 및 장려금 위임사무의 완료로 보는 경우]</h4>
              <div >
                  의뢰인은 다음 각 호의 경우 위임사무는 성공한 것으로 간주하고, 본 약정에서 정한 보수금의 전부 또는 일부를 지급한다. 단, 귀하는 보수의 지급기준을 수임업무의 노력의 정도, 사무처리의 경과, 정도 및 난이도 등을 고려하고, 의뢰인 본인의 과실여부 및 과실정도에 따라 지급액을 정하여 청구할 수 있다.
              </div>
              <br />
              <br />
              <div >
                  1. 의뢰인 본인이 귀하에 대하여 본 약정에서 정한 의무를 이행치 않거나, 진술한 사실이 허위인 까닭에 귀하가 본 약정을 해제한 때.
              </div>
              <br />
              <div >
                  2. 위임사무 착수 후 의뢰인 본인이 귀하의 동의없이 임의로 본 약정을 해지한 때.
              </div>
              <br />
              <div >
                  3. 의뢰인 본인의 고의 또는 과실로 귀하에게 책임 없는 사유로 위임이 종결된 때.
              </div>
              <h4>제6조 [자료(도장 포함) 제공 및 보관책임]</h4>
              <div >
                  의뢰인 본인은 위임사무 수행 시 필요하다고 인정하여 귀하가 제출을 요구한 서류 및 자료나 조회한 사항에 대해서 신속히 이에 응하여 제출하거나 회답하며, 귀하는 다음과 같이 제공한 자료의 보관책임을 가진다.              
              </div>
              <br />
              <div >
                  1. 의뢰인 본인은 귀하가 위임사무를 처리하는 데 필요한 자료를 적극 제공하며, 귀하는 본인이 제시한 자료의 범위 내에서 책임진다.
              </div>
              <br />
              <div >
                  2. 의뢰인 본인이 제작 의뢰하거나 본인이 귀하에게 제공한 도장은 귀하가 본 위임분야 계약의 위임 사무만을 위해 사용해야 하며, 만약 귀하가 본 위임사무 외에 도장을 사용함으로 인해 본인에게 생긴 민형사상 책임은 귀하가 부담한다.
              </div>
              <br />
              <div >
                  3. 위임사무를 처리하는데 필요하다고 인정하여 의뢰인 본인이 제공한 자료는 본 약정에 정한 의무를 이행치 않을 경우 귀하가 이를 유치하여도 본인은 이의하지 아니한다. 단, 도장은 반환하거나 폐기하여야 한다.
              </div>
              <br />
              <div >
                  4. 의뢰인 본인의 의사를 확인하여 전항의 자료를 위임사무가 종료되거나 본 약정이 만료된 때로부터 공인노무사법에 의거 3년이 경과한 때에는 귀하가 임의로 폐기하여도 이의가 없다.
              </div>
              <br />
              <div >
                  5. 의뢰인 본인의 청구에 의하여 위 서류와 자료를 본인이 지정한 자에게 교부된 후나 우편송부 후 분실 또는 훼손되었을 때에도 이의가 없다.
              </div>
              <br />
              <h4>제7조 [위임분야 사무의 종료]</h4>
              <div >
                  ① 본인이 임의로 본 고용안정지원금 및 장려금 위임사무를 해지하거나, 본 의뢰에서 정한 의무를 이행치 않을 때에는 기한이익을 상실한다. 또한 이 때에는 기 지불된 보수 등에 대하여 반환을 청구할 수 없으며 이는 위임사무 처리비용으로 변제된 것으로 본다.
              </div>
              <br />
              <br />
              <h4>제8조 [특약 사항]</h4>
              <div >
                  ① 본인의 위임사무를 귀하가 원활하기 수행하기 위하여 귀하의 유관회사 또는 제휴사(세무사, 회계사, 변호사, 변리사, 법무사 등)를 통하여 위임사무를 수행할 수 있다. 단, 해당 업무수행에 적법한 권한이 있는 유관회사 및 제휴사에 한정한다.
              </div>
              <br />
              <div >
                  ② 본인은 본 계약의 위임사무 수행 결과 등의 정보를 공공기관 제출자료 및 귀하의 거래현황 홍보를 위한 자료로 활용하는데 동의한다.
              </div>
              <br />
              <div >
                  ③ 본인이 의뢰한 고용안정지원금 및 장려금은 2021년 특별고용촉진장려금, 청년채용특별장려금, 출산육아기고용안정지원금, 워라벨일자리장려금임을 확인하며, 다른 지원금 및 장려금은 별도의 컨설팅으로 진행됨을 확인한다.
              </div>
              <br />
              <h4>제9조 [계약의 해석 및 재판 관할]</h4>
              <div >
                
                  ① 본 계약에 내용이 없는 경우 AI NOMUSA이용약관에 따르고, 본 계약과  AI NOMUSA이용약관 내용이 불일치하는 경우는 본 계약이 우선한다.  
                
              </div>
              <br/>
              <div>
                
                  ② 본인과 귀하 간 제기된 소송은 대한민국법을 준거법으로 하고, 민사소송법상 귀하의 본사소재지를 관할하는 법원을 관할법원으로 합의한다.
                
              </div>
              <br />
              <table width ="100%" align = "center">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell align = "center">{format(today, 'yyyy-MM-dd')}</Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              <br />
              <br />
              <table width ="100%" align = "center">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell align = "center" width='50%'>의뢰인 : ${profile?.companyName} ${profile.ceoName} (서명 또는 인)</Table.Cell>
                    <Table.Cell style={{position : 'relative'}} className='text' align = "center" width='50%'>
                       수임인 : ${nomusaCompanyName} (서명 또는 인)
                      {/* 직인
                      <img style={styles} src="/노무법인 인업 세종본부 네모직인.png" alt="노무법인 인업 세종본부도장" /> */}
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table>
              {/* 직인과 관련있어서 때문에 주석처리함.
              <table width ="100%" align = "center">
                <Table.Body>
                  <Table.Row>
                    <Table.Cell align = "center" width='50%'> </Table.Cell>
                    <Table.Cell align = "left" width='50%'>
                    </Table.Cell>
                  </Table.Row>
                </Table.Body>
              </table> */}
              </div>
            </>
        
          {/* <CmsContractContents 
            profile={profile}
            nomusaCompanyName={nomusaCompanyName}
            nomusaName={nomusaName}
            nomusaPhone={nomusaPhone}
          /> */}
        </Grid>
        <br />
        <hr />
        <br />
        <Grid>
          <SupportFundWarrantContents 
            profile={profile}
            nomusaCompanyName={nomusaCompanyName}
            nomusaName={nomusaName}
            nomusaPhone={nomusaPhone}
          />
        </Grid>
      </Tab.Pane>
    </div>
  );
};


