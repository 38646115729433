import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Input, Loader } from 'semantic-ui-react';
import { fetchCompaniesFormFirestoreService4In } from '../companyActions';
import CompanyList from './CompanyList';
import PaydocuShortCut from '../paydocuDashboard/PaydocuShortCut';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
} from '../../clientsRequest/clientReq4In/clientReqActions4In';

export default function CompanyDashboard() {
	const dispatch = useDispatch();
	const { companies, filter, clientInfo } = useSelector(
		(state) => state.companyReducer
	);
	const { loading } = useSelector((state) => state.async);
	const { authenticated } = useSelector((state) => state.auth);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { clientReqs4In } = useSelector((state) => state.clientReq4InStore);

	const [searchTerm, setSearchTerm] = useState('');
	const [searchResults, setSearchResults] = useState([]);
	const handleChange = (event) => {
		setSearchTerm(event.target.value);
	};

	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;

	useEffect(() => {
		const fetchCompanies = async () => {
			try {
				await dispatch(fetchCompaniesFormFirestoreService4In());
			} catch (error) {
				console.error('Error fetching companies:', error);
			}
		};

		fetchCompanies();
	}, [dispatch]);

	useEffect(() => {
		const fetchClientReqs = async () => {
			try {
				await dispatch(fetchClientReqs4In(filter));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchClientReqs();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	const isWorker = currentUserProfile?.isWorker;

	return (
		<>
			{isWorker ? null : (
				<PaydocuShortCut filter={filter} clientReqs4In={clientReqs4In} />
			)}
			{authLevel >= 100 ? '회사명, 이메일, 회원가입일, 영업자' : '직원성명'}
			:{' '}
			<Input
				type='text'
				placeholder='Search'
				value={searchTerm}
				onChange={handleChange}
			/>
			<Grid>
				<Grid.Column width={16}>
					<>
						<hr />
						{authenticated && (
							<CompanyList
								companies={companies}
								searchResults={searchResults}
								setSearchResults={setSearchResults}
								searchTerm={searchTerm}
							/>
						)}
					</>
				</Grid.Column>
				<Grid.Column width={16}>
					<Loader active={loading} />
				</Grid.Column>
			</Grid>
		</>
	);
}
