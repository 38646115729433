import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Table, Header } from 'semantic-ui-react';
import PaydocuShortCut from '../../../companyManage/paydocuDashboard/PaydocuShortCut';
import {
	clearClientReqs4In,
	fetchClientReqs4In,
	fetchWorkerUsers,
} from '../clientReqActions4In';
import {
	checkRetirementFundCompliance,
	numberToCommaString,
} from '../../../../app/common/util/util';
import moment from 'moment';

const SelfCheckLaborLaw = () => {
	const dispatch = useDispatch();
	const { filter, clientInfo } = useSelector((state) => state.companyReducer);
	const { currentUserProfile } = useSelector((state) => state.profile);
	const { workerUsers, clientReqs4In } = useSelector(
		(state) => state.clientReq4InStore
	);

	const [checkList, setCheckList] = useState([
		{
			id: 1,
			description:
				'근로조건 명시: 근로계약 시 임금의 구성항목, 지급방법, 근로시간, 휴가 등에 관한 사항을 서면으로 명시하여 교부해야 한다. (근로기준법 제17조)',
			penalty: '500만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{ key: 'partial', text: '일부 항목 미명시', value: '일부 항목 미명시' },
				{
					key: 'not-specified',
					text: '근로조건 미명시',
					value: '근로조건 미명시',
				},
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 2,
			description:
				'금품 청산: 근로자가 퇴직한 경우 14일 이내에 임금 등 금품을 지급해야 한다. (근로기준법 제36조)',
			penalty: '3년 이하 징역 또는 3천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{
					key: 'not-paid',
					text: '14일 이내 미지급',
					value: '14일 이내 미지급',
				},
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 3,
			description:
				'임금 지급: 매월 1회 이상 일정한 날짜에 임금 전액을 지급해야 한다. (근로기준법 제43조)',
			penalty: '3년 이하 징역 또는 3천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{ key: 'not-compliant', text: '지급일 미준수', value: '지급일 미준수' },
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 4,
			description:
				'임금명세서: 임금을 지급할 때 구성 항목, 계산방법, 공제 내역 등을 포함한 명세서를 서면으로 교부해야 한다. (근로기준법 제48조)',
			penalty: '500만 원 이하 과태료',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{
					key: 'partial-miss',
					text: '일부 서면 교부사항 누락',
					value: '일부 서면 교부사항 누락',
				},
				{
					key: 'no-issue',
					text: '임금명세서 미교부',
					value: '임금명세서 미교부',
				},
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 5,
			description:
				'근로시간: 1주간의 근로시간은 40시간, 1일 8시간을 초과하지 않는다. (근로기준법 제50조)',
			penalty: '2년 이하 징역 또는 2천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{ key: 'exceed-time', text: '근로시간 초과', value: '근로시간 초과' },
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 6,
			description:
				'휴일: 1주 1회 이상 유급휴일을 부여해야 한다. (근로기준법 제55조)',
			penalty: '2년 이하 징역 또는 2천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{
					key: 'no-holiday',
					text: '유급휴일 미부여',
					value: '유급휴일 미부여',
				},
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 7,
			description:
				'연장/휴일/야간 근로: 연장, 휴일, 야간근로 시 통상임금의 50% 이상 가산 지급. (근로기준법 제56조)',
			penalty: '3년 이하 징역 또는 3천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{
					key: 'less-pay',
					text: '50% 미만 가산 지급',
					value: '50% 미만 가산 지급',
				},
				{ key: 'no-bonus', text: '가산 지급 없음', value: '가산 지급 없음' },
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 8,
			description:
				'연차 유급휴가: 1년간 80% 이상 출근 시 15일 유급휴가 부여. (근로기준법 제60조)',
			penalty: '2년 이하 징역 또는 2천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{
					key: 'partial-payment',
					text: '미사용 휴가 수당 부족 지급',
					value: '미사용 휴가 수당 부족 지급',
				},
				{
					key: 'no-holiday-pay',
					text: '유급휴가 및 수당 미부여',
					value: '유급휴가 및 수당 미부여',
				},
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 9,
			description:
				'최저임금: 최저임금 이상을 지급해야 한다. (최저임금법 제6조)',
			penalty: '3년 이하 징역 또는 2천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{
					key: 'below-min-wage',
					text: '최저임금 미만 지급',
					value: '최저임금 미만 지급',
				},
			],
			result: '',
			aiNomusaCheck: '',
		},
		{
			id: 10,
			description:
				'퇴직금 지급: 퇴직 시 14일 이내에 퇴직금을 지급해야 한다. (근로자퇴직급여 보장법 제9조)',
			penalty: '3년 이하 징역 또는 3천만 원 이하 벌금',
			options: [
				{ key: 'no-violation', text: '위반없음', value: '위반없음' },
				{ key: 'no-payout', text: '퇴직금 미지급', value: '퇴직금 미지급' },
			],
			result: '',
			aiNomusaCheck: '',
		},
	]);

	const currentYear = new Date().getFullYear();

	const registeredWorkerPaydocuWorkerUid = workerUsers.map(
		(user) => user?.paydocuWorkerUid
	);

	const activeWorkers = clientReqs4In.filter((worker) => !worker?.retiredDate);

	// 재직자의 ID 목록 생성
	const workerListId = activeWorkers.map((worker) => worker?.id);

	// 등록되지 않은 재직자 목록 필터링
	const unRegisteredWorkerId = workerListId.filter(
		(id) => !registeredWorkerPaydocuWorkerUid?.includes(id)
	);

	// 등록된 재직자 수와 미등록된 재직자 수를 계산
	const registeredWorkerCount =
		workerListId.length - unRegisteredWorkerId.length;

	const companyId = clientInfo?.id
		? clientInfo?.id
		: clientInfo?.clientUid?.clientUid
		? clientInfo?.clientUid
		: currentUserProfile?.id;

	const companyName = clientInfo?.companyName
		? clientInfo?.companyName
		: currentUserProfile?.companyName
		? currentUserProfile?.companyName
		: '주식회사 000';

	const numOfWorkers = clientInfo?.numOfWorkers
		? clientInfo?.numOfWorkers
		: currentUserProfile?.numOfWorkers;

	const annualLeaves = clientInfo?.annualLeaves
		? clientInfo?.annualLeaves
		: currentUserProfile?.annualLeaves;

	const data = activeWorkers.map((workerList) => {
		let status = '정규직'; // 기본값
		if (workerList?.dayWorker?.daylyPay > 0) {
			status = '일용직';
		} else if (workerList?.dayWorker?.timeWage > 0) {
			status = '시급직';
		} else if (workerList?.isBizWorker) {
			status = '사업소득직';
		}

		return {
			status: status,
			weekLBTime: workerList?.lawBase?.weekLBTime,
			hourOrdinaryWage: workerList?.lawBase?.hourOrdinaryWage, // 정규직의 시급
			daylyPay: workerList?.dayWorker?.daylyPay, // 일용직의 일급
			timeWage: workerList?.dayWorker?.timeWage, // 시급직의 시급
		};
	});

	const retiredWorkers = clientReqs4In.filter(
		(workerList) => workerList?.retiredDate
	); // 퇴사일이 있는 사람 필터링

	// 금품 지급일이 있는 사람 필터링
	const retiredWorkersWithPaidDate = retiredWorkers.filter(
		(workerList) => workerList?.retiredFundPaidDate
	);
	// 퇴사자 명수와 금품 지급일이 입력된 명수
	const totalRetiredWorkers = retiredWorkers.length; // 퇴사자 명수
	const totalRetiredWithPaidDate = retiredWorkersWithPaidDate.length; // 금품 지급일이 입력된 명수

	const retiredWorkerData = clientReqs4In
		.filter((workerList) => workerList?.retiredDate) // 퇴사일이 있는 사람만 필터링
		.filter((workerList) => workerList?.retiredFundPaidDate) // 금품 지급일이 있는 사람만 필터링
		.map((workerList) => {
			// 퇴직자 관련 정보 추가
			const retiredDateM = workerList?.retiredDate
				? moment(workerList?.retiredDate)
				: null;
			const retiredFundPaidDateM = workerList?.retiredFundPaidDate
				? moment(workerList?.retiredFundPaidDate)
				: null;

			// 금품청산 위반 여부 확인
			const retirementCompliance = checkRetirementFundCompliance(
				retiredDateM,
				retiredFundPaidDateM,
				workerList?.retiredFundPaidDate
			);

			return {
				retirementCompliance: retirementCompliance, // 금품청산 위반 여부
				workerName: workerList?.workerName, // 추가로 필요한 정보
				retiredDate: workerList?.retiredDate, // 퇴사일
				retiredFundPaidDate: workerList?.retiredFundPaidDate, // 금품 지급일
			};
		});

	// 현재 재직자 중 정규직, 일용직, 시급직, 사업소득직의 직원 수 계산
	// 정규직 인원 수 계산
	const regularWorkersCount = data.filter(
		(worker) => worker.status === '정규직'
	).length;

	// 정규직이면서 주소정 근로시간(weekLBTime) 15시간 미만인 직원 수 계산
	const partTimeRegularWorkersCount = data.filter(
		(worker) => worker.status === '정규직' && worker.weekLBTime < 15
	).length;
	const dailyWorkersCount = data.filter(
		(worker) => worker.status === '일용직'
	).length;
	const hourlyWorkersCount = data.filter(
		(worker) => worker.status === '시급직'
	).length;
	const bizIncomeWorkersCount = data.filter(
		(worker) => worker.status === '사업소득직'
	).length;

	const lawBaseLowWage2024 = 9860;

	// 현재 재직자 중 정규직의 시급이 9860원보다 낮은 사람 수 계산
	const regularWorkersBelowWage = data.filter(
		(worker) =>
			worker.status === '정규직' && worker.hourOrdinaryWage < lawBaseLowWage2024
	).length;

	// 현재 재직자 중 일용직의 일급이 9860원보다 낮은 사람 수 계산
	const dailyWorkersBelowWage = data.filter(
		(worker) =>
			worker.status === '일용직' && worker.daylyPay < lawBaseLowWage2024
	).length;

	// 현재 재직자 중 시급직의 시급이 9860원보다 낮은 사람 수 계산
	const hourlyWorkersBelowWage = data.filter(
		(worker) =>
			worker.status === '시급직' && worker.timeWage < lawBaseLowWage2024
	).length;

	const violationLawBaseLowWage2024 =
		regularWorkersBelowWage + dailyWorkersBelowWage + hourlyWorkersBelowWage ===
		0
			? '위반없음'
			: `${currentYear}년도 최저임금법 위반`;

	useEffect(() => {
		const fetchWorkerUsersData = async () => {
			try {
				await dispatch(fetchWorkerUsers(companyId));
			} catch (error) {
				console.error('Error fetching worker users:', error);
			}
		};

		fetchWorkerUsersData();
	}, [dispatch, companyId]);

	useEffect(() => {
		const fetchClientReqs = async () => {
			try {
				await dispatch(fetchClientReqs4In(filter));
			} catch (error) {
				console.error('Error fetching client requests:', error);
			}
		};

		fetchClientReqs();

		return () => {
			dispatch(clearClientReqs4In());
		};
	}, [dispatch, filter]);

	// "지급기일 연장 동의(서)가 없는 이상"이라는 메시지를 포함한 퇴사자 수
	const nonCompliantWorkersCount = retiredWorkerData.filter((worker) =>
		worker.retirementCompliance?.includes(
			'지급기일 연장 동의(서)가 없는 이상 퇴직일로부터 14일 이내에 금품청산이 이루어져야 합니다.'
		)
	).length;

	const violation14Pay = nonCompliantWorkersCount > 0 ? '위반' : '준수';

	// ID별로 조건에 따라 aiNomusaCheck 값을 설정
	useEffect(() => {
		const updatedList = checkList.map((item) => {
			let aiNomusaCheck = '';

			switch (item.id) {
				case 1:
					aiNomusaCheck = `${companyName}의 현재 재직자 ${activeWorkers.length}명은 모두 근로기준법 제17조 및 
          기단법 제17조에 따른 항목을 명시한 적법한 근로계약서를 사용중이며, 그 중 ${registeredWorkerCount}명은 AI노무사 
          즉시 교부기능을 사용하고 있습니다.`;
					break;
				case 2:
					aiNomusaCheck = `${companyName}의 현재 퇴직자 ${totalRetiredWorkers}명 중 금품청산일이 입력된 퇴사자는 ${totalRetiredWithPaidDate}명이고 
					그들 중 AI노무사에 등록된 정보에 의하면 퇴직일로부터 14일 이후에 금품이 청산된 퇴사자는 ${nonCompliantWorkersCount}명입니다.`;
					break;
				case 3:
					aiNomusaCheck =
						'임금계약서에 임금 지급일을 명시되어 있으나 실제 지급여부는 확인이 필요합니다.';
					break;
				case 4:
					aiNomusaCheck = `${companyName}의 현재 재직자 ${activeWorkers.length}명은 모두 근로기준법 제48조 제1항에 따른  
          적법한 임금명세서를 사용중이며, 그 중 ${registeredWorkerCount}명은 AI노무사 즉시 교부기능을 사용하고 있습니다.`;
					break;
				case 5:
					aiNomusaCheck = `${companyName}의 현재 재직자 ${activeWorkers.length}명은 모두 근로기준법 제17조 및 
          기단법 제17조에 따른 항목을 명시한 적법한 근로계약서를 사용중이며, 그 중 ${registeredWorkerCount}명은 AI노무사 
          즉시 교부기능을 사용하고 있습니다.`;
					break;
				case 6:
					aiNomusaCheck = `${companyName}의 현재 재직자 ${
						activeWorkers.length
					}명 월급제 직원 총 ${regularWorkersCount} 중 1주 소정근로시간 15시간 미만 직원
          ${partTimeRegularWorkersCount}명을 제외한 나머지 ${
						regularWorkersCount - partTimeRegularWorkersCount
					}명은 모두 근로기준법 제55조에 따른
          유급휴일을 부여하고 있음을 근로계약서 제6조에 명시하고 있고, 주 소정근로 15시간 미만 직원에게는 유급 
          주휴일이 해당되지 않음을 명시하고 있습니다.`;
					break;
				case 7:
					if (numOfWorkers >= 5) {
						aiNomusaCheck = `${companyName}은 상시근로자수 5인 이상 사업장으로, 임금계약서와 임금명세서에 근로기준법 제56조에 따른 가산임금 지급을 명시하고 있습니다.`;
					} else {
						aiNomusaCheck = `${companyName}은 상시근로자수 5인 미만 사업장이므로 근로기준법 제56조가 적용되지 않음이 명시되어 있습니다.`;
					}
					break;
				case 8:
					if (numOfWorkers >= 5) {
						if (annualLeaves === '입사일(법정)기준') {
							aiNomusaCheck = `${companyName}은 상시근로자수 5인 이상 사업장으로, 임금계약서와 임금명세서에 근로기준법 제60조에 따른 연차휴가가 입사일 기준으로 부여됨을 명시되어 있습니다.`;
						} else {
							aiNomusaCheck = `${companyName}은 상시근로자수 5인 이상 사업장으로, 임금계약서와 임금명세서에 근로기준법 제60조에 따른 연차휴가가 회계년도 기준으로 부여됨을 명시되어 있고, 법정연차에 미달함을 방지히기 위해 
              법정연차도 함께 계산하고 있습니다.`;
						}
					} else {
						aiNomusaCheck = `${companyName}은 상시근로자수 5인 미만 사업장이므로 근로기준법 제60조 연차유급 휴가가 적용되지 않음이 명시되어 있습니다.`;
					}

					break;
				case 9:
					aiNomusaCheck = `${companyName}의 현재 재직자 중 정규직 ${regularWorkersCount}명 중 시급이 ${numberToCommaString(
						lawBaseLowWage2024
					)}원 
          미만인 직원 수는 ${regularWorkersBelowWage}명이며, 일용직 ${dailyWorkersCount}명 중 일급이 ${numberToCommaString(
						lawBaseLowWage2024
					)}원 
          미만인 직원 수는 ${dailyWorkersBelowWage}명이며, 시급직 ${hourlyWorkersCount}명 중 시급이 ${numberToCommaString(
						lawBaseLowWage2024
					)}원 
          미만인 직원 수는 ${hourlyWorkersBelowWage}명으로 ${currentYear}년도 최저임금법 ${violationLawBaseLowWage2024}`;
					break;
				case 10:
					aiNomusaCheck = `${companyName}의 현재 퇴직자 ${totalRetiredWorkers}명 중 금품청산일이 입력된 퇴사자는 ${totalRetiredWithPaidDate}명이고 
					그들 중 AI노무사에 등록된 정보에 의하면 퇴직일로부터 14일 이후에 퇴직금이 청산된 퇴사자는 ${nonCompliantWorkersCount}명입니다.`;
					break;
				default:
					aiNomusaCheck = item.aiNomusaCheck; // 기존 값 유지
			}

			return { ...item, aiNomusaCheck };
		});

		// checkList의 값이 변경되었을 때만 업데이트
		if (JSON.stringify(checkList) !== JSON.stringify(updatedList)) {
			setCheckList(updatedList);
		}
	}, [
		companyName,
		checkList,
		activeWorkers.length,
		registeredWorkerCount,
		numOfWorkers,
		annualLeaves,
		lawBaseLowWage2024,
		data,
		regularWorkersCount,
		partTimeRegularWorkersCount,
		dailyWorkersCount,
		hourlyWorkersCount,
		bizIncomeWorkersCount,
		regularWorkersBelowWage,
		dailyWorkersBelowWage,
		hourlyWorkersBelowWage,
		currentYear,
		violationLawBaseLowWage2024,
		retiredWorkerData,
		totalRetiredWorkers,
		totalRetiredWithPaidDate,
		nonCompliantWorkersCount,
		violation14Pay,
	]);

	const splitDescription = (description) => {
		const [item, contentAndLaw] = description.split(':');
		const [content, law] = contentAndLaw.split('(');
		return {
			항목: item.trim(),
			내용: content.trim(),
			근거법령: law ? `(${law.trim()}` : '',
		};
	};
	const realCount = true;

	return (
		<>
			<PaydocuShortCut
				filter={filter}
				clientReqs4In={clientReqs4In}
				realCount={realCount}
			/>
			<Header as='h1' textAlign='center'>
				{currentYear}년도 기초노동질서 자율점검
			</Header>
			<br />
			<Table celled>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell width={2} textAlign='center'>
							항목/법령/벌칙
						</Table.HeaderCell>
						<Table.HeaderCell width={3} textAlign='center'>
							내용
						</Table.HeaderCell>
						<Table.HeaderCell width={5} textAlign='center'>
							AI노무사 확인 내용
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{checkList.map((item, index) => {
						const { 항목, 내용, 근거법령 } = splitDescription(item.description);
						return (
							<Table.Row key={item.id}>
								<Table.Cell>
									{/* 항목 앞에 자동으로 숫자 붙이기 */}
									<strong>
										{index + 1}. {항목}
									</strong>{' '}
									<br />
									{근거법령}
									<br />
									{item.penalty}
								</Table.Cell>
								<Table.Cell>{내용}</Table.Cell>
								{/* 강조 색으로 AI노무사검증 셀 스타일링 */}
								<Table.Cell style={{ color: 'green', fontWeight: 'bold' }}>
									{item.aiNomusaCheck}
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		</>
	);
};

export default SelfCheckLaborLaw;
