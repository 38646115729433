import React from 'react';
import { Link } from 'react-router-dom';
import ClientReqLastPreventData from '../ClientReqLastPreventData';

export default function ClientReqDashboard4InAuthenticated({
	lastPreventData,
	lastPreventDataShow,
	authLevel,
	// numOfWorkers,
	// annualLeaves,
	// finBaseDateF,
}) {
	return (
		<>
			<h3 style={{ color: 'red' }}>* 고용안정지원금 감원방지기간 안내</h3>
			{lastPreventData && lastPreventDataShow ? (
				<ClientReqLastPreventData
					lastPreventData={lastPreventData}
					authLevel={authLevel}
				/>
			) : (
				<>
					<h3 style={{ color: 'blue' }} as={Link} to={`/pricePolicyPaper`}>
						중요 : 입력된 퇴사자 정보에 의하면 고용안정지원금 감원방지기간에
						해당되지 않습니다.
					</h3>
					<hr />
				</>
			)}
			{/* <h3 style={{ color: 'red' }}>* 연차 계산내역 안내</h3>
			{numOfWorkers < 5 ? (
				<div style={{ color: 'blue' }}>
					* 회사정보에 등록된 직원수는 5인 미만으로 근로기준법 60조에 따른
					연차휴가가 발생하지 않습니다.
				</div>
			) : (
				<div>
					* 년 단위 연차는 전년도에 80%이상 출근했다는 가정하에 AI NOMUSA로
					설계된 근로계약서 제6조(휴일 및 휴가) 제4항에 따라 계산된 것으로
					참고용임.
				</div>
			)}
			<br />
			<hr /> */}
		</>
	);
}
