import React from 'react';
import { laborContractStyle } from '../../app/common/util/util';

export default function ClientPrivateProtectPaperContents({currentUserProfile, agentType, referralAgentType}) {

  return (
    <>
      <br />
      <div>재정일 : 2021년 11월 19일</div>
      <div style={laborContractStyle}><strong>{`제1장 수집하는 개인정보의 항목 및 수집 방법`}</strong> 
        <br />
        <br />
        <div className='margin'>{`제1조 처리하는 개인정보의 항목`}</div>
        <div className='margin'>{`1. 회사는 최초 회원가입 시 원활한 고객 상담, 서비스 제공을 위해 아래와 같은 최소한의 개인정보를 필수 항목으로 수집하고 있습니다`}</div>
        <div className='margin'>{`1-1. 회사명`}</div>
        <div className='margin'>{`1-2. 연락처`}</div>
        <div className='margin'>{`1-3. 이메일`}</div>
        <div className='margin'>{`1-4. 비밀번호`}</div>
        <div className='margin'>{`2. 서비스 이용 과정이나 사업 처리 과정에서 아래와 같은 정보들이 추가로 수집될 수 있습니다.`}</div>
        <div className='margin'>{`2-1. 서비스 이용 정보: 사업자 업태, 종목, 사업자등록번호, 대표자명, 회사 직원 수, 회계기준일, 연차설정정보, 동종유사근로자 1주 근무일수, 사업장 주소`}</div>
        <div className='margin'>{`2-2. 거래정보: 신용카드 정보(카드번호, 유효기간, 비밀번호 앞 두 자리), 세금계산서 발행을 위한 회계 담당자 정보(이름, 연락처, 이메일 주소) 등`}</div>
        <div className='margin'>{`2-3. IP 주소, 쿠키, 방문 일시, 서비스 이용 기록, 불량 이용 기록, 브라우저 정보, 운영체제(OS) 정보, 사용 기기 정보, MAC 주소, UUID, 방문 일시 등`}</div>
        <div className='margin'>{`2-4. 개인에 대한 정보: 성명(사용자, 근로자), 계약기간(계약일, 계약종료일) 근무장소, 업무내용, 작성일, 사업체정보,(사업체명)(고죵주), 전화번호, 주소, 대표자명, 서명, 근로자ID(이메일), 서울시인증여부, 이메일), 친권자(후견인), 동의서 정보(성명, 생년월일, 주소, 연락처, 연소근로자관계), 연소근로자 정보(생년월일, 만나이, 주소, 연락처, 친권자 서명), 후견인 동의표시, 후견인 동의서 작성일, 가족관계 증명서 여부`}</div>
        <div className='margin'>{`2-5. 간접개인정보 : 근로시간, 휴게시간, 근무일, 휴일, 임금(시급, 일급, 주급, 월급) 입금액, 시급액, 상여금액, 기타급여(항목, 금액), 임금지급일, 임금지급방법(직접, 통장입금), 보험적용여부(고용보험, 산재보험, 국민연금, 건강보험) 출근정보(출근일, 출근시간, 퇴근시간), 근무시간정보(근무인정시간, 시간외, 야간근무, 휴일근무, 초과근무)`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제2장 개인정보의 수집 및 이용목적`}</strong> 
        <br />
        <br />
        <div className='margin'>{`제3조 회원관리`}</div>
        <div className='margin'>{`회원제 서비스 제공, 개인 식별, 이용약관 위반 회원에 대한 이용제한 조치, 서비스의 원활한 운영에 지장을 미치는 행위 및 서비스 부정이용 행위 제재, 가입의사 확인, 가입 및 가입횟수 제한, 만 14세 미만 아동의 개인정보 수집 시 법정 대리인 동의여부 확인, 추후 법정 대리인 본인확인, 분쟁 조정을 위한 기록보존, 불만처리 등 민원처리, 고지사항 전달, 회원탈퇴 의사의 확인`}</div>
        <br />
        <div className='margin'>{`제4조 신규 서비스 개발 및 마케팅·광고에의 활용`}</div>
        <div className='margin'>{`신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스 이용에 대한 통계`}</div>
        <br />
        <div className='margin'>{`제5조 서비스 제공에 관한 계약 이행 및 유료 서비스 제공에 따른 요금 정산`}</div>
        <div className='margin'>{`콘텐츠 제공, 특정 맞춤 서비스 제공, 유료 서비스 이용에 대한 과금, 구매 및 요금 결제, 본인인증, 요금 추심`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제3장 개인정보의 공유 및 제공`}</strong> 
        <br />
        <br />
        <div className='margin'>{`회사는 이용자들의 개인정보를 ‘2. 개인정보의 수집목적 및 이용목적’에서 고지한 범위 내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다. 다만, 아래의 경우에는 예외로 합니다.`}</div>
        <div className='margin'>{`1. 이용자가 사전에 동의한 경우`}</div>
        <div className='margin'>{`2. 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우`}</div>
        <div className='margin'>{`3. 개인정보 공유 및 제공 업체 및 기관`}</div>
        <div className='margin'>{`3-1. 전산개발 및 유지보수 : 노무법인 최상인업`}</div>
        {(agentType === 'bs노무사' || referralAgentType === 'bs노무사' || agentType === 'ps노무사' || referralAgentType === 'ps노무사') &&
          <div className='margin'><span style={{color: 'blue'}}>{`3-2. 노무서비스 해당업무 대행 또는 대리인 : ${currentUserProfile?.agentName}`}</span></div>
        }
      </div>
      <div style={laborContractStyle}><strong>{`제4장 개인정보의 취급 위탁`}</strong> 
        <br />
        <br />
        <div className='margin'>{`서비스 제공에 있어 필요한 업무 중 일부를 외부 업체로 하여금 수행하도록 개인정보를 위탁하고 있습니다. 그리고 위탁 받은 업체가 관계 법령을 준수하도록 관리·감독하고 있습니다.`}</div>
        <div className='margin'>{`1. 현재 개인정보의 취급 위탁 업체 : 없음.`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제5장 개인정보의 보유 및 이용기간`}</strong> 
        <br />
        <br />
        <div className='margin'>{`이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 단, 상법, 전자상거래 등에서의 소비자보호에 관한 법률 등 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 관계법령에서 정한 일정한 기간 동안 회원 정보를 보관합니다. 이 경우 회사는 보관하는 정보를 그 보관의 목적으로만 이용하며 보존기간은 아래와 같습니다.`}</div>
        <div className='margin'>{`제6조 계약 또는 청약철회 등에 관한 기록`}</div>
        <div className='margin'>{`1. 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률`}</div>
        <div className='margin'>{`2. 보존 기간: 5년`}</div>
        <br />
        <div className='margin'>{`제7조 대금 결제 및 재화 등의 공급에 관한 기록`}</div>
        <div className='margin'>{`1. 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률`}</div>
        <div className='margin'>{`2. 보존 기간: 3년`}</div>
        <br />
        <div className='margin'>{`제8조 소비자의 불만 또는 분쟁처리에 관한 기록`}</div>
        <div className='margin'>{`1. 보존 이유: 전자상거래 등에서의 소비자보호에 관한 법률`}</div>
        <div className='margin'>{`2. 보존 기간: 3년`}</div>
        <br />
        <div className='margin'>{`제9조 근로계약서 등 생성을 위해 시스템에서 생성된 생산물에 관련한 개인정보`}</div>
        <div className='margin'>{`1. 보존 이유: 근로기준법 제제42조 및 시행령 제22조`}</div>
        <div className='margin'>{`2. 보존 기간: 근로자 퇴사일로부터 3년`}</div>
        <br />
        <div className='margin'>{`제10조 웹사이트 방문 기록`}</div>
        <div className='margin'>{`1. 보존 이유: 통신비밀보호법`}</div>
        <div className='margin'>{`2. 보존 기간: 3개월`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제6장 개인정보 파기절차 및 방법`}</strong> 
        <br />
        <br />
        <div className='margin'>{`이용자의 개인정보는 원칙적으로 개인정보의 수집 및 이용목적이 달성되면 지체 없이 파기합니다. 회사의 개인정보 파기절차 및 방법은 다음과 같습니다.`}</div>
        <div className='margin'>{`제10조 파기절차`}</div>
        <div className='margin'>{`1. 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라(보유 및 이용기간 참조) 일정 기간 저장된 후 파기됩니다.`}</div>
        <div className='margin'>{`2. 동 개인정보는 법률에 의한 경우가 아니고서는 보유되는 이외의 다른 목적으로 이용되지 않습니다.`}</div>
        <br />
        <div className='margin'>{`제11조 파기방법`}</div>
        <div className='margin'>{`1. 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.`}</div>
        <div className='margin'>{`2. 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제7장 이용자 및 법정 대리인의 권리와 그 행사 방법`}</strong> 
        <br />
        <br />
        <div className='margin'>{`1. 이용자 및 법정대리인은 언제든지 등록되어 있는 자신 혹은 당해 만 14세 미만 아동의 개인정보를 조회하거나 수정할 수 있으며, 회사의 개인정보의 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원 탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.`}</div>
        <div className='margin'>{`2. 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘개인정보 변경’(또는 ‘회원정보 수정’ 등) 기능을, 가입 해지(동의 철회)를 위해서는 노무법인 최상인업 웹사이트 또는 AI NOMUSA시스템, SNS 또는 구글, 네이버 등 포탈 사이트에 공개된 회사 연락처에 직접 연락하거나 서비스 내 회원 탈퇴 기능을 통해 파기할 수 있습니다. 혹은 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.`}</div>
        <div className='margin'>{`3. 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.`}</div>
        <div className='margin'>{`4. 회사는 이용자 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 ‘5. 개인정보의 보유 및 이용기간’에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제8장 개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항`}</strong> 
        <br />
        <br />
        <div className='margin'>{`제12조 쿠키`}</div>
        <div className='margin'>{`1. 회사는 개인화되고 맞춤화된 서비스를 제공하기 위해서 이용자의 정보를 저장하고 수시로 불러오는 ‘쿠키(cookie)’를 사용합니다.`}</div>
        <div className='margin'>{`2. 쿠키는 웹사이트를 운영하는데 이용되는 서버가 이용자의 브라우저에게 보내는 아주 작은 텍스트 파일로 이용자 컴퓨터의 하드디스크에 저장됩니다. 이후 이용자가 웹사이트에 방문할 경우 웹사이트 서버는 이용자의 하드디스크에 저장되어 있는 쿠키의 내용을 읽어 이용자의 환경설정을 유지하고 맞춤화된 서비스를 제공하기 위해 이용합니다.`}</div>
        <div className='margin'>{`3. 쿠키는 개인을 식별하는 정보를 자동적·능동적으로 수집하지 않으며, 이용자는 언제든지 이러한 쿠키의 저장을 거부하거나 삭제할 수 있습니다.`}</div>
        <br />
        <div className='margin'>{`제13조 회사의 쿠키 사용 목적`}</div>
        <div className='margin'>{`1. 회사는 쿠키를 통해 이용자가 선호하는 설정 등을 저장하여 이용자에게 보다 빠른 웹 환경을 지원하며, 편리한 이용을 위해 서비스 개선에 활용합니다.`}</div>
        <div className='margin'>{`2. 회사는 쿠키를 통해 서비스의 접속 빈도, 방문 시간, 방문 횟수 등을 분석하여, 이용자의 취향과 관심분야를 파악하고 이를 서비스 제공에 활용합니다.`}</div>
        <br />
        <div className='margin'>{`제14조 쿠키의 설치·운영 및 거부`}</div>
        <div className='margin'>{`1. 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.`}</div>
        <div className='margin'>{`2. 다만, 쿠키의 저장을 거부할 경우에는 서비스의 기능이 제대로 동작하지 않을 수 있으며, 이와 관련하여 회사는 책임지지 않습니다.`}</div>
        <div className='margin'>{`3. 쿠키 설치 허용 여부를 지정하는 방법(Internet Explorer의 경우)은 다음과 같습니다.`}</div>
        <div className='margin'>{`3-1. 도구 메뉴에서 인터넷 옵션을 선택합니다.`}</div>
        <div className='margin'>{`3-2. 개인 정보 탭을 선택합니다.`}</div>
        <div className='margin'>{`3-3. 설정에서 슬라이더를 위로 이동하여 모든 쿠키를 차단하거나 아래로 이동하여 모든 쿠키를 허용한 다음 확인을 선택합니다.`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제9장 개인정보의 기술적·관리적 보호 대책`}</strong> 
        <br />
        <br />
        <div className='margin'>{`회사는 이용자의 개인정보를 처리함에 있어 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적·관리적 대책을 강구하고 있습니다.`}</div>
        <div className='margin'>{`제15조 비밀번호 암호화`}</div>
        <div className='margin'>{`서비스 이용자의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.`}</div>
        <br />
        <div className='margin'>{`제16조 해킹 등에 대비한 대책`}</div>            
        <div className='margin'>{`1. 회사는 해킹이나 컴퓨터 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다.`}</div>
        <div className='margin'>{`2. 개인정보의 훼손에 대비해서 자료를 수시로 백업하고 있고, 최신 백신 프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있으며, 암호화 통신 등을 통하여 네트워크상에서 개인정보를 안전하게 전송할 수 있도록 하고 있습니다.`}</div>
        <div className='margin'>{`3. 침입차단 시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위해 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.`}</div>
        <br />
        <div className='margin'>{`제17조 처리 직원의 최소화 및 교육`}</div>
        <div className='margin'>{`회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있고 이를 위한 별도의 비밀번호를 부여하여 정기적으로 갱신하고 있으며, 담당자에 대한 수시 교육을 통하여 이 개인정보처리방침의 준수를 항상 강조하고 있습니다.`}</div>
        <br />
        <div className='margin'>{`제18조 개인정보보호전담기구의 운영`}</div>
        <div className='margin'>{`사내 개인정보보호전담기구 등을 통하여 이 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다. 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.`}</div>            
      </div>
      <div style={laborContractStyle}><strong>{`제10장 개인정보관리책임자 및 담당자의 연락처`}</strong> 
        <br />
        <br />
        <div className='margin'>{`이용자는 회사의 서비스를 이용하며 발생하는 모든 개인정보보호 관련 민원을 개인정보관리책임자 혹은 담당 부서로 신고하실 수 있습니다. 회사는 이용자들의 신고사항에 대해 신속하게 충분한 답변을 드릴 것입니다.`}</div>
        <div className='margin'>{`제19조 개인정보관리책임자`}</div>
        <div className='margin'>{`1. 회사의 개인정보관리책임자는 다음과 같습니다.`}</div>
        <div className='margin'>{`1-1. 이름 : 김경모`}</div>
        <div className='margin'>{`1-2. 전화번호 : 02-555-9356`}</div>
        <div className='margin'>{`1-3. 이메일 : paydac@naver.com`}</div>
        <div className='margin'>{`2. 기타 개인정보침해에 대한 신고나 상담이 필요하신 경우에는 아래 기관에 문의하시기 바랍니다.`}</div>
        <div className='margin'>{`2-1. 개인정보침해신고센터 ( http://privacy.kisa.or.kr) / 국번없이 118)`}</div>            
        <div className='margin'>{`2-2. 대검찰청 과학수사부 사이버수사과 ( http://www.spo.go.kr) / 02-3480-3570)`}</div>
        <div className='margin'>{`2-3. 경찰청 사이버안전국 ( http://cyberbureau.police.go.kr) / 국번없이 182)`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제11장 적용범위`}</strong> 
        <br />
        <br />
        <div className='margin'>{`노무법인 최상인업에 링크되어 있는 다른 웹사이트들이 개인정보를 수집하는 행위에 대해서는 노무법인 최상인업의 개인정보처리방침이 적용되지 않음을 알려 드립니다. 또한 본 개인정보처리방침은 회사와 이용계약을 체결한 회원에 한해 적용됩니다.`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제12장 고지의 의무`}</strong> 
        <br />
        <br />
        <div className='margin'>{`위 개인정보의 수집 및 이용에 관한 동의는 필수이지만 본인은 동의를 거부할 수 있습니다. 다만, 거부시 본 AI NOMUSA를 사용할 수 없습니다.`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제13장 동의 거부 권리 및 거부시 불이익`}</strong> 
        <br />
        <br />
        <div className='margin'>{`현 개인정보처리방침의 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 이메일을 통해 고지할 것입니다. 다만 개인정보의 수집 및 활용, 제3자 제공 등과 같이 이용자 권리의 중요한 변경이 있을 경우에는 최소 30일 전에 고지합니다.`}</div>
      </div>
      <div style={laborContractStyle}><strong>{`제13장 시행일자`}</strong> 
        <br />
        <br />
        <div className='margin'>{`제20조. 시행일`}</div>
        <div className='margin'>{`이 약관은 2021년 8월 1일부터 시행합니다.`}</div>
        <div className='margin'>{`단, 본 약관의 공지 이후 적용일 이전에 가입한 신규회원에 대해서는 전단의 규정에도 불구하고 회원 가입시부터 본 약관이 적용됩니다.`}</div>
        <br />
      </div>
    </>
  )
}
