import React, { useEffect, useState } from 'react';
import MyDateInput from '../../../../../app/common/form/MyDateInput';
import MyNumberInput from '../../../../../app/common/form/MyNumberInput';

import MyTextInput from '../../../../../app/common/form/MyTextInput'

import MySelectInput from '../../../../../app/common/form/MySelectInput';
import { payDayType } from '../../../../../app/api/dropdownOption';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../app/common/modals/modalReducer';
import { Icon, Label } from 'semantic-ui-react';
import { wageContarctEndDateValue, workerEnteredDateValue } from '../../../../../app/common/util/util';
// import { Button } from 'semantic-ui-react';

export default function CrwfWorkerBasicInfoMonthWageInfo({
  values
}) {

  const dispatch = useDispatch();

  function workerRegStep3YTOpen() {
    dispatch(openModal({modalType:'WorkerRegStep3'}))
  }

  const [alertShown, setAlertShown] = useState(false);

  useEffect(() => {
    // 필수 입력란이 모두 채워졌는지 확인
    const allRequiredFieldsFilled = values?.totalMonthWageAmount &&
      values?.wageContractStartDate &&
      values?.wageContarctEndDate &&
      values?.monthlyPay?.startToEndDate &&
      values?.monthlyPay?.payDay &&
      values?.monthlyPay?.payDayBeforeAfter;
    if (allRequiredFieldsFilled && !alertShown) {
      alert('3. 임금정보 중 (3-2) 근로소득세 및 4대보험 설정 정보는 다시 한번 확인해보세요.');
      setAlertShown(true); // 다시 뜨지 않도록 상태 업데이트
    }
  }, [
    values?.totalMonthWageAmount,
    values?.wageContractStartDate,
    values?.wageContarctEndDate,
    values?.monthlyPay?.startToEndDate,
    values?.monthlyPay?.payDay,
    values?.monthlyPay?.payDayBeforeAfter,
    alertShown
  ]);

  return (
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      <div style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '17px'}}>
        <span style={{color : 'red'}}>* </span><span style={{color : 'blue'}}>기본 임금정보 입력</span ><span style={{fontSize : '8px'}}>(설명은 오른쪽 동영상을 참조하세요.)</span>
        <span style={{position: "absolute", right: 0, marginRight:'10px', cursor:'pointer'}}><Label style={{color:'red'}} onClick={() => workerRegStep3YTOpen()}><Icon size='big' name='youtube' />동영상 설명</Label></span>
      </div>
        <div className='margin' style={{width : '33%', marginRight : '3px'}}>
          <MyNumberInput
            label={<span style={{color: 'blue'}}>* (1) 월급총액 (비과세 등 모든 수당을 포함한 총액)(원)</span>}                
            name='totalMonthWageAmount' 
            placeholder='월급액' 
            autoComplete='off'
          />
        </div>
        <div className='margin' style={{width : '33%', marginRight : '3px'}}>
          <MyDateInput 
            label={<span style={{color: 'blue'}}>* (2) 월급(연봉) 계약 시작일</span>}                
            name='wageContractStartDate' 
            placeholderText={workerEnteredDateValue(values)}
            timeFormat='HH:mm'
            timeCaption='time'
            dateFormat='yyyy-MM-dd'
            autoComplete='off'
          />
        </div>
        <div className='margin' style={{width : '33%', marginRight : '3px'}}>
          <MyDateInput 
            label={<span style={{color: 'blue'}}>* (3) 월급 계약 종료일</span>}                
            name='wageContarctEndDate' 
            placeholderText={wageContarctEndDateValue(values)}
            timeFormat='HH:mm'
            timeCaption='time'
            dateFormat='yyyy-MM-dd'
            autoComplete='off'
          />
        </div>
        <div className='margin' style={{width : '33%', marginRight : '3px'}}>
          <MyTextInput
            label={<span style={{color: 'blue'}}>* (4) 월급 산정 기간</span>}                
            name='monthlyPay.startToEndDate' 
            placeholder='예) 전월 26일~ 당월 25일' 
            autoComplete='off' 
          />
        </div>
        <div className='margin' style={{width : '33%', marginRight : '3px'}}>
          <MyTextInput
            label={<span style={{color: 'blue'}}>* (5) 월급 지급일</span>}                
            name='monthlyPay.payDay' 
            placeholder='예) 익월 10일' 
            autoComplete='off'
          />
        </div>
        <div className='margin' style={{width : '33%', marginRight : '3px'}}>
          <MySelectInput 
            label={<span style={{color: 'blue'}}>* (6) 월급날이 휴일인 경우 월급 지급일</span>}                
            placeholder='예) 지급일 전 근무일' 
            name='monthlyPay.payDayBeforeAfter' 
            options={payDayType} 
          />
        </div>

    </div>
  )
}
