import React, { useState, useEffect, useMemo } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function RoeBS1GeneralRules({
	company, // 이 부분 수정
	companyGa,
	serviceStartDate,
	allOpen,
	setSectionCount, // 조항 수를 설정하는 함수 전달
}) {
	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `목적`,
				content: [
					`이 규정은 ${company}의 영업비밀과 주요시설의 관리 및 보호에 관한 필요한 사항을 규정하여 효율적인 업무수행과 ${company}의 발전을 도모함을 목적으로 한다.`,
				],
			},
			{
				title: `정의`,
				content: [
					`이 규정에서 사용되는 용어의 정의는 다음과 같다.`,
					[
						`“영업비밀”이란 함은 ${companyGa} 보유 또는 보유할 정보로서 공연히 알려져 있지 아니하고 독립된 경제적 가치를 가지는 것으로, 상당한 노력에 의하여 비밀로 유지된 생산방법·판매방법·경영관리 기타 영업활동에 유용한 기술상 또는 경영상의 정보를 말한다.`,
						`“각급 부서”이라 함은 회사 규정에 의하여 설치된 각종 하부 및 소속 부서을 말한다.`,
						`“사원”이라 함은 회사에 재직하는 임원과 사원을 말한다.`,
						`“영업비밀 보호”란 외부에 알려질 경우 ${company}의 안전과 이익을 해치는 비밀과 회사에 관한 중요정보를 보호하기 위한 유·무형의 모든 조치를 말한다.`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `보호대상`,
				content: [
					`이 규정은 ${companyGa} 보유하고 있는 다음 각 호를 그 보호대상으로 한다.`,
					[
						`영업비밀 그 자체`,
						`영업비밀이 화체된 물건 및 물체(예시: 서류(전자문서 포함), 도면, 복사물, 자기테이프, 자재, 생산품, 등)`,
						`영업비밀 생산설비와 장비`,
						`영업비밀 통제구역`,
						`기타 ${company} 기밀과 관련된 정보`,
					],
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `적용범위`,
				content: [
					`이 규정은 ${company}에 신규채용∙재직∙퇴직하는 모든 사원 및 출입자에게 적용한다.`,
					`이 규정은 ${company}의 각급 부서 및 시설물에 적용한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
		];

		// if (['대한민', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections?.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}
		setSectionCount(baseSections.length); // 조항 수 설정

		return baseSections;
	}, [company, companyGa, setSectionCount]);

	const [openSections, setOpenSections] = useState({});

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제1장 총 칙</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={index + 1} // 동적으로 number 생성
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
