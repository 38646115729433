import React, { useState } from 'react';
import * as moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Item, Label, Segment } from 'semantic-ui-react';
import { decrypto } from '../../../../../app/firestore/firestoreService';
import { deleteClientReq4InInFirestore } from '../../../../../app/firestore/firestoreService4In';
import { deleteClientReq4In } from '../../clientReqActions4In';
import { asyncActionError } from '../../../../../app/async/asyncReducer';
import {
  absentDaysYearCntForALF,
  contractOldWorkerF,
  freeComClient,
  getAgeFromSocialNumber,
  lawlowestWageF,
	lowWageOKComment,
	lowWageViolationDashboardComment,
	numberToCommaString,
	numStringToNumber,
	restOKComment,
	restViolationDashboardComment,
	roundUp,
	roundUp2,
	usedAnnualLeavesInLastYearSumF,
} from '../../../../../app/common/util/util';

import InsureInReportButton from '../InsureInReportButton';
import InsureOutReportButton from '../InsureOutReportButton';
import DayWorkerListItem4InButton from '../DayWorkerListItem4InButton';
import OpenDetailBasicInfoListItem4In from '../OpenDetailBasicInfoListItem4In';
import OpenDetailAnnualLeavesListItem4In from '../OpenDetailAnnualLeavesListItem4In';
import JudgeDayWorkerListItem4In from '../JudgeDayWorkerListItem4In';
import InsuranceListItem4In from '../InsuranceListItem4In';
import CustomConfirm from '../../../../../app/layout/ConfirmComponent';

export default function ClientReqListItem4InDay({ 
  clientReq4In, 
  index, 
  clientReqs4In,
  authLevel,
  companyName
}) {

	const dispatch = useDispatch();

	const { currentUserProfile } = useSelector(state => state.profile);
  const { clientInfo, superAdminForFreeTrue } = useSelector(state => state.companyReducer);
	const [loadingDelete, setLoadingDelete] = useState(false);
	const [confirmOpen, setConfirmOpen] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);

  function openDetailHandler () {
    setOpenDetail(!openDetail)
  }

  // 유효한 날짜 값인지 확인하고 포맷하는 함수
  const formatDateWithMoment = (dateString) => {
    return moment(dateString).isValid() ? moment(dateString).format('YYYY-MM-DD') : '';
  };
  
  const isEtcWorker = clientReq4In?.isEtcWorker;
  const workerId = clientReq4In?.id
  const isExceptTimeLawWorker = clientReq4In?.isExceptTimeLawWorker;
  const fixedMonthRealWorkingHoursETL = clientReq4In?.fixedMonthRealWorkingHoursETL;

	//유저 권한(10: 회원가입, 20: 근로계약서 계약, 30: 아웃소싱 계약, 50: 노무사)
	const ceoName = clientInfo?.ceoName ? clientInfo?.ceoName : currentUserProfile?.ceoName;
  const overFiveStartDay = clientInfo?.overFiveStartDay ? formatDateWithMoment(clientInfo?.overFiveStartDay) :
                            currentUserProfile?.overFiveStartDay ? formatDateWithMoment(currentUserProfile?.overFiveStartDay) : '';

	const decryptoClientReq = {};
	for (const prop in clientReq4In) {
		decryptoClientReq[prop] =
    (prop === "workerSocialNumberFront" 
    || prop === "workerSocialNumberBack" 
    || prop === "familySocialNumber"
    || prop === "healthCarePeopleComments")
      ? decrypto(clientReq4In[prop], String(process.env.CRYPTO_KEY))
      : clientReq4In[prop];
	}

  const workerSocialNumberFront = decryptoClientReq.workerSocialNumberFront
  const netAge = getAgeFromSocialNumber(workerSocialNumberFront);


	//상시근로지수
	const numOfWorkers = decryptoClientReq?.numOfWorkers;

  const contarctOldWorkerYes = contractOldWorkerF(decryptoClientReq?.contractOldWorker, decryptoClientReq?.afterRetireAgainStartEnterDate, 
    decryptoClientReq?.againContractStartDate, netAge);

	//입사일
	const enterDate = clientReq4In ? formatDateWithMoment(decryptoClientReq?.workerEnteredDate) : ''
  //
  const afterRetireAgainContractStartDate = (clientReq4In && contarctOldWorkerYes && decryptoClientReq?.afterRetireAgainContractStartDate) ? formatDateWithMoment(decryptoClientReq?.afterRetireAgainContractStartDate) : '';
	

  const retiredDate = decryptoClientReq?.retiredDate ? formatDateWithMoment(decryptoClientReq.retiredDate) : '재직자'


  //5인이상 적용일과 촉탁 입사일로 입사일 조정
  const anuualLeaveEnterDate = overFiveStartDay >= (enterDate || (afterRetireAgainContractStartDate)) ? overFiveStartDay :
                              afterRetireAgainContractStartDate >= enterDate ? afterRetireAgainContractStartDate :
                              enterDate;

  const mTPCTime = decryptoClientReq?.mTPCTime;


  const thisYear = moment(new Date()).format('yyyy')

	const workerPeriod = moment(new Date()).diff(moment(enterDate), 'month');
  const underOneYearWorkPeriod = workerPeriod < 12
  
  function secondYearForAnnualLeave(enterDate, thisYear) {
    const enterYear = +moment(enterDate).format('yyyy')
    if (enterYear+1 === +thisYear) {
      return true
    }
  }
	//근로계약서 직원 교부일
	const firstConfirmedTimeOfLaborContract = decryptoClientReq?.confirmedTimeOfLaborContract 
		? decryptoClientReq?.confirmedTimeOfLaborContract[0].toDate().toString() : ''

	// 임금계약서 직원 교부일
	const firstConfirmedTimeOfWageContract = decryptoClientReq?.confirmedTimeOfWageContract 
		? decryptoClientReq?.confirmedTimeOfWageContract[0].toDate().toString() : '';

	const deleteDisable = (firstConfirmedTimeOfLaborContract || firstConfirmedTimeOfWageContract || (!superAdminForFreeTrue && freeComClient(authLevel, ceoName))) ? true : false

	//일용직 일급
	const daylyPay = decryptoClientReq?.daylyPay?.wage ? numStringToNumber(decryptoClientReq?.daylyPay?.wage) : 0
	const daylyTimePay = decryptoClientReq?.daylyPay?.timeWage ? numStringToNumber(decryptoClientReq?.daylyPay?.timeWage) : 0

  const isDayWorker = (daylyPay || daylyTimePay) ? true : false;
  const isTimeWorker = clientReq4In?.isTimeWorker ? true : false;

	//연차 계산
	const annualLeaves = decryptoClientReq?.annualLeaves;
	const finBaseDate = decryptoClientReq?.finBaseDate ? formatDateWithMoment(decryptoClientReq?.finBaseDate) : '';
	const finBaseMonthDay = decryptoClientReq?.finBaseDate ? formatDateWithMoment(decryptoClientReq?.finBaseDate, 'MM월 dd일') : '';
	// //퇴직연차계으로 당장 필요없음
	// const retireDate = decryptoClientReq?.retiredDate ? formatDateWithMoment(decryptoClientReq?.retiredDate) : '';

	// //통상시급
	// const hourOrdinaryWage = decryptoClientReq?.hourOrdinaryWage;
	//최저시급
	const calHourLowestWage = decryptoClientReq?.lawBase?.calHourLowestWage;

	//실수령액(실제)
	const deductWage = decryptoClientReq?.calTaxAnd4In?.deductWage;

	const totalMonthWageAmount = decryptoClientReq?.totalMonthWageAmount ? numberToCommaString(decryptoClientReq?.totalMonthWageAmount) : '';


	//청년채용특별지원금
	const youthSupportFundPerson = decryptoClientReq?.youthSupportFundPerson;
	// //코로나 특별고용촉진장려금
	// const covid19SupportFundPerson = decryptoClientReq?.covid19SupportFundPerson;
	//대체인력지원금
	const replacedWorkerFundPerson = decryptoClientReq?.replacedWorkerFundPerson;

  const childSupportFundPerson = decryptoClientReq?.childSupportFundPerson


async function handleDeleteToggle () {
	setConfirmOpen(false);
	setLoadingDelete(true);
	try {
		await deleteClientReq4InInFirestore(clientReq4In.id);
		await dispatch(deleteClientReq4In(clientReq4In.id));
		setLoadingDelete(false);
	} catch (error) {
		setLoadingDelete(true);
		dispatch(asyncActionError());		
	}
}
////////////2024년 최저임금작업////////////////////////////

const sumOfTFWagesForBLLowWage = clientReq4In.cTFMealWage + clientReq4In.cTFCarWage + clientReq4In.cTFChildWage + clientReq4In.cTFStudyWage + clientReq4In.cTFEtc ;
const cOWMonthlyBonus =  clientReq4In?.ordinaryWage?.monthlyBonus ? numStringToNumber(clientReq4In?.ordinaryWage.monthlyBonus) : 0;
const cTotalMonthWageAmount = clientReq4In?.totalMonthWageAmount ? numStringToNumber(clientReq4In?.totalMonthWageAmount) : 0;
const sumOfAWages = clientReq4In?.calWorkWage?.sumOfAWages;
const monthOrdinaryWage = clientReq4In?.calWorkWage?.monthOrdinaryWage;
const cOWDayHourWage = clientReq4In?.cOWDayHourWage;
const monthLBTime = clientReq4In?.lawBase?.monthLBTime;
const workStartTime = clientReq4In?.daylyPay?.workingStartTime ? clientReq4In?.daylyPay?.workingStartTime : null;
const workEndTime = clientReq4In?.daylyPay?.workingEndTime ? clientReq4In?.daylyPay?.workingEndTime : null;
const dayType = clientReq4In?.daylyPay?.workingEndTimeNextDayOrNot ? clientReq4In?.daylyPay?.workingEndTimeNextDayOrNot : null;
const dayRest = clientReq4In?.daylyPay?.dayBreakTimes === undefined ? 0 : clientReq4In?.daylyPay?.dayBreakTimes;
const nightRest = clientReq4In?.daylyPay?.nightBreakTimes === undefined ? 0 : clientReq4In?.daylyPay?.nightBreakTimes;
const week14OverBLTime = clientReq4In?.week14OverBLTime

const lawlowestWage2024 = 
lawlowestWageF(2024, 
  +sumOfTFWagesForBLLowWage, 
  +cOWMonthlyBonus, 
  +numOfWorkers, 
  +cTotalMonthWageAmount, 
  +sumOfAWages, 
  +monthOrdinaryWage, 
  +mTPCTime, 
  +cOWDayHourWage, 
  +monthLBTime, 
  daylyPay, 
  week14OverBLTime, 
  workEndTime, 
  dayType, 
  workStartTime, 
  dayRest,
  nightRest);
  
  const minusDayWorkerDayWage =  roundUp(lawlowestWage2024.insufficientDayWage);
///////////////////////////////////////////////////////////////////

return (
    <>
      <Segment.Group style={{ marginBottom : '-30px'}}>
        <Segment>
          <Item.Group>
            <Item>
              <Item.Content>
                {authLevel >= 10 &&
                  <>
                    <Item.Description>
                      <DayWorkerListItem4InButton 
                        openDetailHandler={openDetailHandler}
                        clientReq4In={clientReq4In}
                        daylyTimePay={daylyTimePay}
                        freeComClient={freeComClient}
                        Link={Link}
                        index={index}
                        clientReqs4In={clientReqs4In}
                        currentUserProfile={currentUserProfile}
                        minusDayWorkerDayWage={minusDayWorkerDayWage}
                        enterDate={enterDate}
                        loadingDelete={loadingDelete}
                        deleteDisable={deleteDisable}
                        setConfirmOpen={setConfirmOpen}
                        companyName={companyName}
                      />
                    </Item.Description>
                    <br />
                  </>
                }
                {openDetail && 
                  <>
                    <OpenDetailBasicInfoListItem4In
                      childSupportFundPerson={childSupportFundPerson}
                      youthSupportFundPerson={youthSupportFundPerson}
                      replacedWorkerFundPerson={replacedWorkerFundPerson}
                      isDayWorker={isDayWorker}
                      isTimeWorker={isTimeWorker}
                      daylyTimePay={daylyTimePay}
                      Item={Item}
                      decryptoClientReq={decryptoClientReq}
                      isExceptTimeLawWorker={isExceptTimeLawWorker}
                      numOfWorkers={numOfWorkers}
                      enterDate={enterDate}
                      numberToCommaString={numberToCommaString}
                      daylyPay={daylyPay}
                      retiredDate={retiredDate}
                      formatDateWithMoment={formatDateWithMoment}
                      totalMonthWageAmount={totalMonthWageAmount}
                      roundUp2={roundUp2}
                      deductWage={deductWage}
                      calHourLowestWage={calHourLowestWage}
                      fixedMonthRealWorkingHoursETL={fixedMonthRealWorkingHoursETL}
                      isEtcWorker={isEtcWorker}
                      roundUp={roundUp}
                      mTPCTime={mTPCTime}
                    />
                    <OpenDetailAnnualLeavesListItem4In
                      afterRetireAgainContractStartDate={afterRetireAgainContractStartDate}
                      overFiveStartDay={overFiveStartDay}
                      thisYear={thisYear}
                      daylyPay={daylyPay}
                      daylyTimePay={daylyTimePay}
                      week14OverBLTime={week14OverBLTime}
                      authLevel={authLevel}
                      numOfWorkers={numOfWorkers}
                      Item={Item}
                      isExceptTimeLawWorker={isExceptTimeLawWorker}
                      decryptoClientReq={decryptoClientReq}
                      finBaseDate={finBaseDate}
                      annualLeaves={annualLeaves}
                      finBaseMonthDay={finBaseMonthDay}
                      anuualLeaveEnterDate={anuualLeaveEnterDate}
                      underOneYearWorkPeriod={underOneYearWorkPeriod}
                      absentDaysYearCntForALF={absentDaysYearCntForALF}
                      enterDate={enterDate}
                      workerId={workerId}
                      secondYearForAnnualLeave={secondYearForAnnualLeave}
                      usedAnnualLeavesInLastYearSumF={usedAnnualLeavesInLastYearSumF}
                    />
                  </>
                }
              </Item.Content>
            </Item>
          </Item.Group>
        </Segment>
        {openDetail && 
          <Segment clearing>
            <JudgeDayWorkerListItem4In 
              Label={Label}
              clientReq4In={clientReq4In}
              lowWageOKComment={lowWageOKComment}
              lowWageViolationDashboardComment={lowWageViolationDashboardComment}
              authLevel={authLevel}
              numberToCommaString={numberToCommaString}
              roundUp={roundUp}
              restOKComment={restOKComment}
              restViolationDashboardComment={restViolationDashboardComment}
            />
            <InsuranceListItem4In 
              authLevel={authLevel}
              decryptoClientReq={decryptoClientReq}
              Item={Item}
              currentUserProfile={currentUserProfile}
              numberToCommaString={numberToCommaString}
              InsureInReportButton={InsureInReportButton}
              clientReq4In={clientReq4In}
              workerId={workerId}
              formatDateWithMoment={formatDateWithMoment}
              InsureOutReportButton={InsureOutReportButton}
            />
          </Segment>
        }
        <CustomConfirm 
          title="삭제 확인" // 제목을 적절하게 설정하세요.
          content="해당 DATA가 모두 삭제됩니다. 진짜 삭제하시겠습니까?"
          open={confirmOpen}
          onCancel={() => setConfirmOpen(false)}
          onConfirm={handleDeleteToggle}
        />
      </Segment.Group>
		</>
	)
}
