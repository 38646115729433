import React from 'react';
import { useDispatch } from 'react-redux';
import { Icon, Table } from 'semantic-ui-react';
import { openModal } from '../../../../../app/common/modals/modalReducer';
import { josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLCHeadWageTable({
	worker,
	companyName,
	handelInputChange,
	thisYear,
	thisMonth,
	isExceptTimeLawWorker,
	isBizWorker,
}) {
	const dispatch = useDispatch();

	const companyUn = josaUnType(companyName);

	function headText(worker) {
		const text = worker + '의 임금명세서';
		return text;
	}

	function bizWorkerHeadText(worker) {
		const text = worker + '의 사업소득금액명세서';
		return text;
	}

	function payPaperIntroCYTOpen() {
		dispatch(openModal({ modalType: 'PayPaperIntro' }));
	}

	function payPaperLaborContractCYTOpen() {
		dispatch(openModal({ modalType: 'PayPaperLaborContract' }));
	}

	// const nextCopyMonthNum = +thisMonth + 1
	// const nextCopyMonthString = "0" + String(nextCopyMonthNum)

	return (
		<Table.Header>
			{isBizWorker ? null : (
				<Table.Row>
					<Table.HeaderCell colSpan='4'>
						<span
							style={{ color: 'red', cursor: 'pointer' }}
							onClick={() => payPaperIntroCYTOpen()}>
							<Icon size='big' name='youtube' />
							임금명세서 기초설명 1
						</span>
					</Table.HeaderCell>
					<Table.HeaderCell colSpan='5'>
						<span
							style={{ color: 'red', cursor: 'pointer' }}
							onClick={() => payPaperLaborContractCYTOpen()}>
							<Icon size='big' name='youtube' />
							임금명세서 기초설명 2
						</span>
					</Table.HeaderCell>
				</Table.Row>
			)}
			<Table.Row>
				<Table.HeaderCell colSpan='9' textAlign='center'>
					<span style={{ textAlign: 'right', fontSize: '20px' }}>
						<input
							type='text'
							name='thisYear'
							style={{ width: '70px', fontSize: '25px', textAlign: 'right' }}
							value={thisYear}
							onChange={(e) => handelInputChange(e)}
						/>{' '}
						년
					</span>
					&nbsp;&nbsp;&nbsp;&nbsp;
					<span style={{ textAlign: 'right', fontSize: '20px' }}>
						<select
							test='File'
							name='thisMonth'
							style={{ width: '65px', fontSize: '25px', textAlign: 'right' }}
							defaultValue={thisMonth}
							onChange={(e) => handelInputChange(e)}
							autoComplete='off'>
							<option value='01'>01</option>
							<option value='02'>02</option>
							<option value='03'>03</option>
							<option value='04'>04</option>
							<option value='05'>05</option>
							<option value='06'>06</option>
							<option value='07'>07</option>
							<option value='08'>08</option>
							<option value='09'>09</option>
							<option value='10'>10</option>
							<option value='11'>11</option>
							<option value='12'>12</option>
						</select>
						&nbsp;월
					</span>
					<span
						style={{ textAlign: 'center', margin: '20px', fontSize: '20px' }}>
						{isBizWorker ? bizWorkerHeadText(worker) : headText(worker)}
					</span>
				</Table.HeaderCell>
			</Table.Row>
			{isBizWorker ? null : (
				<Table.Row>
					{isExceptTimeLawWorker ? (
						<Table.HeaderCell colSpan='9'>
							{`
                ${companyUn}
                ${worker}에게 근로기준법 제48조 제2항에 의거 아래와 같이 임금의 구성항목, 계산방법과 공제내역이 명시된 임금명세서를 교부하며, 귀하는 근로기준법 제63조에 의거 근로시간, 휴게, 휴일의 규정이 적용되지 않음을 안내해 드립니다., 귀하의 노고에 감사드립니다.
              `}
						</Table.HeaderCell>
					) : (
						<Table.HeaderCell colSpan='9'>
							{`
                ${companyUn}
                ${worker}에게 근로기준법 제48조 제2항에 의거 아래와 같이 임금의 구성항목, 계산방법과 공제내역이 명시된 임금명세서를 교부합니다. 귀하의 노고에 감사드립니다.
              `}
						</Table.HeaderCell>
					)}
				</Table.Row>
			)}
		</Table.Header>
	);
}
