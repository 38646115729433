import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Divider, Modal } from 'semantic-ui-react';
import {openModal} from '../../app/common/modals/modalReducer';

export default function UnauthModal({history, setModalOpen}) {

  const [open, setOpen] = useState(true);
  const {prevLocation} = useSelector(state => state.auth)
  const dispatch = useDispatch();

  function handleClose() {
    if (!history) {
      setOpen(false);
      setModalOpen(false);
      return;
    }
    if (history && prevLocation) {
      history.push(prevLocation.pathname);
    } else {
      history.push('/clientReqs4In');
    }
    setOpen(false);
  }

  function handleOpenLoginModal(modalType) {
    dispatch(openModal({modalType}));
    setOpen(false);
  }

  return (
    <Modal open = {open} size = 'mini' onClose={handleClose}>
      <Modal.Header content='로그인 또는 회원가입' />
      <Modal.Content>
        <p>회원은 "로그인" 비회원은 "회원가입" 클릭</p>
        <Button.Group widths = {4}>
          <Button fluid color='teal' content='로그인' onClick = {() => handleOpenLoginModal('LoginForm')}/>
          <Button.Or />
          <Button fluid color='green' content='회원가입' onClick = {() => handleOpenLoginModal('RegisterForm')}/>
        </Button.Group>
        <Divider /> 
        <div style={{textAlign : 'center'}}>
        <p>20년 경력 노무사 출신 개발자가 직접 개발한</p>
        <p>근로계약서, 임금명세서, 취업규칙 프로그램</p>
        <p>AI NOMUSA에 오신 것을 환영합니다.</p>

          {/* <Button onClick = {handleClose} content = 'Cancel' /> */}
        </div>
      </Modal.Content>
    </Modal>
  )
}
