import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function InsureRegister({
	MdSecurityUpdateGood,
	currentUserProfile,
}) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);
	const insureManageType = currentUserProfile?.insureManageType;

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	const handleAlert = () => {
		alert(
			'4대보험 자동신고 엑셀파일 다운기능은 별도의 유료 부과 서비스입니다.'
		);
	};

	return (
		<Menu.Item
			className='menu-item'
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}>
			{!isMobile && <MdSecurityUpdateGood className='icon-spacing' />}
			{insureManageType === '4대보험 자체관리' ||
			currentUserProfile?.authLevel >= 100 ? (
				<Dropdown
					pointing='top left'
					text='4대보험 신고'
					open={dropdownOpen}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item
							as={Link}
							to='/insureManage'
							text='월급직 4대보험신고'
						/>
						<hr />
						<Dropdown.Item
							as={Link}
							to='/paydocusDayWorkerTableReport'
							text='일용근로내역확인신고'
						/>
						<Dropdown.Item
							as={Link}
							to='/paydocusDayWorkerTableReportHP'
							text='일용직 국민/건강 신고'
						/>
					</Dropdown.Menu>
				</Dropdown>
			) : insureManageType === '4대보험 노무사 위탁관리' ? (
				<Dropdown
					pointing='top left'
					text='4대보험 신고 확인'
					open={dropdownOpen}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item
							as={Link}
							to='/insureManage'
							text='4대보험 입퇴사 신고 확인'
						/>
						<hr />
						<Dropdown.Item
							as={Link}
							to='/paydocusDayWorkerTableReportHP'
							text='일용직 국민/건강 신고 확인'
						/>
					</Dropdown.Menu>
				</Dropdown>
			) : (
				<Dropdown
					pointing='top left'
					text='4대보험 신고'
					open={dropdownOpen}
					onClick={handleDropdownToggle}>
					<Dropdown.Menu>
						<Dropdown.Item text='신고파일 엑셀다운' onClick={handleAlert} />
					</Dropdown.Menu>
				</Dropdown>
			)}
		</Menu.Item>
	);
}
