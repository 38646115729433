import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	dateDifference,
	getAgeFromSocialNumber,
	getAgeFromSocialNumberEnteredDate,
} from '../../../../app/common/util/util';
import { db } from '../../../../app/config/firebase';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { Modal } from 'semantic-ui-react';
import { useDispatch, useSelector } from 'react-redux';
import { clearPaydocu } from '../../../companyManage/companyActions';

export default function RegularWorkerListItemButton({
	eiOutType,
	lastPreventDateAvoidYes,
	childSupportFundPerson,
	youthSupportFundPerson,
	replacedWorkerFundPerson,
	decryptoClientReq,
	currentUserProfile,
	index,
	companyName,
	minusMonthWage,
	clientReq4In,
	Button,
	setConfirmOpen,
	freeComClient,
	clientReqs4In,
	Link,
	isEtcWorker,
	isExceptTimeLawWorker,
	openDetailHandler,
	loadingDelete,
	enterDate,
	retiredDate,
	numberToCommaString,
	deleteDisable,
	insureManageType,
}) {
	const history = useHistory();
	const dispatch = useDispatch();
	const { clientInfo, superAdminForFreeTrue } = useSelector(
		(state) => state.companyReducer
	);
	const [isOutRequested, setIsOutRequested] = useState(false);
	const [isInRequested, setIsInRequested] = useState(false);
	const [isInResponsed, setIsInResponsed] = useState(false);
	const [isOutResponsed, setIsOutResponsed] = useState(false);
	const [loadingDeleteIn, setLoadingDeleteIn] = useState(false);
	const [loadingDeleteOut, setLoadingDeleteOut] = useState(false);
	const [showModal, setShowModal] = useState(false);

	const workerName = decryptoClientReq.worker?.name;
	const authLevel = clientInfo?.authLevel
		? clientInfo?.authLevel
		: currentUserProfile?.authLevel;
	const ceoName = clientInfo?.ceoName
		? clientInfo?.ceoName
		: currentUserProfile?.ceoName;
	const docId = clientReq4In?.id;
	const workerDocRef = doc(db, 'workers', docId);

	useEffect(() => {
		let isMounted = true; // 마운트 상태를 추적하는 변수

		const fetchData = async () => {
			const docSnap = await getDoc(workerDocRef);
			if (docSnap.exists() && isMounted) {
				// isMounted가 true인 경우에만 상태 업데이트
				const data = docSnap.data();
				setIsOutRequested(data.retireInfo?.isInsureOutRequest || false);
				setIsInRequested(data.isInsureInRequest || false);
				setIsInResponsed(data.insureInResponse || false);
				setIsOutResponsed(data.insureOutResponse || false);
			}
		};

		fetchData();

		return () => {
			isMounted = false; // 언마운트 시 isMounted를 false로 설정
		};
	}, [workerDocRef]);

	const handleAcquisitionClick = async () => {
		toggleInsureInRequest(!isInRequested);
	};

	const toggleInsureInRequest = async (value) => {
		setLoadingDeleteIn(true);

		try {
			await updateDoc(workerDocRef, {
				isInsureInRequest: value,
				isInsureInRequestDate: new Date(),
			});

			setIsInRequested(value);

			let msg = '';
			if (insureManageType === undefined || insureManageType === '') {
				msg =
					'4대보험 자체 자동 신고를 위한 데이터 만들기 기능 또는 노무사 신고 대행 요청은 별도의 유료 서비스입니다.';
			} else if (
				insureManageType === '4대보험 노무사 위탁관리' ||
				insureManageType === '4대보험 최상인업노무법인 위탁관리'
			) {
				msg = value
					? `${workerName}의 4대보험 취득신고 요청이 완료됐습니다. 익일 처리 예정이오니 변동사항 있으시면 요청을 철회하세요.`
					: `${workerName}의 4대보험 취득신고 요청이 철회되었습니다.`;
			} else if (insureManageType === '4대보험 자체관리') {
				msg = value
					? `${workerName}의 4대보험 취득신고 Data가 생성되었습니다. 왼쪽 사이드 바의 "4대보험 신고" 메뉴를 이용하세요.`
					: `${workerName}의 4대보험 취득신고 Data가 삭제되었습니다.`;
			}
			alert(msg);
		} catch (error) {
			console.error('Error updating document: ', error);
		}

		setLoadingDeleteIn(false);
	};

	const toggleInsureOutRequest = async (value) => {
		setLoadingDeleteOut(true);

		try {
			await updateDoc(workerDocRef, {
				isInsureOutRequest: value,
				isInsureOutRequestDate: new Date(),
				'retireInfo.isInsureOutRequest': value,
			});

			let msg = '';
			if (insureManageType === '') {
				msg =
					'4대보험 자체 자동 신고를 위한 데이터 만들기 기능 또는 노무사 신고 대행 요청은 별도의 유료 서비스입니다.';
			} else if (
				insureManageType === '4대보험 노무사 위탁관리' ||
				insureManageType === '4대보험 최상인업노무법인 위탁관리'
			) {
				msg = value
					? `${workerName}의 4대보험 상실신고 요청이 완료됐습니다. 익일 처리 예정이오니 변동사항 있으시면 요청을 철회하세요.`
					: `${workerName}의 4대보험 상실신고 요청이 철회되었습니다.`;
			} else if (insureManageType === '4대보험 자체관리') {
				msg = value
					? `${workerName}의 4대보험 상실신고 Data가 생성되었습니다. 왼쪽 사이드 바의 "4대보험 신고" 메뉴를 이용하세요.`
					: `${workerName}의 4대보험 상실신고 Data가 삭제되었습니다.`;
			}
			alert(msg);

			!value &&
				alert(
					'퇴직사유 등만 수정하셔도 퇴사월 급여작업에서 다시 퇴직정산을 하셔야 합니다.'
				);
			!value &&
				alert(
					'수정사항 반영을 위해 보라색 급여(수정/복사)버튼 클릭후 퇴직정산을 다시 실행해주세요.'
				);
		} catch (error) {
			console.error('Error updating document: ', error);
		}

		setLoadingDeleteOut(false);
		setShowModal(false);
	};

	const handleRequestClick = async () => {
		if (isOutRequested) {
			toggleInsureOutRequest(false);
		} else {
			setShowModal(true);
		}
	};

	function manageClientReq4InHnadler(id) {
		history.push(`/manageClientReq4In/${id}`);
	}

	function manageClientNewWageHnadler(id) {
		dispatch(clearPaydocu());
		history.push(`/clientRDWageTable/${id}`);
	}

	const youthSupportFundText =
		(lastPreventDateAvoidYes === null || lastPreventDateAvoidYes) &&
		youthSupportFundPerson === '2024 청년 일자리 도약 장려금 후보'
			? '청년 장려금 2년간 1200만원 대상후보'
			: (lastPreventDateAvoidYes !== null || !lastPreventDateAvoidYes) &&
			  youthSupportFundPerson === '2024 청년 일자리 도약 장려금 후보'
			? '입사일 현재 청년 장려금 대상자이지만 감원방지기간으로 제외'
			: null;
	const replacedWorkerFundText =
		(lastPreventDateAvoidYes === null || lastPreventDateAvoidYes) &&
		replacedWorkerFundPerson === '육아 관련 대체인력지원금 후보'
			? '대체인력 최대 1200만원 지원금 대상 후보'
			: (lastPreventDateAvoidYes !== null || !lastPreventDateAvoidYes) &&
			  replacedWorkerFundPerson === '육아 관련 대체인력지원금 후보'
			? '입사일 현재 대체인력 지원금 대상자이지만 감원방지기간으로 제외'
			: null;
	const childSupportFundText =
		childSupportFundPerson === '육아휴직(단축) 등 사업주 지원금 대상'
			? childSupportFundPerson
			: null;

	const hirePeriod =
		retiredDate !== '재직자' ? dateDifference(enterDate, retiredDate) : null;

	const today = moment();

	const childLeaveOfAbsenceStartDate =
		decryptoClientReq?.childLeaveOfAbsenceStartDate
			? moment(decryptoClientReq?.childLeaveOfAbsenceStartDate).format(
					'YYYY-MM-DD'
			  )
			: null;
	const childLeaveOfAbsenceEndDate =
		decryptoClientReq?.childLeaveOfAbsenceEndDate
			? moment(decryptoClientReq?.childLeaveOfAbsenceEndDate).format(
					'YYYY-MM-DD'
			  )
			: null;

	const childShortTimeStartDate = decryptoClientReq?.childShortTimeStartDate
		? moment(decryptoClientReq?.childShortTimeStartDate).format('YYYY-MM-DD')
		: null;
	const childShortTimeEndDate = decryptoClientReq?.childShortTimeEndDate
		? moment(decryptoClientReq?.childShortTimeEndDate).format('YYYY-MM-DD')
		: null;

	const againContractStartDate = decryptoClientReq?.againContractStartDate
		? moment(decryptoClientReq?.againContractStartDate).format('YYYY-MM-DD')
		: null;
	const contractEndDate = decryptoClientReq?.contractEndDate
		? moment(decryptoClientReq?.contractEndDate).format('YYYY-MM-DD')
		: null;
	const isContractWorker = contractEndDate
		? `계약직[${
				againContractStartDate ? againContractStartDate : enterDate
		  } ~ ${contractEndDate}]`
		: null;

	const afterRetireAgainContractStartDate =
		decryptoClientReq?.afterRetireAgainContractStartDate
			? moment(decryptoClientReq?.afterRetireAgainContractStartDate).format(
					'YYYY-MM-DD'
			  )
			: null;
	const afterRetireAgainContractEndDate =
		decryptoClientReq?.afterRetireAgainContractEndDate
			? moment(decryptoClientReq?.afterRetireAgainContractEndDate).format(
					'YYYY-MM-DD'
			  )
			: null;
	const isOldContractWorker =
		decryptoClientReq?.contractOldWorker === '정년이후 촉탁계약직' &&
		afterRetireAgainContractStartDate !== null
			? `촉탁 계약직[${afterRetireAgainContractStartDate}~${afterRetireAgainContractEndDate}]`
			: null;

	const maternityLeaveStartDate = decryptoClientReq?.maternityLeaveStartDate
		? moment(decryptoClientReq?.maternityLeaveStartDate).format('YYYY-MM-DD')
		: null;
	const maternityLeaveEndDate = decryptoClientReq?.maternityLeaveEndDate
		? moment(decryptoClientReq?.maternityLeaveEndDate).format('YYYY-MM-DD')
		: null;
	const isTodayInMaternityRange = decryptoClientReq?.maternityLeaveStartDate
		? today.isBetween(
				decryptoClientReq?.maternityLeaveStartDate,
				decryptoClientReq?.maternityLeaveEndDate,
				'days',
				'[]'
		  )
		: null;
	const isTodayInMaternityRangePerson = isTodayInMaternityRange
		? `출산전후휴가자 [${maternityLeaveStartDate}~${maternityLeaveEndDate}]`
		: null;

	const isTodayInChildLeaveRange =
		decryptoClientReq?.childLeaveOfAbsenceStartDate
			? today.isBetween(
					decryptoClientReq?.childLeaveOfAbsenceStartDate,
					decryptoClientReq?.childLeaveOfAbsenceEndDate,
					'days',
					'[]'
			  )
			: null;
	const isTodayInChildLeaveRangePerson = isTodayInChildLeaveRange
		? `육아휴직자 [${childLeaveOfAbsenceStartDate}~${childLeaveOfAbsenceEndDate} ]`
		: null;

	const isTodayInChildShortTimeRange =
		decryptoClientReq?.childShortTimeStartDate
			? today.isBetween(
					decryptoClientReq?.childShortTimeStartDate,
					decryptoClientReq?.childShortTimeEndDate,
					'days',
					'[]'
			  )
			: null;
	const isTodayInChildShortTimeRangePerson = isTodayInChildShortTimeRange
		? `육아기 근로시간 단축 사용자 [${childShortTimeStartDate}~${childShortTimeEndDate}]`
		: null;

	const netAge = getAgeFromSocialNumber(
		decryptoClientReq.workerSocialNumberFront
	); //오늘날짜 만나이
	const netAgeEntered = getAgeFromSocialNumberEnteredDate(
		decryptoClientReq.workerSocialNumberFront,
		enterDate
	); //입사일 만나이

	const insureOutResponseDate = decryptoClientReq?.insureOutResponseDate
		? moment(decryptoClientReq?.insureOutResponseDate).format('YYYY-MM-DD')
		: null;
	const insureInResponseDate = decryptoClientReq?.insureInResponseDate
		? moment(decryptoClientReq?.insureInResponseDate).format('YYYY-MM-DD')
		: null;

	// const isAgent = isAgentF(currentUserProfile?.agentType, currentUserProfile?.authLevel)

	const freeOpenCopyYesOrNot = superAdminForFreeTrue
		? false
		: freeComClient(authLevel, ceoName) && clientReqs4In.length === 1
		? false
		: freeComClient(authLevel, ceoName)
		? true
		: false;

	const before65EnteredWorker =
		netAgeEntered < 65 && netAge >= 65 ? true : false;

	const textInsureInRequest =
		insureManageType === '4대보험 노무사 위탁관리' ||
		insureManageType === '4대보험 최상인업노무법인 위탁관리'
			? '4대보험 취득신고 노무사 대행 요청'
			: insureManageType === '4대보험 자체관리'
			? '4대보험 취득신고 Data 생성'
			: '4대보험 취득신고 Data 생성 또는 노무사 대행 요청';
	const textInsureOutRequest =
		insureManageType === '4대보험 노무사 위탁관리' ||
		insureManageType === '4대보험 최상인업노무법인 위탁관리'
			? '4대보험 퇴사신고 노무사 대행 요청'
			: insureManageType === '4대보험 자체관리'
			? '4대보험 퇴사신고 Data 생성'
			: '4대보험 퇴사신고 Data 생성 또는 노무사 대행 요청';

	return (
		<>
			{decryptoClientReq?.retiredDate ? (
				decryptoClientReq?.hostUid !== currentUserProfile?.id ? (
					<strong style={{ color: 'darkGray' }}>
						<span style={{ color: 'blue', fontSize: '2' }}>[{index + 1}]</span>{' '}
						[회사명] : {companyName} , [직원명] :{' '}
						{decryptoClientReq.worker?.name}, &nbsp; [입사일]: {enterDate}{' '}
						&nbsp; [퇴사일]: {retiredDate} &nbsp; [퇴사사유]: {eiOutType} &nbsp;
						[재직기간]: {hirePeriod}
					</strong>
				) : (
					<strong style={{ color: 'darkGray' }}>
						<span style={{ color: 'blue', fontSize: '2' }}>[{index + 1}]</span>
						{decryptoClientReq.worker?.name} &nbsp; [입사일]: {enterDate} &nbsp;
						[퇴사일]: {retiredDate}&nbsp; [퇴사사유]: {eiOutType} &nbsp;
						[재직기간]: {hirePeriod}
					</strong>
				)
			) : (
				<>
					{minusMonthWage ? (
						decryptoClientReq?.hostUid !== currentUserProfile?.id ? (
							<strong>
								<span style={{ color: 'blue', fontSize: '2' }}>
									[{index + 1}]
								</span>
								[회사명] : {companyName}, [직원명] :{' '}
								{decryptoClientReq.worker?.name}, &nbsp; [입사일]: {enterDate},
								&nbsp;
								<span style={{ color: 'orange' }}>
									[2024년 최저임금 부족액]: (월){' '}
									{numberToCommaString(minusMonthWage)} 원&nbsp;
								</span>
								&nbsp;<span>만 {netAge}세</span>
								&nbsp;
								{before65EnteredWorker && (
									<span style={{ color: 'red' }}>
										, 만 {netAgeEntered}세[입사일 기준]
									</span>
								)}
								&nbsp;<span>{isContractWorker}</span>
								&nbsp;<span>{isOldContractWorker}</span>
								&nbsp;<span>{isTodayInMaternityRangePerson}</span>
								&nbsp;<span>{isTodayInChildLeaveRangePerson}</span>
								&nbsp;<span>{isTodayInChildShortTimeRangePerson}</span>
							</strong>
						) : (
							<strong>
								<span style={{ color: 'blue', fontSize: '2' }}>
									[{index + 1}]
								</span>
								{decryptoClientReq.worker?.name} &nbsp; [입사일]: {enterDate},
								&nbsp;
								<span style={{ color: 'orange' }}>
									[2024년 최저임금 부족액]: (월){' '}
									{numberToCommaString(minusMonthWage)} 원
								</span>
								&nbsp;<span>만 {netAge}세</span>
								&nbsp;
								{before65EnteredWorker && (
									<span style={{ color: 'red' }}>
										, 만 {netAgeEntered}세[입사일 기준]
									</span>
								)}
								&nbsp;<span>{isContractWorker}</span>
								&nbsp;<span>{isOldContractWorker}</span>
								&nbsp;<span>{isTodayInMaternityRangePerson}</span>
								&nbsp;<span>{isTodayInChildLeaveRangePerson}</span>
								&nbsp;<span>{isTodayInChildShortTimeRangePerson}</span>
							</strong>
						)
					) : decryptoClientReq?.hostUid !== currentUserProfile?.id ? (
						<strong>
							<span style={{ color: 'blue', fontSize: '2' }}>
								[{index + 1}]
							</span>
							[회사명] : {companyName}, [직원명] :{' '}
							{decryptoClientReq.worker?.name},&nbsp; [입사일]: {enterDate},
							{youthSupportFundText}&nbsp;
							{replacedWorkerFundText}&nbsp;
							{childSupportFundText}&nbsp; &nbsp;<span>만 {netAge}세</span>
							&nbsp;
							{before65EnteredWorker && (
								<span style={{ color: 'red' }}>
									, 만 {netAgeEntered}세[입사일 기준]
								</span>
							)}
							&nbsp;<span>{isContractWorker}</span>
							&nbsp;<span>{isOldContractWorker}</span>
							&nbsp;&nbsp;
							<span style={{ color: 'blue' }}>
								{isEtcWorker
									? '교대제 등'
									: isExceptTimeLawWorker
									? '감단직 등'
									: ''}
							</span>
							&nbsp;<span>{isTodayInMaternityRangePerson}</span>
							&nbsp;<span>{isTodayInChildLeaveRangePerson}</span>
							&nbsp;<span>{isTodayInChildShortTimeRangePerson}</span>
						</strong>
					) : (
						<strong>
							<span style={{ color: 'blue', fontSize: '2' }}>
								[{index + 1}]
							</span>
							{decryptoClientReq.worker?.name} &nbsp; [입사일]: {enterDate}{' '}
							&nbsp;
							<span style={{ color: 'blue', fontSize: '2' }}>
								{youthSupportFundText}&nbsp;
								{replacedWorkerFundText}&nbsp;
								{childSupportFundText}
							</span>
							&nbsp;<span>만 {netAge}세</span>
							&nbsp;
							{before65EnteredWorker && (
								<span style={{ color: 'red' }}>
									, 만 {netAgeEntered}세[입사일 기준]
								</span>
							)}
							&nbsp;<span>{isContractWorker}</span>
							&nbsp;<span>{isOldContractWorker}</span>
							&nbsp;&nbsp;
							<span style={{ color: 'blue' }}>
								{isEtcWorker
									? '교대제 등'
									: isExceptTimeLawWorker
									? '감단직 등'
									: ''}
							</span>
							&nbsp;<span>{isTodayInMaternityRangePerson}</span>
							&nbsp;<span>{isTodayInChildLeaveRangePerson}</span>
							&nbsp;<span>{isTodayInChildShortTimeRangePerson}</span>
						</strong>
					)}
				</>
			)}
			<div style={{ marginTop: '10px', marginBottom: '30px' }}>
				<Button
					loading={loadingDelete}
					disabled={
						superAdminForFreeTrue
							? false
							: deleteDisable || freeComClient(authLevel, ceoName)
					}
					onClick={() => setConfirmOpen(true)}
					color='red'
					floated='right'
					content='삭제'
				/>
				<Button
					as={Link}
					to={`/clientRDLawBaseWorkerInfo/${clientReq4In?.id}`}
					color='yellow'
					floated='right'
					content='근로자명부'
				/>

				<Button
					as={Link}
					to={`/clientReqs4In/${clientReq4In?.id}`}
					color='teal'
					floated='right'
					content='근로계약서'
				/>
				<Button
					as={Link}
					to={`/clientRDLaborWageContract/${clientReq4In?.id}`}
					color='teal'
					floated='right'
					content='임금계약서'
				/>
				<Button
					loading={loadingDelete}
					onClick={() => openDetailHandler()}
					color='teal'
					floated='right'
					content='상세정보'
				/>
				<>
					<div className='tooltip'>
						<Button
							disabled={freeOpenCopyYesOrNot}
							onClick={() => manageClientReq4InHnadler(clientReq4In?.id)}
							color='purple'
							floated='right'
							content='인사정보(수정/복사)'
						/>
						{
							<span className='tooltiptext'>
								[인사정보]의 이름을 바꾸면 복사됩니다.(근로조건이 같은
								신입등록시 복사사용).
							</span>
						}
					</div>
					<div className='tooltip'>
						<Button
							onClick={() => manageClientNewWageHnadler(clientReq4In?.id)}
							color='purple'
							floated='right'
							content='개인급여작업'
						/>
						{
							<span className='tooltiptext'>
								[급여작업]개인별로 입퇴사시, 산전후휴가시, 임금인상시 등
								일할계산이 필요한 경우에 사용하세요.
							</span>
						}
					</div>
				</>
			</div>
			<>
				{decryptoClientReq?.retiredDate ? (
					<div
						style={{
							marginTop: '55px',
							marginBottom: '70px',
							textAlign: 'right',
						}}>
						{!clientReq4In?.retireInfo?.isRetired ? (
							<span style={{ color: 'blue', fontSize: '2' }}>
								퇴직월 마감, 상실사유 입력시 퇴직신고버튼 활성화됨.
							</span>
						) : (
							<div>
								<Button
									loading={loadingDeleteOut}
									onClick={handleRequestClick}
									color={
										!isOutResponsed && isOutRequested
											? 'red'
											: isOutResponsed
											? 'grey'
											: 'yellow'
									}
									floated='right'
									content={
										!isOutResponsed && isOutRequested
											? insureManageType === '4대보험 노무사 위탁관리' ||
											  insureManageType === '4대보험 최상인업노무법인 위탁관리'
												? '상실신고 요청 취소'
												: insureManageType === '4대보험 자체관리'
												? '상실신고 Data 삭제'
												: '4대보험 상실신고 Data 생성 또는 노무사 대행 요청'
											: isOutResponsed
											? `취득신고 완료(${insureOutResponseDate})`
											: textInsureOutRequest
									}
									disabled={isOutResponsed} // insureOutResponse가 true일 경우 버튼을 비활성화
								/>
								<Modal open={showModal} onClose={() => setShowModal(false)}>
									<Modal.Header>퇴사월 급여작업 확인</Modal.Header>
									<Modal.Content>
										<p>
											퇴사월 급여작업을 마무리하셨습니까?(퇴직 사유 등
											정보수정시에도 퇴사월 급여작업에 퇴직정산이 필요합니다.)
										</p>
									</Modal.Content>
									<Modal.Actions>
										<Button
											onClick={() => toggleInsureOutRequest(true)}
											color='green'>
											Yes
										</Button>
										<Button onClick={() => setShowModal(false)} color='red'>
											No
										</Button>
									</Modal.Actions>
								</Modal>
							</div>
						)}
					</div>
				) : (
					<div
						style={{
							marginTop: '55px',
							marginBottom: '70px',
							textAlign: 'right',
						}}>
						{decryptoClientReq?.retiredDate ? null : (
							<Button
								loading={loadingDeleteIn}
								onClick={handleAcquisitionClick}
								color={
									!isInResponsed && isInRequested
										? 'green'
										: isInResponsed
										? 'grey'
										: 'blue'
								}
								floated='right'
								content={
									!isInResponsed && isInRequested
										? insureManageType === '4대보험 노무사 위탁관리' ||
										  insureManageType === '4대보험 최상인업노무법인 위탁관리'
											? '취득신고 요청 취소'
											: insureManageType === '4대보험 자체관리'
											? '취득신고 Data 삭제'
											: '4대보험 취득신고 Data 생성 또는 노무사 대행 요청'
										: isInResponsed
										? `취득신고 완료(${insureInResponseDate})`
										: textInsureInRequest
								}
								disabled={isInResponsed} // insureOutResponse가 true일 경우 버튼을 비활성화
							/>
						)}
					</div>
				)}
			</>
		</>
	);
}
