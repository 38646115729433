import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Divider, Dropdown, Image, Menu } from 'semantic-ui-react';
import { signOutFirebase } from '../../app/firestore/firebaseService';
import { clearClientReqs4In } from '../clientsRequest/clientReq4In/clientReqActions4In';

export default function SignedInMenu() {
  const history = useHistory();
  const dispatch = useDispatch();
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  
  const {currentUserProfile} = useSelector(state => state.profile);
  const isWorker = currentUserProfile?.isWorker
  const workerName = currentUserProfile?.workerName;

  
  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  async function handleSignOut () {
    try {
      history.push('/');
      dispatch(clearClientReqs4In());
      await signOutFirebase();
      history.go(0);
    } catch (error) {
      toast.error(error.message);
      console.log(error.message)
    }
  }

  return (
    <Menu.Item className="menu-item" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
    {!isMobile && <Image
        avatar 
        spaced='right' 
        src={currentUserProfile?.photoURL || '/assets/user.png' } />}
      <Dropdown 
        pointing='top left' 
        text={isWorker ? workerName : currentUserProfile?.companyName}
        open={dropdownOpen}
        onClick={handleDropdownToggle}
      >
        <Dropdown.Menu>
          <Dropdown.Item as={Link} to={`/profile/${currentUserProfile?.id}`} 
            text='회원정보' 
            icon='user' />
          <Dropdown.Item as={Link} to={`/account`} 
            text='비밀번호 변경' 
            icon='user secret' />
          <Divider />
          {isWorker ? null : 
            <>
              {/* <Dropdown.Item as={Link} to={`/programUseContract/${currentUserProfile?.id}`} 
                text='AI NOMUSA 계약서' 
                icon='won sign' />
              <Divider />
              <Dropdown.Item as={Link} to={`/cmsProgramContract/${currentUserProfile?.id}`} 
                text='자동이체 신청서' 
                icon='won sign' />
              <Divider /> */}
              <Dropdown.Item as={Link} to={`/supportFundContract/${currentUserProfile?.id}`} 
                text='고용안정 지원금 계약서' 
                icon='won sign' />
              <Divider />
            </>
            
          }
          <Dropdown.Item onClick={handleSignOut} 
            text='로그아웃' 
            icon='power' />        
        </Dropdown.Menu>
      </Dropdown>
    </Menu.Item>
  )
}
