import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Dropdown, Menu } from 'semantic-ui-react';

export default function NomuWorksMenu({ GrUserAdmin, isSuperAdmin }) {
	const [dropdownOpen, setDropdownOpen] = useState(false);
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

	useEffect(() => {
		const handleResize = () => {
			setIsMobile(window.innerWidth < 1000);
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	const handleMouseOver = () => {
		setDropdownOpen(true);
	};

	const handleMouseLeave = () => {
		setDropdownOpen(false);
	};

	const handleDropdownToggle = () => {
		setDropdownOpen(!dropdownOpen);
	};

	return (
		<Menu.Item
			className='menu-item'
			onMouseOver={handleMouseOver}
			onMouseLeave={handleMouseLeave}>
			{!isMobile && <GrUserAdmin className='icon-spacing' />}
			<Dropdown
				pointing='top left'
				text='관리자'
				open={dropdownOpen}
				onClick={handleDropdownToggle}>
				<Dropdown.Menu>
					<Dropdown.Item as={Link} to='/companies' text='회원사관리' />
					{isSuperAdmin && (
						<Dropdown.Item
							as={Link}
							to='/updateCompanyInfo'
							text='직원정보의 회사 수정'
						/>
					)}
					<Dropdown.Item as={Link} to='/competencyManage' text='역량관리' />
				</Dropdown.Menu>
			</Dropdown>
		</Menu.Item>
	);
}
