import { getFirestore, doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../../app/config/firebase'; // Assuming you export your auth instance from this file

import { Form, Formik } from 'formik';
import React from 'react';
import ModalWrapper from '../../app/common/modals/ModalWrapper';
import * as Yup from 'yup';
import MyTextInput from '../../app/common/form/MyTextInput';
// import { Button, Divider, Label } from 'semantic-ui-react';
import { Button, Label } from 'semantic-ui-react';
import { useHistory } from 'react-router-dom';

import { useDispatch } from 'react-redux';
import { closeModal } from '../../app/common/modals/modalReducer';
import { delay } from '../../app/common/util/util';
import PasswordReset from './PasswordReset';



export default function LoginForm() {
  const dispatch = useDispatch();
  const history = useHistory();

  return (
    <ModalWrapper size='mini'header="AI NOMUSA로그인">
      <Formik
        initialValues={{email: '', password: ''}}
        validationSchema={Yup.object({
          email: Yup.string().required().email(),
          password: Yup.string().required()
        })}
        onSubmit={async (values, {setSubmitting, setErrors}) => {
          try {
            await delay(1000);
            // Use signInWithEmailAndPassword from 'firebase/auth' instead of a custom signInWithEmail
            const userCredential = await signInWithEmailAndPassword(auth, values.email, values.password);
            const user = userCredential.user;
            // Use getFirestore to obtain the Firestore instance
            const db = getFirestore();
            await setDoc(doc(db, 'users', user.uid), {
              lastSignInTime: serverTimestamp() // Use serverTimestamp from 'firebase/firestore'
            }, { merge: true });
            setSubmitting(false);
            dispatch(closeModal());
            history.push('/');
          } catch (error) {
            setErrors({ auth: 'Problem with username or password' });
            setSubmitting(false);
          }
        }}
      >
        {({isSubmitting, isValid, dirty, errors}) => (
          <Form className='ui form'>
            <MyTextInput name='email' placeholder='Email Adress' autoComplete='off'/>
            <MyTextInput name='password' placeholder='password' type="password" autoComplete='off'/>
            {errors.auth && <Label basic color='red' style={{marginBottom: 10}} content={errors.auth} />}
            <Button 
              loading={isSubmitting}
              disabled={!isValid || !dirty || isSubmitting}
              type='submit'
              fluid
              size='large'
              content='Login'
            />
            
            {/* <Divider horizontal>Or</Divider>
            <SocialLogin /> */}
          </Form>
        )}
      </Formik>
      <br />
      <hr />
      <PasswordReset />
    </ModalWrapper>
  )
}
