import React from 'react';
import { FormField, Label } from 'semantic-ui-react';
import { useField } from 'formik';

export default function MyNumberInputWorkers({
	label,
	setNumOfWorkers,
	...props
}) {
	const [field, meta, helpers] = useField(props.name); // props에서 name 속성만 전달합니다.

	const handleChange = (e) => {
		const { value } = e.target;
		const number = parseInt(value, 10); // 입력값을 정수로 변환합니다.

		// 입력값이 숫자이고, NaN이 아닌 경우에만 값을 업데이트합니다.
		if (!isNaN(number)) {
			helpers.setValue(number); // Formik의 setValue를 사용하여 값을 업데이트합니다.
			setNumOfWorkers(number); // 부모 컴포넌트에 정의된 상태 업데이트 함수를 호출합니다.
		}
	};

	return (
		<FormField error={meta.touched && !!meta.error}>
			<label>{label}</label>
			<input
				{...field}
				{...props}
				value={field.value || ''}
				onChange={handleChange} // 변경된 handleChange 함수를 사용합니다.
				type='number' // 입력 필드를 숫자 유형으로 설정합니다.
			/>
			{meta.touched && meta.error ? (
				<Label basic color='red'>
					{meta.error}
				</Label>
			) : null}
		</FormField>
	);
}
