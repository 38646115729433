import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function RegularLaborContract() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI NOMUSA 5인 이상 정규직 근로계약서 기능 설명 동영상">
      <YouTube videoId='-ez8qcGjcXk' opts={opts} />
    </ModalWrapper>
  )
}
