import React, { useState, useMemo, useEffect } from 'react';
import '../Roe1GeneralRules.css'; // CSS 파일 불러오기

const RuleSection = ({
	number,
	title,
	content,
	isOpen,
	toggleOpen,
	amended,
}) => {
	const formatContent = (content) => {
		if (!content) return null; // content가 undefined일 경우 null 반환

		let mainCount = 0; // 0부터 시작하여 CSS에서 올바르게 매핑될 수 있도록 설정

		return content.map((item, index) => {
			if (Array.isArray(item)) {
				return (
					<ol key={index} type='1'>
						{item.map((subItem, subIndex) => (
							<li
								key={subIndex}
								className='rule-sub-item'
								data-index={subIndex}>
								{subItem}
							</li>
						))}
					</ol>
				);
			} else {
				const formattedItem = item;
				return (
					<li key={index} className='rule-item' data-index={mainCount++}>
						{formattedItem}
					</li>
				);
			}
		});
	};

	return (
		<div>
			<div className='jau__title sectionup' onClick={toggleOpen}>
				{`제${number}조 ${title}`}
			</div>
			{isOpen && (
				<div className='jau__contents'>
					<ul>{formatContent(content)}</ul>
					{amended && (
						<div className='no-print'>
							<ul>
								{amended.map((amendment, i) => (
									<li
										key={i}
										style={{
											color: amendment?.includes('개정') ? 'red' : 'black',
										}}>
										{amendment}
									</li>
								))}
							</ul>
						</div>
					)}
				</div>
			)}
		</div>
	);
};

export default function Roe7ReviseRules({
	company,
	// serviceStartDate,
	// profile,
	allOpen,
	companyUn,
	previousSectionCount1,
	previousSectionCount2,
	previousSectionCount3,
	previousSectionCount4,
	previousSectionCount5,
	previousSectionCount6,
	rlcExcuteDueDate,
}) {
	const [openSections, setOpenSections] = useState({});

	const sections = useMemo(() => {
		let baseSections = [
			{
				title: `대표위원의 권한위임`,
				content: [
					`노사 쌍방의 대표위원은 필요시 그 권한을 타위원에게 위임 할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `제3자의 배제`,
				content: [
					`${company}에 재직하고 있지 아니하는 자는 본 협의회의 설치 및 운영에 관여하지 못한다.`,
					`협의회의 개최 시 해당 위원 및 간사를 제외한 제3자의 참석을 금한다. 다만, 위원들의 협의 하에 필요한 담당자나, 관련사항 전문가를 참여시킬 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `신고의무사항`,
				content: [
					`협의회와 관련하여 고용노동부에 신고하여야 할 제반 사항은 사용자측에서 한다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `운영세칙`,
				content: [
					`협의회는 협의회운영 등과 관련된 사항에 대하여 운영세칙을 작성할 수 있다.`,
					`기타 필요한 사항은 별도로 정할 수 있다.`,
				],
			},
			{
				title: `규정외의 사항`,
				content: [
					`이 규정에 명시되지 않은 사항에 대해서는 법령 및 통상관례에 따른다.`,
				],
			},
			{
				title: `시행일`,
				content: [`본 규칙은 ${rlcExcuteDueDate}부터 시행한다.`],
			},
		];
		// if (['대한민국', '한국', '일본']?.includes(company)) {
		// 	const additionalSection = {
		// 		title: `소정근로시간`,
		// 		content: [
		// 			`사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 8시간을 원칙으로 하되, 단시간 사원의 경우 1일 8시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 40시간을 원칙으로 하되, 단시간 사원의 경우 1주 40시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`15세 이상 18세 미만인 사원의 소정근로시간은 아래와 같다.`,
		// 			[
		// 				`1일 소정근로시간은 휴게시간을 제외하고 7시간을 원칙으로 하되, 단시간 사원의 경우 1일 7시간을 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 				`1주 소정근로시간은 35시간을 원칙으로 하되, 단시간 사원의 경우 1주 35시간 한도로 근로계약에 의거 소정근로시간을 달리 정할 수 있다.`,
		// 			],
		// 			`1주 소정근로시간은 월요일의 시업시각부터 기산하고, 1월 소정근로시간은 매월 1일의 시업시각부터 기산한다.`,
		// 			`경영상 필요한 경우 근로계약서 또는 임금 계약서의 작성을 통하여 기존에 제1항 및 제2항의 규정의 적용받는 것과 달리 1일 또는 1주 소정근로시간을 약정할 수 있다.`,
		// 			`고용노동부장관의 승인을 받은 감시적, 단속적 근로에 종사하는 자(경비직, 시설관리직, 운전기사 등) 및 관리, 감독업무 또는 기밀 사무를 취급하는 자는 본 규칙에서 정한 근로시간, 휴게와 휴일에 관한 규정은 적용하지 아니한다.`,
		// 			`기타 필요한 사항은 별도로 정할 수 있다.`,
		// 		],
		// 	};
		// 	baseSections.splice(1, 0, additionalSection); // "목적" 다음에 "소정근로시간" 삽입
		// }

		const excludedCompanies = ['주식회사 허브601'];

		// 조건에 따라 섹션 제거
		if (excludedCompanies?.includes(company)) {
			// "title"이 여러 개일 때 제거할 섹션 목록
			const excludedTitles = ['영업비밀 관리위원회'];

			baseSections = baseSections?.filter(
				(section) => !excludedTitles?.includes(section.title)
			);
		}

		return baseSections;
	}, [company, rlcExcuteDueDate]);

	useEffect(() => {
		const newOpenSections = sections.reduce((acc, section) => {
			acc[section.title] = allOpen;
			return acc;
		}, {});
		setOpenSections(newOpenSections);
	}, [allOpen, sections]);

	const toggleSection = (section) => {
		setOpenSections((prevState) => ({
			...prevState,
			[section]: !prevState[section],
		}));
	};

	return (
		<>
			<div className='jang sectionup'>제7장 보 칙</div>
			{sections.map((section, index) => (
				<div key={index}>
					<RuleSection
						number={
							index +
							1 +
							previousSectionCount1 +
							previousSectionCount2 +
							previousSectionCount3 +
							previousSectionCount4 +
							previousSectionCount5 +
							previousSectionCount6
						}
						title={section?.title}
						content={section?.content}
						isOpen={!!openSections[section?.title]}
						toggleOpen={() => toggleSection(section?.title)}
						amended={section?.amended}
					/>
				</div>
			))}
		</>
	);
}
