import React from 'react';
import { josaGaType, josaUnType } from '../../../../../app/common/util/util';

export default function ClientReqDLCDWage({
	worker,
	companyName,
	contractOldWorker,
}) {
	const companyUn = josaUnType(companyName);
	const companyGa = josaGaType(companyName);
	const workerGa = josaGaType(worker);

	const contractOldWorkerText = `${companyUn} ${workerGa} 정년퇴직 후 촉탁 계약직으로 입사했기 때문에 고용상 연령차별금지 및 고령자고용촉진에 관한 법률 제21조에 의거 정년이후 퇴직금 계산을 위한 계속 근로 연수는 새로이 계산한다.`;

	return (
		<>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>
						제7조 근로계약서, 임금계약서, 임금명세서 교부의무 이행
					</strong>
				</span>
				<ul>
					<li>
						① {companyUn} 근로기준법 제17조에 따라 임금의 구성항목, 계산방법,
						지급방법, 소정근로시간, 휴일, 휴가가 명시된 근로계약서와
						임금계약서를 {worker}에게 교부한다.
					</li>
					<li>
						② {companyUn} 근로기준법 제48조 제2항 및 동법 시행령 제27조의 2에
						따른 임금의 구성항목, 계산방법, 근로자를 특정할 수 있는 정보,
						임금지급일, 임금총액, 임금의 구성항목별 금액, 임금의 구성항목별
						금액이 근무일수, 근무시간 등에 따라 달라지는 경우 그 계산방법을
						명시한 임금명세서를 {worker}에게 교부한다.
					</li>
					<li>
						③ {companyUn} {worker}에게 제1항과 제2항에 따른 근로계약서,
						임금계약서, 임금명세서의 교부를 서면으로 직접 교부하거나 동 법에
						따른 전자문서의 송부방법에 따라 전자문서로 송부한다.
					</li>
					<li>
						④ {companyUn} 제1항과 제2항에 따른 법정 서류 교부와 관련하여 ESG
						경영의 일환으로 종이문서 대신 전자문서를 교부하는 정책을 시행하고
						있으며, {workerGa} 본인의 PC나 휴대폰으로 언제 어디서든 로그인하여
						확인할 수 있는 SaaS 프로그램(AINOMUSA 시스템)을 통해 {worker}에게
						서류를 교부합니다. 또한, 해당 시스템 가입 시 개인정보 활용 동의 및
						이용약관 동의 절차를 {companyGa} 대리 수행할 수 있음에 동의하며,
						이러한 대리 수행 절차는 법적 요건에 맞게 이행됩니다.
					</li>
					<b>
						<li style={{ color: 'blue' }}>
							위 ④ 항 동의자 : {worker} (인 또는 sign)
						</li>
					</b>
				</ul>
			</div>
			<div className='jau__contents'>
				<span style={{ fontSize: '1rem' }}>
					<strong>제8조 임금 및 퇴직금</strong>
				</span>
				<ul>
					<li>
						① {companyUn} 근로기준법 제17조에 따른 {worker}의 임금의 구성항목,
						계산방법, 지급방법 및 퇴직급여보장법상의 퇴직금 등 임금 및 퇴직금과
						관련하여 본 근로계약서의 내용이 반영된 별도의 임금계약서를 {worker}
						에게 동 법률에 의거 교부하되, 두 당사자의 서명날인이 모두 있어야
						근로계약의 효력이 발생된다.
					</li>
					{contractOldWorker && <li>② {contractOldWorkerText}</li>}
				</ul>
			</div>
		</>
	);
}
