import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { Dropdown, Menu } from 'semantic-ui-react'


export default function PaydocuEvaluationMenu({VscHorizontalRule, authLevel, personalEvaluationYes}) {

  const history = useHistory();

  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 1000);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 1000);
    };
    
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMouseOver = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };

  const handleDropdownToggle = () => {
    setDropdownOpen(!dropdownOpen);
  };

  function reloadToPaydocusTableWorkerHr () {
    history.push('/paydocusTableWorkerHr');
  }

  function reloadToPaydocusTableHr () {
    history.push('/paydocusTableHr');
  }

  return (
    <>
      <Menu.Item className="menu-item" onMouseOver={handleMouseOver} onMouseLeave={handleMouseLeave}>
      {!isMobile && <VscHorizontalRule className="icon-spacing" />}
        <Dropdown style={{width:'160px'}} pointing='top left' text='인사고과 Review' open={dropdownOpen} onClick={handleDropdownToggle}>
          {authLevel <= 30 || !personalEvaluationYes ? 
            <Dropdown.Menu>
              <Dropdown.Item disabled text='프리미엄 서비스용' />
            </Dropdown.Menu>
          :
            <Dropdown.Menu>
              <Dropdown.Item onClick={() => reloadToPaydocusTableHr()}  text='업무수행평가월별 대장' />
              <Dropdown.Item onClick={() => reloadToPaydocusTableWorkerHr()}  text='업무수행평가(개인별)' />
            </Dropdown.Menu>
          }
        </Dropdown>
      </Menu.Item>
    </>
  )
}
