import React, { useEffect, useState } from 'react';
import {
	getAgeFromSocialNumber,
	getForeignerFromSocialNumber,
	netAge15To18F,
	selectValidVisaNoJobPay,
} from '../../../../../app/common/util/util';
import { toast } from 'react-toastify';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import MyDateInputEntered from '../../../../../app/common/form/MyDateInputEntered';
import { Icon, Label } from 'semantic-ui-react';
import { useDispatch } from 'react-redux';
import { openModal } from '../../../../../app/common/modals/modalReducer';
// import { foreignerVisaType, nationality, noJobPayInDetailJobType, noJobPayInMainJobType, noJobPayInSubJobType } from '../../../../../app/api/dropdownOption';
import {
	foreignerVisaType,
	nationality,
	noJobPayInDetailJobType,
} from '../../../../../app/api/dropdownOption';

export default function WorkerFormStep1({
	values,
	MyTextInput,
	workField,
	MyTextArea,
	setEnterDate,
	prevWorker,
}) {
	const dispatch = useDispatch();
	const [alertShown, setAlertShown] = useState(false);
	const [isAlertShown, setIsAlertShown] = useState(false);

	const isForiegner = getForeignerFromSocialNumber(
		values?.workerSocialNumberBack
	);
	const visaType = values?.foreigner?.visaType;
	const isOpenSelectNoJobPay =
		isForiegner && selectValidVisaNoJobPay?.includes(visaType);

	useEffect(() => {
		if (isOpenSelectNoJobPay && !isAlertShown) {
			alert(
				'우측 메뉴 (1-2) 4대보험 취득[필수] / 외국인 신업급여 임의가입을 선택할 수 있는 비자 종류입니다.'
			);
			setIsAlertShown(true);
		}
	}, [isOpenSelectNoJobPay, isAlertShown]);

	useEffect(() => {
		// 필수 입력란이 모두 채워졌는지 확인
		const copyWorkerAlert =
			values?.worker?.name && values?.worker?.name !== prevWorker;

		if (copyWorkerAlert && !alertShown && prevWorker !== undefined) {
			alert(
				'직원 복사시 "3. 임금정보 중 (3-2) 근로소득세 및 4대보험 설정" 정보는 다시 한번 확인해보세요.'
			);
			setAlertShown(true); // 다시 뜨지 않도록 상태 업데이트
		}
	}, [values?.worker?.name, prevWorker, alertShown]);

	useEffect(() => {
		// 필수 입력란이 모두 채워졌는지 확인
		const allRequiredFieldsFilled =
			values?.worker?.name &&
			values?.workerEnteredDate &&
			values?.workerSocialNumberFront &&
			values?.workerSocialNumberBack &&
			values?.workerDivisions &&
			values?.workerTitles &&
			values?.workField &&
			values?.workDetailField &&
			values?.worker?.address;

		if (allRequiredFieldsFilled && !alertShown) {
			alert(
				'필수 입력란을 모두 입력하셨습니다. "2. 근로시간정보" 단계로 진행하셔도 됩니다.'
			);
			setAlertShown(true); // 다시 뜨지 않도록 상태 업데이트
		}
	}, [
		values?.worker?.name,
		values?.workerEnteredDate,
		values?.workerSocialNumberFront,
		values?.workerSocialNumberBack,
		values?.workerDivisions,
		values?.workerTitles,
		values?.workField,
		values?.workDetailField,
		values?.worker?.address,
		alertShown,
	]);

	const netAge = getAgeFromSocialNumber(
		values?.workerSocialNumberFront,
		values?.workerSocialNumberBack
	);
	const netAge15To18 = netAge15To18F(netAge);

	function workerRegStep1YTOpen() {
		dispatch(openModal({ modalType: 'WorkerRegStep1' }));
	}

	function workerRegStep1_1YTOpen() {
		dispatch(openModal({ modalType: 'WorkerRegStep1_1' }));
	}

	useEffect(() => {
		if (netAge15To18) {
			toast(
				'15세이상 18세 미만 연소근로자로 연소근로자용 근로계약서가 작성됩니다.'
			);
			toast(
				'회사는 근로기준법 제66조에 따라 연소근로자의 연령을 증명하는 가족관계증명서와 친권자 또는 후견인의 취업동의서를 징구해야 합니다.'
			);
		}
	}, [netAge15To18]);

	return (
		<>
			<div
				style={{
					display: 'flex',
					alignItems: 'center',
					alignContent: 'flex-end',
					width: '100%',
					marginBottom: '30px',
					marginTop: '30px',
					fontSize: '17px',
				}}>
				<span style={{ color: 'red' }}>* </span>
				<span style={{ color: 'blue' }}>기본 인사정보 입력</span>
				<span style={{ fontSize: '8px' }}>
					(설명은 오른쪽 동영상을 참조하세요.)
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '10px',
						cursor: 'pointer',
					}}>
					<Label
						style={{ color: 'red' }}
						onClick={() => workerRegStep1_1YTOpen()}>
						<Icon size='big' name='youtube' />
						직원 복사 등록
					</Label>
				</span>
				<span
					style={{
						position: 'absolute',
						right: 0,
						marginRight: '150px',
						cursor: 'pointer',
					}}>
					<Label
						style={{ color: 'red' }}
						onClick={() => workerRegStep1YTOpen()}>
						<Icon size='big' name='youtube' />
						직원 등록 일반
					</Label>
				</span>
			</div>
			<div
				style={{
					display: 'flex',
					flexWrap: 'wrap',
					justifyContent: 'flex-start',
					alignItems: 'center',
					alignContent: 'flex-end',
				}}>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<>
								<span style={{ color: 'blue' }}>* (1) 직원이름</span>
								<span style={{ color: 'red' }}>
									[필수](변경시 신규 직원으로 복사됨)
								</span>
							</>
						}
						name='worker.name'
						placeholder='홍길동'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyDateInputEntered
						label={
							<span style={{ color: 'blue' }}>
								* (2) 입사일<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						name='workerEnteredDate'
						placeholderText='달력 사용(또는 0000-00-00  형식으로 입력요망)'
						timeFormat='HH:mm'
						timeCaption='time'
						dateFormat='yyyy-MM-dd'
						autoComplete='off'
						setEnteredDate={setEnterDate}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(3) 사번(있는 경우 입력하세요.)'
						name='worker.comNumber'
						placeholder='회사에서 부여한 사번이 있는 경우 그 사번을 쓰세요.'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<span style={{ color: 'blue' }}>
								* (4-1) 주민등록증 앞번호
								<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						name='workerSocialNumberFront'
						placeholder={'외국인은 등록번호 또는 국내거소신고번호'}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<span style={{ color: 'blue' }}>
								* (4-2) 주민등록증 뒷번호
								<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						// type='password'
						name='workerSocialNumberBack'
						placeholder={'주민등록번호 뒤 7자리'}
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<span style={{ color: 'blue' }}>
								* (5) 직위/직책<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						name='workerTitles'
						placeholder='직원 직위'
						autoComplete='off'
					/>
				</div>
				{isForiegner && (
					<>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label={
									<span>
										{' '}
										국적<span style={{ color: 'red' }}>[외국인 필수]</span>
									</span>
								}
								name='foreigner.nationality'
								autoComplete='off'
								options={nationality}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MySelectInput
								label={
									<span>
										비자종류<span style={{ color: 'red' }}>[외국인 필수]</span>
									</span>
								}
								placeholder='비자종류'
								name='foreigner.visaType'
								options={foreignerVisaType}
							/>
						</div>
						<div
							className='margin'
							style={{ width: '33%', marginRight: '3px' }}>
							<MyTextInput
								label={
									<span>
										영문이름<span style={{ color: 'red' }}>[외국인 필수]</span>
									</span>
								}
								name='foreigner.englishName'
								placeholder='여권상 영문 이름'
								autoComplete='off'
							/>
						</div>
					</>
				)}
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<span style={{ color: 'blue' }}>
								* (6) 부서명<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						name='workerDivisions'
						placeholder='부서'
						autoComplete='off'
					/>
				</div>

				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MySelectInput
						label={
							<span style={{ color: 'blue' }}>
								* (7) 직무<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						name='noJobPayDetail'
						// placeholder='회계 사무원, 판매 종사원, 경비원 등'
						autoComplete='off'
						options={noJobPayInDetailJobType}
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<span style={{ color: 'blue' }}>
								* (7-1) 구체적 담당업무
								<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						name='workDetailField'
						placeholder='최대한 자세히 기술'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<div className='tooltip'>
						<MySelectInput
							label={
								<span style={{ color: 'blue' }}>
									* (7-2) 간주근로여부
									<span style={{ color: 'red' }}>[필수]</span>
								</span>
							}
							name='workField'
							// placeholder='직종 선택.'
							options={workField}
						/>
						{
							<span className='tooltiptext'>
								"외근직"을 선택하시면 근로기준법 58조 1항의 간주근로계약서가
								만들어집니다.
							</span>
						}
					</div>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label={
							<span style={{ color: 'blue' }}>
								* (8) 직원 E-mail<span style={{ color: 'red' }}>[필수]</span>
							</span>
						}
						name='worker.email'
						placeholder='직원 이메일(교부의무 면제기능 사용시 필수)'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-1) 직원 급여통장 은행명'
						name='worker.bank'
						placeholder='급여은행'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-2) 직원 급여계좌'
						name='worker.bankAccount'
						placeholder='계좌번호'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='(8-3) 직원 연락처'
						name='worker.phone'
						placeholder='계좌번호'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='* (8-4) 직원 주소'
						name='worker.address'
						placeholder='서울시 00구 00동 00-00'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='* (8-5) 직원 자격(증) 사항(근로자명부용)'
						name='worker.ability'
						placeholder='사회복자사'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='* (8-6) 직원 최종학력(근로자명부용)'
						name='worker.schoolGrade'
						placeholder='대학교 졸업'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='* (8-7) 직원 경력(근로자명부용)'
						name='worker.companyExperience'
						placeholder='OO회사 3년'
						autoComplete='off'
					/>
				</div>
				<div className='margin' style={{ width: '33%', marginRight: '3px' }}>
					<MyTextInput
						label='* (8-7) 직원 병역 (근로자명부용)'
						name='worker.militaryService'
						placeholder='육군 만기전역'
						autoComplete='off'
					/>
				</div>
				<div
					className='margin'
					style={{ width: '99%', marginRight: '3px', marginTop: '50px' }}>
					<MyTextArea
						label='(10) 직원 특이사항 등 메모'
						name='worker.memo'
						placeholder='예, 육아휴직 기간(2021년 1월 1일~12월 31일, 가불금 30만원(2011년 3월 9일)..., '
						autoComplete='off'
						rows={3}
					/>
				</div>
			</div>
		</>
	);
}
