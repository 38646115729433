import React from 'react';

import {
  noJobPayYesTypeOption,
  noJobPayNoTypeOption,
  pensionOut4Type,
  healthOut4Type,
  noJobPayYesOrNo,
} from '../../../../../app/api/dropdownOption';
import MyTextArea from '../../../../../app/common/form/MyTextArea';
import MySelectInput from '../../../../../app/common/form/MySelectInput';
import { Icon, Label } from 'semantic-ui-react';
import { openModal } from '../../../../../app/common/modals/modalReducer';
import { useDispatch } from 'react-redux';

export default function CrwfWorkerRetireInfo42( {
  noJobPay, 
  setNoJobPay,
}) {
  const dispatch = useDispatch();

  function workerRegStep4_2YTOpen() {
    dispatch(openModal({modalType:'WorkerRegStep4_2'}))
  }
  return ( 
    <div style={{ display : 'flex', flexWrap : 'wrap', justifyContent: 'flex-start', alignItems : 'center', alignContent : 'flex-end'}}>
      <div style={{display: 'flex', alignItems : 'center', alignContent : 'flex-end', width : '100%', marginBottom : "30px", marginTop : "30px", fontSize: '17px'}}>
        <span style={{color : 'red'}}>* </span><span style={{color : 'blue'}}>실업급여/국민/건강 상실</span ><span style={{fontSize : '8px'}}>(설명은 오른쪽 동영상을 참조하세요.)</span>
        <span style={{position: "absolute", right: 0, marginRight:'10px', cursor:'pointer'}}><Label style={{color:'red'}} onClick={() => workerRegStep4_2YTOpen()}><Icon size='big' name='youtube' />실업급여/국민/건보 상실 설명</Label></span>
      </div>
      <div className='margin' style={{width : '24.5%', marginRight : '3px'}}>
          <MySelectInput
            onChangeCallback={setNoJobPay}
            label='실업급여 신청(필수)' 
            name='noJobPay' 
            options={noJobPayYesOrNo} 
          />
        </div>
        {
          noJobPay === '1. 실업급여 신청' ?
              <div className='margin' style={{width : '24.5%', marginRight : '3px'}}>
                <MySelectInput
                  label='실업급여 신청사유(필수)' 
                  name='noJobPayYesType' 
                  placeholder='권고사직 등' 
                  options={noJobPayYesTypeOption} 
                />
              </div>  
            :  
              <div className='margin' style={{width : '24.5%', marginRight : '3px'}}>
                <MySelectInput
                  label='실업급여 미신청사유(필수)' 
                  name='noJobPayNoType' 
                  placeholder='자발적 이직 등' 
                  options={noJobPayNoTypeOption} 
                />
              </div>
        }

        <div className='margin' style={{width : '24.5%', marginRight : '3px'}}>
          <MySelectInput
            label='국민연금 상실부호(필수)' 
            name='pensionOutType' 
            placeholder='퇴직/ 60세 도달 등' 
            options={pensionOut4Type} 
          />
        </div>
        <div className='margin' style={{width : '24.5%', marginRight : '3px'}}>
          <MySelectInput
            label='건강보험 상실부호(필수)' 
            name='healthOutType' 
            placeholder='퇴직/ 의료급여수급권자 등' 
            options={healthOut4Type} 
          />
        </div>

        <div className='margin' style={{width : '99%', marginRight : '3px'}}>
          <MyTextArea label='기타 메모 및 특이사항(선택)'
            name='comment4InsureOut'
            placeholder='기타 메모.'
            rows={2} 
          />
        </div>
    </div>
  )
}