// import { format } from 'date-fns';
import React from 'react';
import { format } from 'date-fns/esm';
import { Table} from 'semantic-ui-react'
// import { printSupportFundWarrant } from '../../../app/common/util/util';

export default function SupportFundWarrantContents({profile, nomusaCompanyName, nomusaName, nomusaPhone}) {
  let today = new Date(); 

  return (
  <>
  
    {/* <Button 
      color='blue' 
      style={{display: "inline", color: 'black', margin: '5px',}}
      onClick={() => printSupportFundWarrant()}
    >컨설팅 위임장 출력
    </Button> */}
      <div id='supportFundWarrant'>
        <Table width ="100%" align = "center"  >
          <Table.Body>
            <Table.Row>
              <Table.Cell align = "center"><h1 style={{textAlign : 'center', margin : '10px', fontSize : '30px'}}>{` 위 임 장 `}</h1></Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>
        <table width ="100%" align = "center" >
          <Table.Body>
            <Table.Row>
              <Table.Cell><h1 style={{textAlign : 'left', fontSize : '15px'}}>{` 위임 분야 : 고용안정지원금 및 장려금 `}</h1></Table.Cell>
            </Table.Row>
          </Table.Body>
        </table>
        <h4>[수임인]</h4>
        <table style={{width : "100%", height : "130", textAlign : "center"}} border='1px solid black' >
          <Table.Body>
            <Table.Row>
              <Table.Cell width ="16.6%">노무법인명 </Table.Cell>
              <Table.Cell width ="16.6%">담당 노무사명</Table.Cell>
              <Table.Cell width ="16.6%">전화번호</Table.Cell>
            </Table.Row>
            <Table.Row style={{height : '50px'}}>
              <Table.Cell width ="16.6%">{nomusaCompanyName}</Table.Cell>
              <Table.Cell width ="16.6%">{nomusaName}</Table.Cell>
              <Table.Cell width ="16.6%">{nomusaPhone}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </table>
        <div style={{ fontSize : "20px", textAlign : "center"}}>
          {`
            위임인 본인은 공인노무사법 제2조 제1항의 규정에 의하여 위 수임인을 대리인으로 선임하며, 위임하는 사항은 다음과 같습니다.
          `}
        </div>
        <h4>[위임 사항]</h4>
        <div>
          {`
            ① 위 위임업무와 관련한 관계기관에 대한 정보공개 요청 및 청구, 신고, 신청, 보고,진술, 청구, 서면제출 등
          `}
        </div>
        <div>
          {`
            ② 위 위임업무와 관련하여 상대방 및 관계기관에서 위임인 본인에게 하는 통보의 수령
          `}
        </div>
        <div className='margin' >
          {`
            ③ 아래의 특별 위임 사항
          `}
        </div>
        <h4>[특별 위임 사항]</h4>
        <table style={{width : "100%", height : "130", textAlign : "center"}} border='1px solid black'>
          <Table.Body>
            <Table.Row>
              <Table.Cell colSpan='2' width ="33%">다음 사항 중 별도로 권한을 부여하는 경우 우측에 O표시, 보류하면 X표시 </Table.Cell>
              <Table.Cell width ="33%">O, X표시</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width ="10%">취하 </Table.Cell>
              <Table.Cell className="body" width ="70%">관계기관에 제출된 신고(신청ㆍ청구)를 철회하여 위임 업무를 종료할 수 있는 권한</Table.Cell>
              <Table.Cell width ="20%" style={{opacity: '0.5'}}>O, X</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width ="10%">화해 </Table.Cell>
              <Table.Cell className="body" width ="70%">상호 양보하여 위임 업무를 종결할 수 있는 권한</Table.Cell>
              <Table.Cell width ="20%" style={{opacity: '0.5'}}>O, X</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width ="10%">포기 </Table.Cell>
              <Table.Cell className="body" width ="70%">위임인의 신고(신청ㆍ청구)가 이유 없다고 하여 위임 업무를 종료할 수 있는 권한</Table.Cell>
              <Table.Cell width ="20%" style={{opacity: '0.5'}}>O, X</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width ="10%">화해 </Table.Cell>
              <Table.Cell className="body" width ="70%">상호 양보하여 위임 업무를 종결할 수 있는 권한</Table.Cell>
              <Table.Cell width ="20%" style={{opacity: '0.5'}}>O, X</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width ="10%">인낙 </Table.Cell>
              <Table.Cell className="body" width ="70%">관계기관 및 상대방의 신고(신청ㆍ청구)가 이유 있다고 인정하여 위임 업무를 종료할 수 있는 권한</Table.Cell>
              <Table.Cell width ="20%" style={{opacity: '0.5'}}>O, X</Table.Cell>
            </Table.Row>
            <Table.Row>
              <Table.Cell width ="10%">복대리 </Table.Cell>
              <Table.Cell className="body" width ="70%">다른 공인노무사를 대리인으로 선임할 수 있는 권한</Table.Cell>
              <Table.Cell width ="20%" style={{opacity: '0.5'}}>O, X</Table.Cell>
            </Table.Row>
          </Table.Body>
        </table>
        <table width ="100%" align = "center" >
          <Table.Body>
            <Table.Row>
              <Table.Cell align = "center">{format(today, 'yyyy-MM-dd')}</Table.Cell>
            </Table.Row>
          </Table.Body>
        </table>
        <h3>[위임인]</h3>
        <table style={{width : "100%", height : "130", textAlign : "center"}} border='1px solid black' >
          <Table.Body>
            <Table.Row>
              <Table.Cell width ="20%">회사명 </Table.Cell>
              <Table.Cell width ="20%">대표자 성명</Table.Cell>
              <Table.Cell width ="20%">연락처</Table.Cell>
              <Table.Cell width ="20%">이메일</Table.Cell>
              <Table.Cell width ="20%">확인</Table.Cell>
            </Table.Row>
            <Table.Row style={{height : '70px'}}>
              <Table.Cell width ="20%">{profile?.companyName}</Table.Cell>
              <Table.Cell width ="20%">{profile?.ceoName}</Table.Cell>
              <Table.Cell width ="20%">{profile?.companyPhone }</Table.Cell>
              <Table.Cell width ="20%">{profile?.staffEmail }</Table.Cell>
              <Table.Cell width ="20%" style={{opacity: '0.5'}}>서명 또는 인</Table.Cell>
            </Table.Row>
          </Table.Body>
        </table>
      </div>
    </>
  );
};
