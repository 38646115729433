import React from 'react';
import { Button, Label } from 'semantic-ui-react';
import {
	lowWageOKComment,
	lowWageUnderWonComment,
	lowWageViolationComment,
	numberToCommaString,
	printDayWorker,
	roundUp,
} from '../../../../../app/common/util/util';
import { addPrintCount } from '../../../../../app/firestore/firestoreService4In';

export default function ClientReqDLCDWageDayPrint({
	selectedClientReq4In,
	workerId,
	daylyTimePay,
	isBizWorker,
}) {
	const insufficientDayWage =
		selectedClientReq4In?.dayWorker?.insufficientDayWage;

	const printFunc = () => {
		addPrintCount(workerId);
		printDayWorker();
	};

	const dayWorkerOrTimeWorkerText = daylyTimePay
		? '시급직 근로계약서'
		: isBizWorker
		? '위임계약서'
		: '일용직 근로계약서/임금명세서';

	return (
		<>
			{isBizWorker ? (
				<>
					<Button
						type='button'
						// disabled={insufficientDayWage > 0}
						color='blue'
						onClick={() => printFunc()}>
						{dayWorkerOrTimeWorkerText}
					</Button>
				</>
			) : (
				<>
					<Button
						type='button'
						color='blue'
						disabled={insufficientDayWage > 0}
						onClick={() => printFunc()}>
						{dayWorkerOrTimeWorkerText}
					</Button>
					<Label
						content={
							insufficientDayWage <= 0
								? lowWageOKComment()
								: lowWageViolationComment()
						}
					/>
					<Label
						content={
							insufficientDayWage > 0
								? '일당 기준 ' +
								  numberToCommaString(roundUp(insufficientDayWage)) +
								  lowWageUnderWonComment()
								: ''
						}
					/>
				</>
			)}
		</>
	);
}
