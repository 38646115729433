import React from 'react';
import ModalWrapper from '../../../app/common/modals/ModalWrapper';
import YouTube from 'react-youtube';

export default function  OutSideWorkerLaborContract() {

  const opts = {
    // height: '300%',
    width: '100%',
    playVars: {
      autoplay: 1
    }
  };

  return (
    <ModalWrapper size='large' header="AI NOMUSA 외근 간주직 근로계약서 기능 설명 동영상">
      <YouTube videoId='G_h4wEjXqu0' opts={opts} />
    </ModalWrapper>
  )
}
